import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { SubmitHandler, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import { AuthHandler } from '../../../../stores/handlers';

import { AppElement, AppFC } from '../../../../interfaces';
import { PasswordResetProps } from './types';
import { AlertCategory, AuthPaths, ButtonCategory } from '../../../../constants';
import { generateErrorAlert } from '../../../../utils';

import { Button, Alert } from '../../../Atoms';
import { AppInput } from '../../../Molecules';

const validationSchema = yup.object().shape({
  newPassword1: yup
    .string()
    .required('Please enter your password')
    .matches(
      /^(?=.{8,32}$)(?=.*[a-zA-Z].*)(?=.*[0-9].*)(?=.*[!"#$%&'()*+,\-./:;<=>?@[\\\]^_`{|}~])/,
      'Must contain 8-32 characters, at least one letter, one number and one special character',
    ),
  newPassword2: yup
    .string()
    .required('Please enter your password')
    .oneOf([yup.ref('newPassword1'), ''], 'Passwords must match')
    .matches(
      /^(?=.{8,32}$)(?=.*[a-zA-Z].*)(?=.*[0-9].*)(?=.*[!"#$%&'()*+,\-./:;<=>?@[\\\]^_`{|}~])/,
      'Must contain 8-32 characters, at least one letter, one number and one special character',
    ),
});
export const PasswordReset: AppFC = (): AppElement => {
  const [isLoading, setIsLoading] = useState(false);
  const [alert, setAlert] = useState<AppElement>();

  const { uid, token } = useParams();
  const navigate = useNavigate();

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      newPassword1: '',
      newPassword2: '',
    },
  });

  const { passwordResetConfirm } = AuthHandler();

  const onSubmit: SubmitHandler<PasswordResetProps> = (data) => {
    if (uid && token) {
      setIsLoading(true);

      passwordResetConfirm({ ...data, uid, token })
        .then((res) => {
          setIsLoading(false);
          setAlert(
            <Alert
              className="top-5 z-50"
              category={AlertCategory.Success}
              title={res.data.detail}
            />,
          );
          setTimeout(() => {
            setAlert(null);
          }, 5000);
          navigate(AuthPaths.SignIn, { state: { alertMessage: res.data.detail } });
        })
        .catch((reason) => {
          setIsLoading(false);
          generateErrorAlert(reason, 'Password reset failed!', setAlert, 'top-5 z-50');
        });
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="relative h-full flex flex-col items-center justify-center gap-14">
      {alert}
      <h4>Set new password</h4>
      <div className="flex flex-col gap-10 items-center justify-center w-full">
        <AppInput
          label="Password"
          name="newPassword1"
          type="password"
          control={control}
          placeholder="Enter new password"
          error={errors.newPassword1}
        />
        <AppInput
          label="Confirm Password"
          name="newPassword2"
          type="password"
          control={control}
          placeholder="Confirm new password"
          error={errors.newPassword2}
        />
      </div>
      <Button
        className="w-full"
        category={ButtonCategory.Filled}
        type="submit"
        isLoading={isLoading}
      >
        Set password
      </Button>
    </form>
  );
};
