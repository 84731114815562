import React, { useEffect, useState } from 'react';
import {
  Link, useLocation, useNavigate, useSearchParams,
} from 'react-router-dom';
import { SubmitHandler, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import { AuthHandler } from '../../../../stores/handlers';

import { AppElement, AppFC } from '../../../../interfaces';
import { SignInProps } from './types';
import {
  AlertCategory,
  AuthPaths,
  ButtonCategory, Paths,
} from '../../../../constants';
import { generateErrorAlert } from '../../../../utils';

import { Button, Alert } from '../../../Atoms';
import { TwoFA } from './TwoFA';
import { AppInput } from '../../../Molecules';

const validationSchema = yup.object().shape({
  email: yup
    .string()
    .email('Email is invalid')
    .required('Please enter your email'),
  password: yup
    .string()
    .required('Please enter your password')
    .matches(
      /^(?=.{8,32}$)(?=.*[a-zA-Z].*)(?=.*[0-9].*)(?=.*[!"#$%&'()*+,\-./:;<=>?@[\\\]^_`{|}~])/,
      'Must contain 8-32 characters, at least one letter, one number and one special character',
    ),
});

export const SignIn: AppFC = (): AppElement => {
  const [isLoading, setIsLoading] = useState(false);
  const [alert, setAlert] = useState<AppElement>();
  const [userId, setUserId] = useState('');
  const [is2faEnabled, setIs2faEnabled] = useState(false);
  const [qrCode, setQrCode] = useState('');
  const [manualQrCode, setManualQrCode] = useState('');

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      email: '',
      password: '',
    },
  });
  const navigate = useNavigate();
  const location = useLocation();
  const { signIn, twoFaAuthorize } = AuthHandler();

  const [params] = useSearchParams();
  const projectId = params.get('projectId');
  const miners = params.get('miners');

  const searchPath = projectId ? `${projectId}${miners ? `?miners=${miners}` : ''}` : '';

  useEffect(() => {
    const { alertMessage } = location.state || {};
    if (alertMessage) {
      setAlert(
        <Alert
          className="top-5 z-50"
          category={AlertCategory.Success}
          title={alertMessage}
        />,
      );
      setTimeout(() => {
        setAlert(null);
      }, 7000);
    } else {
      setAlert(null);
    }
    navigate(`${location.pathname}${location.search}`, { state: {} });
  }, []);

  const onSubmit: SubmitHandler<SignInProps> = (data) => {
    setIsLoading(true);
    signIn(data)
      .then((res) => {
        if (res.data) {
          const {
            id, qrCode: code, manualCode, is2faEnabled: enabled,
          } = res.data;

          if (!enabled && !code) {
            twoFaAuthorize({ userId: id })
              .then(() => {
                setIsLoading(false);
                navigate(`${Paths.Projects}/${searchPath}`);
              })
              .catch((reason) => {
                generateErrorAlert(reason, 'Sign in failed!', setAlert, 'top-[20%] z-50');
                setIsLoading(false);
              });
          } else {
            setUserId(id);
            setIs2faEnabled(enabled);
            setQrCode(code);
            setManualQrCode(manualCode);
          }
        }
      })
      .catch((reason) => {
        generateErrorAlert(reason, 'Sign in failed!', setAlert, 'top-5 z-50');
        setIsLoading(false);
      });
  };

  return (
    <>
      {userId && ((!is2faEnabled && qrCode) || (is2faEnabled && !qrCode))
        ? <TwoFA qrCode={qrCode} manualCode={manualQrCode} userId={userId} />
        : (
          <form onSubmit={handleSubmit(onSubmit)} className="relative h-full flex flex-col items-center justify-center max-md:pb-8">
            {alert}
            <h4 className="mb-14">Login to your account</h4>
            <div className="flex flex-col gap-6 items-center justify-center w-full">
              <AppInput
                label="Email Address"
                name="email"
                control={control}
                placeholder="Enter email address"
                error={errors.email}
              />
              <AppInput
                label="Password"
                name="password"
                type="password"
                control={control}
                placeholder="Enter password"
                error={errors.password}
              />
              <div className={`flex flex-col justify-end items-end gap-2 w-full ${errors.password ? 'mt-3' : '-mt-2.5'}`}>
                <Link
                  to={AuthPaths.ForgotPassword}
                  className="text-white/70 text-[12px] font-semibold leading-[100%] hover:underline"
                >
                  Forgot password?
                </Link>
              </div>
            </div>
            <Button
              className="mt-12 w-full"
              category={ButtonCategory.Filled}
              isLoading={isLoading}
            >
              Login
            </Button>
            <div className="bottom-14 flex flex-col items-center gap-6 mt-24">
              <span className="font-semibold text-[13px] leading-[100%]">
                Don&apos;t have an account?
              </span>
              <Link to={AuthPaths.SignUp}>
                <Button
                  className="w-[200px]"
                  category={ButtonCategory.Outlined}
                >
                  <h5>Register</h5>
                </Button>
              </Link>
              <Link className="leading-none" to="https://www.pantheonmining.com/">
                <span className="font-semibold text-[13px] leading-[100%] underline">
                  Go Back to the Website
                </span>
              </Link>
            </div>
          </form>
        )}
    </>

  );
};
