import React, { useContext, useEffect, useState } from 'react';

import { AppElement, AppFC } from '../../../../../interfaces';
import { httpRequest } from '../../../../../services';
import {
  ProfileContext,
  ProjectsContext,
  ViewContext,
  ProjectsActionTypes,
  ViewModalActionTypes,
} from '../../../../../stores';
import {
  // currencyKeys,
  getProjectAPI,
  RequestTypes,
} from '../../../../../constants';
import {
  generateErrorAlert,
  // getFormattedAmount
} from '../../../../../utils';
import { ProjectHandler } from '../../../../../stores/handlers';

export const Docusign: AppFC = (): AppElement => {
  const [alert, setAlert] = useState<AppElement>();

  const {
    projectsState: {
      envelopeId,
    // rates
    }, updateProjectsState,
  } = useContext(ProjectsContext);
  const { viewModal, updateViewModal } = useContext(ViewContext);
  const { info } = viewModal;
  const {
    selectedAccount, spotsAmount, projectId, invoiceId = '',
  } = info || {};
  const { profileState: { wallet, strigaId } } = useContext(ProfileContext);
  const { corporateAccounts } = wallet || {};

  const {
    PROJECT_AGREEMENT_STATUS,
  } = getProjectAPI(viewModal.info?.projectId as string);
  const { updateProjectSpots } = ProjectHandler();

  useEffect(() => {
    if (envelopeId) {
      /** Investment flow - 3.Check investment agreement status exists or don't * */

      httpRequest({
        url: `${PROJECT_AGREEMENT_STATUS}?envelope_id=${envelopeId}`,
        method: RequestTypes.Get,
      }).then(({ data }) => {
        if (data.status) {
          /** Investment flow - 4.If agreement is signed successfully by user,
         * make transaction in case of striga user,
         * and show account details for non striga users  * */

          if (strigaId && selectedAccount && corporateAccounts) {
            // const url = getProjectAPI(viewModal.info?.projectId as string).PROJECT_TRANSACTION;

            // httpRequest({
            //   data: {
            //     sourceAccountId: selectedAccount.accountId,
            //     destinationAccountId: corporateAccounts[currencyKeys[selectedAccount.currency]].accountId,
            //     amount: getFormattedAmount(Number(info?.totalPrice) / (Number(rates?.[`${currencyKeys[selectedAccount.currency]}EUR`]?.sell) || 1), selectedAccount.availableBalance.currency, -1).toString(),
            //   },
            //   url,
            //   method: RequestTypes.Post,
            // })
            //   .then((res) => {
            //     updateViewModal({
            //       type: ViewModalActionTypes.TxConfirmModal,
            //       payload: {
            //         info: {
            //           ...viewModal.info,
            //           challengeId: res.data.challengeId,
            //         },
            //       },
            //     });
            //   })
            //   .catch((reason) => {
            //     generateErrorAlert(reason, 'Transaction Request Failed!', setAlert, 'top-6');
            //   })
            //   .finally(() => {
            //     updateProjectsState({
            //       type: ProjectsActionTypes.SetEnvelopeId,
            //       payload: '',
            //     });
            //   });
          } else {
            updateProjectSpots(projectId as string, { spots: Number(spotsAmount), invoiceId })
              .then(() => {
                updateViewModal({
                  type: ViewModalActionTypes.AccountDetailsModal,
                  payload: {
                    ...info,
                    isClosable: true,
                    title: 'Your Purchase Request Received',
                    importantMessage: 'Please ensure that the funds are transferred from your personal bank account, which must match the first and last name you have registered on the Pantheon Portal.',
                  },
                });
              });
          }
        }
      }).catch((reason) => {
        generateErrorAlert(reason, 'Agreement is not signed by user!', setAlert, '-top-5 w-[230px] z-50');
        setTimeout(() => {
          updateViewModal({ type: ViewModalActionTypes.Close });
        }, 7000);
      });
    }
    return () => {
      updateProjectsState({
        type: ProjectsActionTypes.SetEnvelopeId,
        payload: '',
      });
    };
  }, [envelopeId]);

  return (
    <div className="flex flex-col items-center w-full h-full">
      {alert}
      <div className="flex flex-col gap-8 w-full h-full">
        <iframe
          className="h-full w-full rounded-lg"
          title="Docusign"
          src={info?.url}
          sandbox="allow-same-origin allow-scripts allow-popups allow-forms"
        />
      </div>
    </div>
  );
};
