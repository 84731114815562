import moment from 'moment';

export const getYearOptions = () => {
// Define the start and end years
  const endYear = moment(new Date('2005'));// .clone().subtract(18, 'years');
  const startYear = moment(new Date('1920'));// .clone().subtract(100, 'years');

  // Create an array of year options within the specified interval
  const yearInterval = [];
  const currentDate = moment(startYear);

  while (currentDate.isBefore(endYear)) {
    yearInterval.push({ value: `${currentDate.year()}`, label: `${currentDate.year()}` });
    currentDate.add(1, 'year');
  }
  return yearInterval;
};

export const getMonthOptions = () => [
  { value: '01', label: 'January' },
  { value: '02', label: 'February' },
  { value: '03', label: 'March' },
  { value: '04', label: 'April' },
  { value: '05', label: 'May' },
  { value: '06', label: 'June' },
  { value: '07', label: 'July' },
  { value: '08', label: 'August' },
  { value: '09', label: 'September' },
  { value: '10', label: 'October' },
  { value: '11', label: 'November' },
  { value: '12', label: 'December' },
];

export const getDayOptions = (year = '2000', month = '01') => {
  let startDay = 1;

  const date = moment(`${year}-${month}`, 'YYYY-MM'); // Replace "2023-02" with the desired month and year in YYYY-MM format

  // Get the number of days in the specific month
  const daysInMonth = date.daysInMonth();

  // Create an array of day options within the specified interval
  const dayInterval = [];

  while (startDay <= daysInMonth) {
    const dayString = date.date(startDay).format('DD');
    dayInterval.push({
      value: dayString, label: dayString,
    });
    startDay += 1;
  }
  return dayInterval;
};

export const dateFormat = (date: string, format: string) => moment(new Date(date)).format(format);
export const reorderDateString = (date: string) => {
  const dateArray = date.split('-');
  return `${dateArray[1]}-${dateArray[0]}-${dateArray[2]}`;
};
