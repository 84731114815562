import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import { AppElement, AppFC } from '../../../interfaces';

import {
  InvoicesContext,
  ViewContext,
  ViewModalActionTypes,
} from '../../../stores';

import { CurrencyAmountBox, InvoiceCard, InvoiceRow } from '../../Molecules';

import { BackArrowIcon } from '../../Atoms/icons';
import { InfoRawProps } from '../../Atoms/InfoRaw/types';
import { Button, InfoRaw, Loader } from '../../Atoms';
import { ProjectHandler } from '../../../stores/handlers';
import { ProjectInterface } from '../../../types/projects';
import { InvestmentControl } from '../../Organisms/InvestmentControl';
import { CurrencyTypes } from '../../../constants';

export const Project: AppFC = (): AppElement => {
  const [searchParams] = useSearchParams();
  const orderQuantity = searchParams.get('miners');

  const [isLoading, setIsLoading] = useState(false);
  const [project, setProject] = useState<ProjectInterface>();

  const { invoicesState: { invoices } } = useContext(InvoicesContext);
  const { updateViewModal } = useContext(ViewContext);

  const navigate = useNavigate();

  const { id } = useParams();
  const { getProject, getRates } = ProjectHandler();

  const currentInvoices = invoices?.filter((invoice) => invoice.projectId === project?.id).sort((a, b) => b.invoiceNumber - a.invoiceNumber);

  useEffect(() => {
    getRates().then();
  }, []);

  // console.log('project', project);

  const {
    coverImage,
    title,
    description,
    country,
    energySource,
    electricityPrice = 0,
    minerModel,
    pricePerMiner = 0,
    minimumOrderQuantity = 0,
    soldSpots = 0,
    totalSpots = 0,
    gallery = [],
    wattage,
  } = project || {};

  const [spotsAmount, setSpotsAmount] = useState(Number(orderQuantity) || minimumOrderQuantity);

  const availableSpotsAmount = totalSpots - soldSpots;

  const infoRaws: InfoRawProps[] = [
    {
      label: 'Country',
      value: country,
    },
    {
      label: 'Energy Source',
      value: energySource,
    },
    {
      label: 'Electricity Price per kW/h',
      value: <CurrencyAmountBox
        projectCurrency={project?.currency}
        amount={electricityPrice || '0'}
        currency={CurrencyTypes.EUR}
        decimals={7}
      />,
    },
    {
      label: 'Monthly Electricity Cost *',
      value: !wattage ? '-' : (
        <CurrencyAmountBox
          projectCurrency={project?.currency}
          amount={electricityPrice * 720 * wattage * 0.001 * spotsAmount}
          currency={CurrencyTypes.EUR}
          decimals={7}
        />
      ),
    },
    {
      label: 'Model of the miner',
      value: minerModel,
    },
    {
      label: orderQuantity ? 'Order Quantity' : 'Minimum Order Quantity',
      value: orderQuantity || minimumOrderQuantity,
    },
    {
      label: 'Price per miner',
      value: <CurrencyAmountBox
        projectCurrency={project?.currency}
        amount={pricePerMiner}
        currency={CurrencyTypes.EUR}
        decimals={5}
      />,
    },

  ];

  useEffect(() => {
    setIsLoading(true);
    getProject(id as string).then((data) => {
      if (data) {
        setProject(data);
        setSpotsAmount(Number(orderQuantity) || data.minimumOrderQuantity);
      }
      setIsLoading(false);
    }).catch(() => {
      setIsLoading(false);
    });
    // Scroll to the top of the page after a redirect
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, [id]);

  useEffect(() => {
    if (project && orderQuantity) {
      updateViewModal({
        type: ViewModalActionTypes.QuotationDetailsModal,
        payload: {
          title,
          description,
          info: {
            infoRaws,
            projectCurrency: project.currency,
            projectId: project.id,
            totalPrice: Number(orderQuantity) * pricePerMiner,
            pricePerMiner,
            spotsAmount: Number(orderQuantity),
            projectName: title,
          },
        },
      });
    }
  }, [project]);

  return (
    <div className="flex flex-col gap-6 h-full text-white">
      <Button onClick={() => navigate(-1)} className="h-auto w-fit flex items-center gap-1 p-0">
        <BackArrowIcon className="text-white" />
        <h5 className="font-semibold text-[1rem] pt-[3px] hover:underline">Back</h5>
      </Button>
      {project && (
      <div>
        <div className="relative h-[400px] w-full rounded-xl mb-10 overflow-hidden">
          {!availableSpotsAmount && <div className="absolute top-0 p-3 w-full font-semibold text-white text-[14px] text-center bg-[#FF8B36] flex items-center justify-center">This project is sold out</div>}
          <img src={coverImage} className="h-full w-full rounded-xl object-cover" alt="SumsubKYC" />
        </div>
        <div className="w-full flex gap-14 max-md:flex-col">
          <div className="flex-1">
            <h4 className="text-[2rem] leading-[160%]">
              {title}
            </h4>
            <p className="text-[18px] font-medium">{description}</p>
            <div className="mt-8">
              {infoRaws.map(({ label, value }, index) => <InfoRaw key={label + index} label={label} value={value} />)}
            </div>
            <ul className="mt-6 list-none [&>li]:font-normal [&>li]:text-[13px] [&>li]:flex">
              <li>Disclaimer:</li>
              <li>
                <span className="mr-1">*</span>
                Average hours means not every month is the same amount of days thus hours.
              </li>
              <li>
                <span className="mr-1">*</span>
                Miners can run on a higher hash rate from time to time using more electricity
              </li>
              <li>
                <span className="mr-1">*</span>
                Miners can be offline and use less
              </li>
            </ul>
          </div>

          <InvestmentControl project={project} spotsAmount={spotsAmount} setSpotsAmount={setSpotsAmount} />
        </div>
        {!!currentInvoices.length && (
        <div className="flex flex-col gap-5 mt-20">
          <h5 className="text-[1rem] font-semibold leading-[160%]">Invoices</h5>
          <table className="border-separate border-spacing-y-2 w-full text-sm text-left max-sm:hidden ">
            <thead className="font-medium text-[13px] text-[#7E7E7E] bg-filled">
              <tr className="rounded-lg">
                <th scope="col" className="rounded-l-lg px-6 py-2.5">
                  Invoice number
                </th>
                <th scope="col" className="px-3 py-2.5">
                  Invoice Type
                </th>
                {/* <th scope="col" className="px-3 py-2.5"> */}
                {/*   Project */}
                {/* </th> */}
                <th scope="col" className="px-3 py-2.5">
                  Location
                </th>
                <th scope="col" className="px-6 py-2.5">
                  Status
                </th>
                <th scope="col" className="px-3 py-2.5">
                  Date
                </th>
                <th scope="col" className="rounded-r-lg px-3 py-2.5">
                  <h5 className="opacity-0 w-10">P</h5>
                </th>
              </tr>
            </thead>
            <tbody className="font-medium">
              {
                  currentInvoices?.map((invoice, index) => (
                    <InvoiceRow {...invoice} key={`invoice-row-${index}`} className="shadow-5xl bg-filled" projectId="" />
                  ))
                }
            </tbody>
          </table>
          <div className="sm:hidden flex flex-col gap-3">
            {currentInvoices?.map((invoice, index) => <InvoiceCard {...invoice} key={`invoice-card-${index}`} projectId="" className="shadow-5xl bg-filled" />)}
          </div>
        </div>
        )}
        {!!gallery.length && (
        <div className="flex flex-col gap-8 mt-20">
          <h5>Gallery</h5>
          <div className="grid grid-cols-3 max-md:grid-cols-1 max-lg:grid-cols-2 max-xl::grid-cols-3 gap-5">
            {gallery.map(({ picture }, index) => <img className="h-[200px] w-full rounded-sm object-cover" src={picture} alt="SumsubKYC" key={`gallery-${index}`} />)}
          </div>
        </div>
        )}
      </div>
      )}
      {!project && isLoading && (
        <Loader className="self-center flex-1 w-8 h-8" />
      )}
    </div>
  );
};
