import { LabelHTMLAttributes } from 'react';
import { AppElement, AppFC } from '../../../interfaces';

export const Label: AppFC<LabelHTMLAttributes<HTMLLabelElement>> = ({
  children, className, htmlFor, ...rest
}): AppElement => (
  <label {...rest} className={`relative w-full flex flex-col gap-3.5 ${className || ''}`}>
    {children}
  </label>
);
