import { AppElement, AppFC } from '../../../interfaces';
import { InfoRawProps } from './types';

export const InfoRaw: AppFC<InfoRawProps> = ({
  label, value, className, children, ...rest
}): AppElement => (
  <div className={`flex justify-between py-5 border-b border-[#EAEAEA] ${className}`} {...rest}>
    <span className="leading-[100%]">{label}</span>
    <span className="leading-[100%] font-mendium text-end">{value || children}</span>
  </div>
);
