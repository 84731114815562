import { useContext, useEffect, useState } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Navigate,
  Routes,
} from 'react-router-dom';

import {
  initAxiosInterceptors,
  initAxiosHeaders,
  AuthToken,
} from './services/httpRequest';

import { ProjectsActionTypes, ProjectsContext } from './stores';
import {
  InvoiceHandler, ProfileHandler, ProjectHandler,
} from './stores/handlers';

import { AuthPaths, Paths } from './constants';

import { Layout, AuthLayout } from './components/Templates/Layout';
import {
  SignIn,
  SignUp,
  EmailVerification,
  PasswordForgot,
  PasswordReset,
  Projects,
  Project,
  Invoices,
  InvoicesHistory,
  UserProfile,
  MobileVerification,
  SumsubKYC,
  Wallets,
  Calculator,
} from './components/Pages';
import { AppModal } from './components/Organisms';
import { DocusignResponse } from './components/Molecules';
import { Loader } from './components/Atoms';

export const App = () => {
  initAxiosHeaders();
  initAxiosInterceptors();

  const authToken: AuthToken = new AuthToken();
  const accessToken = authToken.getAccessToken();
  const [isLoading, setIsLoading] = useState(true);

  const { getProjects } = ProjectHandler();
  const { getInvoices } = InvoiceHandler();
  const {
    getProfile,
    getKYCStatus,
  } = ProfileHandler();
  const { updateProjectsState } = useContext(ProjectsContext);

  useEffect(() => {
    if (accessToken) {
      Promise.all([
        getProjects(),
        getInvoices(),
        getProfile(),
        getKYCStatus(),
      ])
        .then(() => {
          setIsLoading(false);
        })
        .catch((e) => {
          setIsLoading(false);
          console.log('project/invoice/profile/getKYCStatus data error', e);
        });
    } else {
      setIsLoading(false);
    }
  }, [accessToken]);

  useEffect(() => {
    window.addEventListener('message', (event: MessageEvent) => {
      const message = event.data;

      if (message.type === 'signMessage') {
        const { data } = message;
        console.log('Received message:', data.envelopeId);
        data.envelopeId && updateProjectsState({ type: ProjectsActionTypes.SetEnvelopeId, payload: data.envelopeId });
      }
    });
    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('message', () => { updateProjectsState({ type: ProjectsActionTypes.SetEnvelopeId, payload: '' }); });
    };
  }, []);

  return (
    <Router>
      <AppModal />
      {!isLoading ? (
        <Routes>
          <Route path={AuthPaths.SignIn} element={<AuthLayout><SignIn /></AuthLayout>} />
          <Route path={AuthPaths.SignUp} element={<AuthLayout><SignUp /></AuthLayout>} />
          <Route path={AuthPaths.VerifyEmail} element={<AuthLayout><EmailVerification /></AuthLayout>} />
          <Route path={AuthPaths.VerifyEmailConfirm} element={<AuthLayout><EmailVerification /></AuthLayout>} />
          <Route path={AuthPaths.ForgotPassword} element={<AuthLayout><PasswordForgot /></AuthLayout>} />
          <Route path={AuthPaths.ResetPassword} element={<AuthLayout><PasswordReset /></AuthLayout>} />
          <Route path={AuthPaths.MobileVerify} element={<AuthLayout><MobileVerification /></AuthLayout>} />

          <Route path={Paths.Wallets} element={<Layout><Wallets /></Layout>} />
          <Route path={Paths.Projects} element={<Layout><Projects /></Layout>} />
          <Route path={Paths.Project} element={<Layout><Project /></Layout>} />
          <Route path={Paths.Invoices} element={<Layout><Invoices /></Layout>} />
          <Route path={Paths.InvoicesHistory} element={<Layout><InvoicesHistory /></Layout>} />
          <Route path={Paths.ProfileEdit} element={<Layout><UserProfile /></Layout>} />
          <Route path={Paths.Calculator} element={accessToken ? <Layout><Calculator /></Layout> : <Calculator />} />
          <Route path={Paths.SumsubKYC} element={<SumsubKYC />} />
          <Route path={Paths.Docusign} element={<DocusignResponse />} />

          <Route path="*" element={<Navigate to={Paths.Projects} />} />
        </Routes>
      ) : <Loader className="self-center justify-self-center w-8 h-8" />}
    </Router>
  );
};
