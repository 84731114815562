import { AppElement, AppFC } from '../../../interfaces';
import { WalletCardProps } from './types';
import { Button } from '../../Atoms';
import { getFormattedAmount, numberWithCommas } from '../../../utils';

export const WalletCard: AppFC<WalletCardProps> = ({
  icon,
  currency,
  balance,
  className,
  onClick,
  selected,
  decimals = 2,
  ...rest
}): AppElement => {
  const { amount, currency: amountCurrency } = balance || {};

  return (
    <Button onClick={onClick} className={`flex items-center gap-2 h-fit p-3  ${selected ? 'border-lightBrown bg-lightBrown26' : 'border-black16'} border rounded-xl ${className || ''}`} {...rest}>
      <img src={icon} alt="currency icon" />
      <div className="flex flex-col items-start gap-1 w-full">
        <h5 className="text-[1rem] font-semibold leading-[100%]">{currency}</h5>
        {amount && (
        <span className="text-[14px] font-medium leading-[100%]">
          {numberWithCommas(getFormattedAmount(amount, amountCurrency || ''), '', decimals)}
        </span>
        )}
      </div>
    </Button>
  );
};
