import React, { useContext, useState } from 'react';
// import { useNavigate } from 'react-router-dom';
import OtpInput from 'react-otp-input';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

// import { ProfileHandler } from '../../../../stores/handlers';
import { ProfileContext } from '../../../../stores';

import { AppElement, AppFC } from '../../../../interfaces';
import { MobileVerifyProps } from './types';
// import { generateErrorAlert } from '../../../../utils';
import {
  // AlertCategory,
  ButtonCategory,
  // Paths,
} from '../../../../constants';

import {
  Button,
  Label,
  ErrorMessage,
  // Alert,
} from '../../../Atoms';

const validationSchema = yup.object().shape({
  otp: yup
    .string()
    .required('Please enter verification code'),
});
export const MobileVerification: AppFC = (): AppElement => {
  // const [isLoading,
  // setIsLoading
  // ] = useState(false);
  const [isVerifyLoading,
    // setIsVerifyLoading
  ] = useState(false);
  const [alert,
    // setAlert
  ] = useState<AppElement>();
  const { profileState: { phone } } = useContext(ProfileContext);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      otp: '',
    },
  });

  // const { verifyPhoneNumber, resendSMS } = ProfileHandler();
  // const navigate = useNavigate();

  // const handleResendMobile = () => {
  //   setIsLoading(true);
  //   resendSMS().then(() => {
  //     setIsLoading(false);
  //     setAlert(
  //       <Alert
  //         className="top-5"
  //         category={AlertCategory.Success}
  //         title="New SMS sent!"
  //       />,
  //     );
  //     setTimeout(() => {
  //       setAlert(null);
  //     }, 7000);
  //   }).catch((reason) => {
  //     setIsLoading(false);
  //     generateErrorAlert(reason, 'SMS resend failed!', setAlert, 'top-5');
  //   });
  // };

  const onSubmit: SubmitHandler<MobileVerifyProps> = (data) => {
    console.log('data', data);
    // setIsVerifyLoading(true);
    // verifyPhoneNumber({ verificationCode: data.otp }).then(() => {
    //   navigate(Paths.Projects);
    // }).catch((reason) => {
    //   setIsVerifyLoading(false);
    //   generateErrorAlert(reason, 'Mobile verification failed!', setAlert, 'top-5');
    // });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="relative h-full flex flex-col items-center justify-center">
      {alert}
      <h4 className="mb-5">Verify phone number</h4>
      <p className="text-center mb-6">{`We've sent a six-digit verification code to ${phone} phone number`}</p>
      <span className="text-[14px] leading-4 mb-16 text-center">
        If you need to change your phone number please contact us at
        {' '}
        <b className="text-blue">support@pantheonmining.com</b>
      </span>

      <Label>
        <Controller
          name="otp"
          control={control}
          render={({
            field: {
              name,
              value,
              onChange,
            },
          }) => (
            <OtpInput
              containerStyle={{ justifyContent: 'center' }}
              value={value}
              onChange={onChange}
              numInputs={6}
              inputStyle={{
                width: '44px',
                height: '44px',
                borderRadius: '8px',
                background: 'transparent',
              }}
              renderSeparator={<span className="w-2" />}
              renderInput={(props) => <input name={name} {...props} className={`otp-input ${errors.otp ? 'otp-input-error' : 'otp-input-default'} `} />}
            />
          )}
        />
        {errors.otp && <ErrorMessage className="top-12 w-full text-center">{errors.otp.message}</ErrorMessage>}
      </Label>
      <Button className="mt-[3.5rem] w-full" category={ButtonCategory.Filled} isLoading={isVerifyLoading}>Verify</Button>
      <div className="w-full mt-11 flex items-center justify-center gap-1 font-semibold text-[13px]">
        <span>
          Didn&apos;t receive the code?
        </span>
        {/* <Button className="px-1" isLoading={isLoading} onClick={handleResendMobile}> */}
        <h5 className="text-[13px] text-blue underline">Resend</h5>
        {/* </Button> */}
      </div>

    </form>
  );
};
