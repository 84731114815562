import { AppElement, AppFC } from '../../../../interfaces';
import { IconProps } from './types';

export const AlertErrorIcon: AppFC<IconProps> = ({ width = '80px', height = '80px', ...rest }): AppElement => (
  <div {...rest}>
    <svg xmlns="http://www.w3.org/2000/svg" style={{ width, height }} viewBox="0 0 80 80" fill="none" {...rest}>
      <path d="M40 30V42.5M70 40C70 43.9397 69.224 47.8407 67.7164 51.4805C66.2087 55.1203 63.999 58.4274 61.2132 61.2132C58.4274 63.999 55.1203 66.2087 51.4805 67.7164C47.8407 69.224 43.9397 70 40 70C36.0603 70 32.1593 69.224 28.5195 67.7164C24.8797 66.2087 21.5726 63.999 18.7868 61.2132C16.001 58.4274 13.7913 55.1203 12.2836 51.4805C10.776 47.8407 10 43.9397 10 40C10 32.0435 13.1607 24.4129 18.7868 18.7868C24.4129 13.1607 32.0435 10 40 10C47.9565 10 55.5871 13.1607 61.2132 18.7868C66.8393 24.4129 70 32.0435 70 40ZM40 52.5H40.0267V52.5267H40V52.5Z" stroke="#EE1616" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  </div>
);
