import React from 'react';
import { AppElement, AppFC } from '../../../interfaces';
import { BTCInfoCardProps } from './types';
import { ArrowIcon } from '../../Atoms/icons';
import { formatNumberWithK, numberWithCommas } from '../../../utils';

export const BTCInfoCard: AppFC<BTCInfoCardProps> = ({
  title,
  value,
  increment,
  buyingAmount,
  miningAmount,
  currency,
  ...rest
}): AppElement => {
  const getIncrementClass = () => {
    switch (true) {
      case increment > 0:
        return 'text-green';
      case increment < 0:
        return 'text-red';
      default:
        return 'text-white/60';
    }
  };
  return (
    <div className="flex flex-col gap-5 rounded-lg border border-white/30 p-2 w-full" {...rest}>
      <div className="flex flex-col gap-5 p-3 justify-center h-full">
        <span className="font-bold text-[24px] leading-none">{title}</span>
        <div className="flex gap-2 justify-between">
          <div>
            <span className="text-[12px]">BTC value</span>
            <div className="relative group">
              <h4>
                {value < 0 ? '-' : ''}
                {currency}
                {formatNumberWithK(Math.abs(value))}
              </h4>
              <div className="absolute left-0 top-[100%] bg-default rounded shadow shadow-white/20 px-2 hidden group-hover:block">
                <h4 className="font-bold">
                  {currency}
                  {numberWithCommas(value, '', 2)}
                </h4>
              </div>
            </div>
          </div>
          <div>
            <span className="text-[12px]">BTC price increment</span>
            <div className={`flex items-center gap-1 ${getIncrementClass()}`}>
              {!!increment && <ArrowIcon className={`${increment > 0 ? '-rotate-45 mb-0.5' : 'rotate-[135deg]'} w-6 h-6`} />}
              <h4>
                {Math.abs(increment)}
                %
              </h4>
            </div>

          </div>
        </div>
      </div>
      {/* <div className="flex flex-col gap-0.5 rounded-lg overflow-hidden"> */}
      {/*   <div className="flex justify-between bg-filled p-4"> */}
      {/*     <span>Buying</span> */}
      {/*     <span className="w-max"> */}
      {/*       ₿ */}
      {/*       {' '} */}
      {/*       {numberWithCommas(buyingAmount, '', 5)} */}
      {/*     </span> */}
      {/*     <span className="w-max"> */}
      {/*       {currency} */}
      {/*       {' '} */}
      {/*       {numberWithCommas(buyingAmount * value, '', 2)} */}
      {/*     </span> */}
      {/*   </div> */}
      {/*   <div className="flex justify-between bg-filled p-4"> */}
      {/*     <span>Mining</span> */}
      {/*     <span className="w-max"> */}
      {/*       ₿ */}
      {/*       {' '} */}
      {/*       {numberWithCommas(miningAmount, '', 5)} */}
      {/*     </span> */}
      {/*     <span className="w-max"> */}
      {/*       {currency} */}
      {/*       {' '} */}
      {/*       {numberWithCommas(miningAmount * value, '', 2)} */}
      {/*     </span> */}
      {/*   </div> */}
      {/* </div> */}
    </div>
  );
};
