import React, { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

import { AppElement, AppFC } from '../../../interfaces';

import {
  Loader,
} from '../../Atoms';

export const DocusignResponse: AppFC = (): AppElement => {
  const [params] = useSearchParams();
  const envelopeId = params.get('envelope');

  useEffect(() => {
    if (envelopeId) {
      const message = {
        type: 'signMessage',
        data: { envelopeId },
      };
      window.parent.postMessage(message);
    }
  }, []);

  return (
    <Loader className="w-8 h-8" />
  );
};
