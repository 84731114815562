import { BlockchainAccountDetailsInterface, EURAccountDetailsInterface } from '../stores/contexts';
import {
  // currencyKeys,
  CurrencyTypes, ProjectCurrencyTypes,
} from '../constants';
import { RatesInterface } from '../types/projects';
import { numberWithCommas } from './ui';

type DetailItemInterface = {
  label: string,
  value: string,
  width?: string,
  isQrCode?: boolean,
};
export const getAccountDetails = (
  data?: EURAccountDetailsInterface
  | BlockchainAccountDetailsInterface,

): DetailItemInterface[] => {
  switch (data?.currency) {
    case CurrencyTypes.EUR: {
      const account = data as EURAccountDetailsInterface;
      return [{
        label: 'Currency',
        value: account.currency || '-',
      },
      {
        label: 'Bank Country',
        value: account.bankCountry || '-',
      },
      {
        label: 'Bank Address',
        value: account.bankAddress || '-',
      },
      {
        label: 'IBAN',
        value: account.iban || '-',
      },
      {
        label: 'BIC',
        value: account.bic || '-',
      },
      {
        label: 'Account Number',
        value: account.accountNumber || '-',
      },
      {
        label: 'Bank Name',
        value: account.bankName || '-',
      },
      {
        label: 'Bank Holder Name',
        value: account.bankAccountHolderName || '',
      },
      ];
    }
    case CurrencyTypes.USDC:
    case CurrencyTypes.USDT: {
      const account = data as BlockchainAccountDetailsInterface;
      return [{
        label: 'Currency',
        value: data.currency || '-',
      },
      {
        label: 'Blockchain deposit address',
        value: account.blockchainDepositAddress || '-',
        isQrCode: true,
      },
      {
        label: 'Blockchain network',
        value: account.blockchainNetwork.name || '-',
      },
      {
        label: 'Blockchain network type',
        value: account.blockchainNetwork.type || '-',
      },
      {
        label: 'Blockchain contract address',
        value: account.blockchainNetwork.contractAddress || '-',
      },
      ];
    }

    case CurrencyTypes.BTC:
    case CurrencyTypes.BNB:
    case CurrencyTypes.ETH: {
      const account = data as BlockchainAccountDetailsInterface;
      return [{
        label: 'Currency',
        value: data.currency || '-',
      },
      {
        label: 'Blockchain deposit address',
        value: account.blockchainDepositAddress || '-',
        isQrCode: true,
      },
      {
        label: 'Blockchain network',
        value: account.blockchainNetwork.name || '-',
      },
      ];
    }

    default: return [];
  }
};

export const getFormattedAmount = (amount?: string | number, currency?: string, from: 1 | -1 = 1) => {
  if (amount) {
    switch (currency) {
      case 'wei':
        return Number(amount) * 10 ** (-18 * from);
      case 'cents':
        return Number(amount) * 10 ** (-2 * from);

      case 'satoshis':
        return Number(amount) * 10 ** (-8 * from);
      default: return Number(amount);
    }
  }
  return 0;
};

export const convertAmountByCurrency = (
  rates?: RatesInterface,
  currency?: CurrencyTypes | ProjectCurrencyTypes,
  amount?: string | number,
  decimals: number = 5,
) => {
  if (amount && currency === CurrencyTypes.EUR) {
    return numberWithCommas(Number(amount) * (Number(rates?.rates.eUR) || 1), CurrencyTypes.EUR, decimals);
  }
  if (amount && currency) {
    return numberWithCommas(
      Number(amount) / (Number(rates?.rates.eUR) || 1),
      currency,
      decimals,
    );
  }
  return numberWithCommas(Number(amount), currency, decimals) || '-';
};

export const getNetworkForWithdraw = (currency?:CurrencyTypes) => {
  switch (currency) {
    case CurrencyTypes.BNB:
      return 'BSC';
    case CurrencyTypes.USDC:
    case CurrencyTypes.USDT:
    case CurrencyTypes.ETH:
      return 'ETH';
    case CurrencyTypes.BTC:
      return 'BTC';
    default:
      return '';
  }
};
