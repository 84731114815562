import bnbIcon from '../assets/icons/wallets/BNB.svg';
import btcIcon from '../assets/icons/wallets/BTC.svg';
import ethIcon from '../assets/icons/wallets/ETH.svg';
import eurIcon from '../assets/icons/wallets/EUR.svg';
import usdcIcon from '../assets/icons/wallets/USDC.svg';
import usdtIcon from '../assets/icons/wallets/USDT.svg';

export enum CurrencyTypes {
  BNB = 'BNB',
  BTC = 'BTC',
  ETH = 'ETH',
  EUR = 'EUR',
  USDC = 'USDC',
  USDT = 'USDT',
}
export enum ProjectCurrencyTypes {
  USD = 'USD',
  EUR = 'EUR',
}
export enum InvestmentStatusTypes {
  Pending = 'pending',
  Approved = 'approved',
  Rejected = 'rejected',
}

export const walletIcons: { [key: string]: string } = {
  eUR: eurIcon,
  bTC: btcIcon,
  uSDC: usdcIcon,
  uSDT: usdtIcon,
  eTH: ethIcon,
  bNB: bnbIcon,
};

export const currencyKeys = {
  [CurrencyTypes.EUR]: 'eUR',
  [CurrencyTypes.ETH]: 'eTH',
  [CurrencyTypes.BTC]: 'bTC',
  [CurrencyTypes.BNB]: 'bNB',
  [CurrencyTypes.USDC]: 'uSDC',
  [CurrencyTypes.USDT]: 'uSDT',
};

export const walletKeys: string[] = ['eUR', 'bTC', 'uSDC', 'uSDT', 'eTH', 'bNB'];

export const networksOptions = [
  { value: 'BTC', label: 'Bitcoin Testnet 3' },
  { value: 'ETH', label: 'USD Coin Test (Goerli)' },
  { value: 'ETH', label: 'Tether USD (Goerli)' },
  { value: 'ETH', label: 'Ethereum Test (Goerli)' },
  { value: 'BSC', label: 'Binance Coin Test (BSC)' },
];
export const currencyOptions = [
  { value: 'BTC', label: 'BTC' },
  { value: 'ETH', label: 'ETH' },
  { value: 'USDT', label: 'USDT' },
  { value: 'USDC', label: 'USDC' },
  { value: 'BNB', label: 'BNB' },
  { value: 'EUR', label: 'EUR' },
];

export const minTradeValues = {
  EUR: 20,
  USDT: 20,
  USDC: 20,
  BTC: 0.0001,
  ETH: 0.01,
  BNB: 0.05,
};
