import { CurrencyTypes, ProjectCurrencyTypes } from '../constants';

export const numberWithCommas = (num?: number | string | null, currency?: CurrencyTypes | string, decimals?: number) : string => {
  if (num || num === 0) {
    const currentDecimals = Number(num) > 100 ? 2 : decimals;
    const value = currentDecimals ? Number(num).toFixed(currentDecimals) : num.toString();

    const valueWithDecimals = currentDecimals ? value.replace(/\.?0+$/, '') : value;

    const parts = valueWithDecimals.split('.');

    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');

    if (currency === CurrencyTypes.EUR) {
      return `€ ${parts.join('.')}`;
    }
    return `${parts.join('.')}${currency || ''}`;
  }

  return '-';
};

export const formatNumber = (value: string) => {
  // Remove all non-numeric characters except the decimal point
  const newValue = value.replace(/[^\d.]/g, '');

  // Split the value on the first decimal point
  const parts = newValue.split('.');

  // Format the integer part with commas
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');

  // Allow only one decimal point and join the integer and fractional parts
  return parts.length > 1 ? `${parts[0]}.${parts[1].slice(0, 2)}` : parts[0];
};

export const formatNumberWithK = (num: number) => {
  if (Math.abs(num) >= 1_000_000_000) {
    const rounded = Math.round((num / 1_000_000_000) * 10) / 10;
    return rounded % 1 === 0 ? `${Math.round(num / 1_000_000_000)}b` : `${rounded}b`;
  }
  if (Math.abs(num) >= 1_000_000) {
    const rounded = Math.round((num / 1_000_000) * 10) / 10;
    return rounded % 1 === 0 ? `${Math.round(num / 1_000_000)}m` : `${rounded}m`;
  }
  if (Math.abs(num) >= 1_000) {
    const rounded = Math.round((num / 1_000) * 10) / 10;
    return rounded % 1 === 0 ? `${Math.round(num / 1_000)}k` : `${rounded}k`;
  }
  return numberWithCommas(num, '', 2);
};

export const capitalizeWords = (str: string) => str.toLowerCase().replace(/(?:^|\s)\S/g, (a) => a.toUpperCase());

export const createStringFromArray = (array: string[]) => array
  .map((item) => item
    .toLowerCase()
    .split('_')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' '))
  .join(', ');

export const getCurrencySign = (currency?: ProjectCurrencyTypes) => {
  if (currency === ProjectCurrencyTypes.USD) {
    return '$';
  }
  if (currency === ProjectCurrencyTypes.EUR) {
    return '€';
  }
  return '';
};
