export enum ButtonCategory {
  Filled = 'filled',
  Outlined = 'Outlined',
  Light = 'Light',
  Default = 'Default',
}
export enum InputCategory {
  // Text = 'text',
  Phone = 'phone',
}

export enum AlertCategory {
  Error = 'error',
  Warning = 'warning',
  Info = 'Info',
  Success = 'success',
}

export enum ModalTypes {
  KYCModal = 'KYCModal',
  AccountDetailsModal = 'AccountDetailsModal',
  QuotationDetailsModal = 'QuotationDetailsModal',
  DocusignModal = 'DocusignModal',
  SumsubModal = 'SumsubModal',
  InvestmentDetailsModal = 'InvestmentDetailsModal',
  TxConfirmModal = 'TxConfirmModal',
  SuccessModal = 'SuccessModal',
  FailModal = 'FailModal',
  PasswordChangeModal = 'PasswordChangeModal',
  BitcoinAddressModal = 'BitcoinAddressModal',
  BankingAccessInfoModal = 'BankingAccessInfoModal',
  BankingWithPassedKYCModal = 'BankingWithPassedKYCModal',
  BankingAccessFormModal = 'BankingAccessFormModal',
  WithdrawModal = 'WithdrawModal',
  ExchangeModal = 'ExchangeModal',
  QrCodeModal = 'QrCodeModal',
  DefaultModal = 'DefaultModal',
}
