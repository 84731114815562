import React, { useContext, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import { AuthHandler } from '../../../../../stores/handlers';
import { ViewContext, ViewModalActionTypes } from '../../../../../stores';

import { AppElement, AppFC } from '../../../../../interfaces';
import { PasswordChangeProps } from './types';
import { ButtonCategory } from '../../../../../constants';

import { Button } from '../../../../Atoms';
import { AppInput } from '../../../../Molecules';

const validationSchema = yup.object().shape({
  newPassword1: yup
    .string()
    .required('Please enter your password')
    .matches(
      /^(?=.{8,32}$)(?=.*[a-zA-Z].*)(?=.*[0-9].*)(?=.*[!"#$%&'()*+,\-./:;<=>?@[\\\]^_`{|}~])/,
      'Must contain 8-32 characters, at least one letter, one number and one special character',
    ),
  newPassword2: yup
    .string()
    .required('Please enter your password')
    .oneOf([yup.ref('newPassword1'), ''], 'Passwords must match')
    .matches(
      /^(?=.{8,32}$)(?=.*[a-zA-Z].*)(?=.*[0-9].*)(?=.*[!"#$%&'()*+,\-./:;<=>?@[\\\]^_`{|}~])/,
      'Must contain 8-32 characters, at least one letter, one number and one special character',
    ),
});
export const PasswordChange: AppFC = (): AppElement => {
  const [isLoading, setIsLoading] = useState(false);
  const { updateViewModal } = useContext(ViewContext);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      newPassword1: '',
      newPassword2: '',
    },
  });

  const { passwordChange } = AuthHandler();

  const onSubmit: SubmitHandler<PasswordChangeProps> = (data) => {
    setIsLoading(true);

    passwordChange(data)
      .then((res) => {
        updateViewModal({
          type: ViewModalActionTypes.SuccessModal,
          payload: {
            title: 'Password change success',
            description: res.detail,
          },
        });
      })
      .catch((reason) => {
        updateViewModal({
          type: ViewModalActionTypes.FailModal,
          payload: {
            title: 'Password change fail',
            description: reason.message,
          },
        });
      });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="relative h-full w-full flex flex-col items-center justify-center gap-14">
      <h4>Change password</h4>
      <div className="flex flex-col gap-10 items-center justify-center w-full">
        <AppInput
          label="New Password"
          name="newPassword1"
          type="password"
          control={control}
          placeholder="Enter new password"
          error={errors.newPassword1}
        />
        <AppInput
          label="Confirm Password"
          name="newPassword2"
          type="password"
          control={control}
          placeholder="Confirm new password"
          error={errors.newPassword2}
        />
      </div>
      <div className="w-full flex justify-between gap-2">
        <Button className="w-[calc(50%-28px)]  flex-1" category={ButtonCategory.Light} onClick={() => updateViewModal({ type: ViewModalActionTypes.Close })}>Cancel</Button>
        <Button type="submit" className="w-[calc(50%-28px)] flex-1" category={ButtonCategory.Filled} isLoading={isLoading}>Confirm</Button>
      </div>
    </form>
  );
};
