import axios, { AxiosResponse } from 'axios';
import {
  AuthToken,
  httpHeader,
  HTTPRequestDataType,
  HTTPRequestParamsType,
  HttpRequestProps,
} from './axios';

export const httpRequest = async ({
  data,
  params,
  url,
  method,
  withoutToken,
  cancelToken,
  headerType,
  apiKey,
  hmacToken,
}: HttpRequestProps<HTTPRequestDataType, HTTPRequestParamsType>) => {
  try {
    const authToken: AuthToken = new AuthToken();

    const tokenIsRefreshing = authToken.getTokenIsRefreshing();
    if (tokenIsRefreshing !== 'on') {
      return await axios({
        url,
        data: data || null,
        params: params || null,
        method,
        headers: {
          ...httpHeader({
            withoutToken,
            headerType,
            apiKey,
            hmacToken,
          }),
        },
        cancelToken: cancelToken || undefined,
      });
    }
    return {} as AxiosResponse;
  } catch (error) {
    return Promise.reject(error);
  }
};
