import {
  // AxiosError,
  CancelToken,
  Method,
} from 'axios';

import { CurrencyTypes, RequestTypes } from '../../../constants';

export type TokenDataType = {
  accessToken?: string,
  refreshToken?: string,
  /** temporary * */
  tokenIsRefreshing?: string,
};

export enum HttpRequestHeaderTypes {
  Json = 'JSON',
  MultiPart = 'MultiPart',
}

export type RequestHeaderProps = {
  headerType?: HttpRequestHeaderTypes,
  apiKey?: string,
  hmacToken?: string,
  withoutToken?: boolean,
};

export type CustomHeaderProps = {
  Accept?: string,
  Authorization?: string,
  type?: string,
  'apy-key'?: string,
  'Content-Type'?: string,
};

export type HttpRequestProps<D, P> = {
  url: string,
  data?: D,
  params?: P,
  method: RequestTypes | Method,
  withoutToken?: boolean,
  cancelToken?: CancelToken,
  apiKey?: string,
  hmacToken?: string,
  signal?: AbortSignal,
  headerType?: HttpRequestHeaderTypes,
};

// export interface ResponseInterface {
//   status: number,
//   action?: RequestStatusTypes,
//   data: any,
//   message: string,
// }

export type GetDefaultParamsType =
    | { limit?: string }
    | { format?: string }
    | { id?: string }
    | null;

export type HTTPRequestParamsType = GetDefaultParamsType;

// export type HttpError<T = unknown> = AxiosError<T>;

// export interface CustomError {
//   message: string;
//   code: number;
// }

export type SignUpDataProps = {
  firstName: string,
  lastName: string,
  email: string,
  phoneNumber: string,
  countryCode: string,
  countryOfResidence: string,
  password1: string,
  password2: string,
  isAgree: boolean,
  projectAndMiners: string,
};

export type VerifyEmailConfirmDataProps = {
  key: string,
};

export type SignInDataProps = {
  email: string,
  password: string,
};

export type TwoFaDataProps = {
  userId: string,
  verificationCode?: string,
  isTwoFaSkipped?: boolean,
};

export type SignOutDataProps = {
  refresh: string,
};

export type ResetPasswordDataProps = {
  email: string,
};

export type ResetPasswordConfirmDataProps = {
  newPassword1: string,
  newPassword2: string,
  uid?: string,
  token?: string,
};

export type VerifyMobileDataProps = {
  verificationCode: string,
};

export type UserUpdateDataProps = {
  country?: string,
  city?: string,
  state?: string,
  zipCode?: string,
  address?: string,
  birthdate?: string,
  profilePicture?: Blob,
  telegram?: string,
  phone?: string,
};

export type UserKYCDataProps = {
  isKycPassed: boolean
};

export type KYCDataProps = {
  birthdate:string,
  address: string,
  address2?: string,
  city?: string,
  zipCode?: string,
  state?: string,
  country?: string, // ISO 3166 alpha-2 format
  placeOfBirth: string,
  occupation?: string,
  sourceOfFunds?: string,
  purposeOfAccount?: string,
  selfPepDeclaration?: boolean,
  expectedIncomingTxVolumeYearly?: string,
  expectedOutgoingTxVolumeYearly?: string,
};

export type KYCProps = {
  tier: number,
};

export type BitcoinWalletDataProps = {
  bitcoinWalletAddress?: string,
};

export type AccountEnrichDataProps = {
  accountId: string,
};

export type TransactionSendDataProps = {
  sourceAccountId: string,
  destinationAccountId: string,
  amount: string,
};

export type TransactionConfirmDataProps = {
  challengeId: string,
  verificationCode: string,
  invoiceId: string,
};

export type InvoiceDataProps = {
  numberOfMiners: number,
};

export type TransactionConfirmResendDataProps = {
  challengeId: string,
};

export type InvestmentSpotsDataProps = {
  spots: number,
  invoiceId: string,
};

export type InvestmentAgreementDataProps = {
  invoiceNumber: number,
  spots: number,
  currency: CurrencyTypes,
  amount: number,
};

export type WithdrawCryptoDataProps = {
  sourceAccountId: string,
  amount: string,
  address: string,
  currency: CurrencyTypes,
  network: string,
};

export type WithdrawBankDataProps = {
  eurAccountId: string,
  amount: string,
  iban: string,
  bic: string,
};

export type ExchangeDataProps = {
  sourceAccountId: string,
  destinationAccountId: string,
  amount: string,
};

export type SubmitFormDataProps = {
  spots: number,
  telegram: string,
};

export type HTTPRequestDataType =
    | TokenDataType
    | SignUpDataProps
    | VerifyEmailConfirmDataProps
    | SignInDataProps
    | TwoFaDataProps
    | SignOutDataProps
    | ResetPasswordDataProps
    | ResetPasswordConfirmDataProps
    | VerifyMobileDataProps
    | UserUpdateDataProps
    | KYCDataProps
    | UserKYCDataProps
    | BitcoinWalletDataProps
    | AccountEnrichDataProps
    | TransactionSendDataProps
    | TransactionConfirmDataProps
    | TransactionConfirmResendDataProps
    | InvestmentSpotsDataProps
    | InvestmentAgreementDataProps
    | InvoiceDataProps
    | WithdrawCryptoDataProps
    | WithdrawBankDataProps
    | ExchangeDataProps
    | SubmitFormDataProps
    | FormData
    | KYCProps
    | { key: string };
