export const API_ROOT = process.env.REACT_APP_API_BASE_URL ? process.env.REACT_APP_API_BASE_URL.toString() : '';

export enum RequestStatusTypes {
  Waiting = 'Waiting',
  // Loading = 'Loading',
  Success = 'Success',
  // Error = 'Error',
  // NetworkError = 'NetworkError',
  // UserSignOut = 'UserSignOut',
  // Cancel = 'Cancel',
  // NoData = 'NoData',
}

export enum KYCStatusTypes {
  NOT_STARTED = 'NOT_STARTED',
  INITIATED = 'INITIATED', // The "Start KYC" endpoint has been called and the SumSub token has been fetched
  PENDING_REVIEW = 'PENDING_REVIEW', // Documents have been submitted and are pending review
  ON_HOLD = 'ON_HOLD', // Requires manual review from the compliance team
  APPROVED = 'APPROVED', // User approved
  REJECTED = 'REJECTED', // User rejected - Can be final or not
  REJECTED_FINAL = 'REJECTED_FINAL',

  SUMSUB_INIT = 'init',
  SUMSUB_PENDING = 'pending',
  SUMSUB_PRECHECKED = 'prechecked',
  SUMSUB_QUEUED = 'queued',
  SUMSUB_ON_HOLD = 'onHold',
  SUMSUB_COMPLETED = 'completed',

}

export enum RequestTypes {
  Get = 'GET',
  Post = 'POST',
  Put = 'PUT',
  // Delete = 'DELETE',
  // Patch = 'PATCH',
}

export const AUTH_API = {
  POST_SIGN_UP: `${API_ROOT}authentication/register/`,
  POST_EMAIL_VERIFICATION: `${API_ROOT}authentication/register/verify-email/`,
  POST_EMAIL_RESEND: `${API_ROOT}authentication/email/confirmation/resend/`,
  POST_SIGN_IN: `${API_ROOT}authentication/prelogin/`,
  POST_TWO_FA_AUTHORIZE: `${API_ROOT}authentication/login/`,
  GET_SIGN_OUT: `${API_ROOT}authentication/logout/`,
  POST_RESET: `${API_ROOT}authentication/password/reset/`,
  POST_PASSWORD_CHANGE: `${API_ROOT}authentication/password/change/`,
  POST_RESET_CONFIRM: `${API_ROOT}authentication/password/reset/confirm/`,
  POST_REFRESH_TOKEN: `${API_ROOT}token/refresh/`,
};

export const getProjectAPI = (id?: string | number) => ({
  PROJECTS: `${API_ROOT}projects/`,
  PROJECT: `${API_ROOT}projects/${id}`,
  PROJECTS_SPOTS: `${API_ROOT}projects/${id}/investment/`,
  // PROJECT_CONFIRM_TRANSACTION: `${API_ROOT}projects/${id}/transaction-confirm/`,
  // PROJECT_CONFIRM_TRANSACTION_RESEND: `${API_ROOT}projects/${id}/transaction-confirm-resend/`,
  // PROJECT_TRANSACTION: `${API_ROOT}projects/${id}/transaction/`,
  PROJECT_AGREEMENT: `${API_ROOT}projects/${id}/investment-agreement/`,
  PROJECT_AGREEMENT_STATUS: `${API_ROOT}projects/${id}/investment-agreement-status/`,
  PROJECT_AGREEMENT_EMAIL: `${API_ROOT}projects/${id}/investment-agreement-email/`,
  PROJECT_INVOICE_GENERATE: `${API_ROOT}projects/${id}/invoices/`,
  PROJECT_SEND_EMAIL: `${API_ROOT}projects/${id}/submit-form/`,
});

export const getInvoiceAPI = (id?: string) => ({
  INVOICES: `${API_ROOT}invoices/`,
  INVOICES_DOWNLOAD: `${API_ROOT}invoices/${id}/download`,
  INVOICES_HISTORY: `${API_ROOT}transaction/`,
});

export const FINANCE_API = {
  EXCHANGE_RATES: `${API_ROOT}finance/exchange-rates/`,
  BTC_NETWORK_HASH_RATE: `${API_ROOT}finance/calculator/`,
  // BANK_WITHDRAW: `${API_ROOT}finance/bank-withdraw/`,
  // CRYPTO_WITHDRAW: `${API_ROOT}finance/cryptowithdraw/`,
  // WHITELISTED_ADDRESSES: `${API_ROOT}finance/whitelisted-addresses/`,
  // TRANSACTION_CONFIRM: `${API_ROOT}finance/withdraw-confirm/`,
  // TRANSACTION_CONFIRM_RESEND: `${API_ROOT}finance/withdraw-confirm-resend/`,
  // CURRENCY_SWAP: `${API_ROOT}finance/currency-swap/`,
  // EXCHANGE_FEE: `${API_ROOT}finance/currency-swap-fee/`,

};

export const PROFILE_API = {
  PROFILE: `${API_ROOT}profile/`,
  PROFILE_UPDATE: `${API_ROOT}profile/`,
  // PROFILE_VERIFY_MOBILE: `${API_ROOT}profile/verify-mobile/`,
  // PROFILE_RESEND_SMS: `${API_ROOT}profile/resend-sms/`,

  UPDATE_PROFILE_KYC: `${API_ROOT}profile/kyc-passed/`,
  POST_KYC_START: `${API_ROOT}profile/kyc/start/`,
  GET_KYC_STATUS: `${API_ROOT}profile/kyc/status/`,
  UPDATE: `${API_ROOT}profile/kyc/update/`,

  // GET_ALL_WALLETS: `${API_ROOT}profile/wallet/`,
  // ENRICH_ACCOUNT: `${API_ROOT}profile/wallet/enrich/`,
  //
  // GET_CORPORATE_ACCOUNT: `${API_ROOT}corporate/account/`,
};

export const CRYPTO_API = {
  PRICES: 'https://api.coinbase.com/v2/prices',
  NICEHASH: 'https://api-test.nicehash.com',
};
