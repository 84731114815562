import { AppElement, AppFC } from '../../../interfaces';
import { InvoiceCardProps } from './types';

import { capitalizeWords, dateFormat, numberWithCommas } from '../../../utils';

export const HistoryCard: AppFC<InvoiceCardProps> = ({
  type,
  description,
  totalAmount,
  createdAt,
  status,
  className,
  ...rest
}): AppElement => {
  const date = dateFormat(createdAt, 'MMM D, YYYY');
  const items = [
    {
      label: 'Type',
      value: capitalizeWords(type),
    },
    {
      label: 'Total amount',
      value: numberWithCommas(totalAmount),
    },
    {
      label: 'Description',
      value: description,
    },
    {
      label: 'Status',
      value: capitalizeWords(status),
    },
    {
      label: 'Date',
      value: date,
    },
  ];

  return (
    <div className={`max-w-sm grid grid-cols-2 gap-6 p-6 h-full rounded-xl ${className || ''}`} {...rest}>
      {items.map(({ label, value }) => (
        <div className="flex flex-col gap-2">
          <h5 className="text-[12px] text-[#A0A0A0]">{label}</h5>
          <h5 className="text-[14px]">{value}</h5>
        </div>
      ))}
    </div>
  );
};
