import {
  useContext, useEffect, useRef, useState,
} from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import { AuthToken } from '../../../services';

import { ProfileContext, ViewContext } from '../../../stores';
import { ProfileHandler } from '../../../stores/handlers';

import { AppElement, AppFC } from '../../../interfaces';
import {
  AuthPaths,
  // KYCStatusTypes,
  Paths,
} from '../../../constants';
// import { getStatus } from '../../../utils';

import { SideBarItem } from '../../Molecules/SideBar/types';
import { Header } from './Header';
import { SideBar } from '../../Molecules';
import { Loader } from '../../Atoms';
import { HamburgerIcon, PantheonIcon } from '../../Atoms/icons';

import projectsIcon from '../../../assets/icons/pages/projects.svg';
import invoicesIcon from '../../../assets/icons/pages/invoices.svg';
import historyIcon from '../../../assets/icons/pages/history.svg';

const items: SideBarItem[] = [
  {
    title: 'Projects',
    icon: projectsIcon,
    path: Paths.Projects,
  },
  {
    title: 'Invoices',
    icon: invoicesIcon,
    path: Paths.Invoices,
  },
  {
    title: 'Transaction History',
    icon: historyIcon,
    path: Paths.InvoicesHistory,
  },
];
export const Layout: AppFC = ({ className, children }): AppElement => {
  const authToken: AuthToken = new AuthToken();

  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const [walletMenuIsOpen, setWalletMenuIsOpen] = useState(false);
  const [sidebarIsOpen, setSidebarIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const { profileState } = useContext(ProfileContext);
  const { viewModal: { isOpen } } = useContext(ViewContext);

  const { pathname } = useLocation();
  const sidebarRef = useRef<HTMLDivElement | null>(null);

  const {
    // getAllWallets,
    getProfile,
  } = ProfileHandler();

  const {
    phoneVerified,
    KYCStatus, // strigaId,
  } = profileState;

  const location = useLocation();

  // const status = getStatus(KYCStatus);

  const handleClickOutsideSidebar = (e: MouseEvent) => {
    if (sidebarRef.current && !sidebarRef.current.contains(e.target as Node)) {
      if (sidebarIsOpen) {
        setSidebarIsOpen(false);
      }
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutsideSidebar);
    return () => {
      document.removeEventListener('click', handleClickOutsideSidebar);
    };
  }, [sidebarIsOpen]);

  useEffect(() => {
    setMenuIsOpen(false);
    setSidebarIsOpen(false);
  }, [pathname]);

  useEffect(() => {
    getProfile().then(() => {
      setIsLoading(false);
    }).catch(() => { setIsLoading(false); });
  }, [KYCStatus]);

  if (!authToken.getAccessToken()) {
    return <Navigate to={`${AuthPaths.SignIn}/${location.search}`} />;
  }

  return (
    <>
      <Helmet>
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      {
        isLoading ? <Loader className="self-center w-8 h-8" />
          : (
            <>
              {!phoneVerified && authToken.getTokenIsRefreshing() !== 'on' ? <Navigate to={AuthPaths.MobileVerify} />
                : (
                  <div style={isOpen ? { position: 'fixed', top: `-${window.scrollY}px` } : { position: 'relative' }} className={`min-h-screen w-full flex flex-col  ${className}`}>
                    <div className={`w-full h-full lg:hidden ${sidebarIsOpen ? 'bg-black bg-opacity-50 z-40' : 'z-0'} fixed`}>
                      <div ref={sidebarRef} className={`w-72 bg-default flex flex-col gap-4 fixed px-5 transition-transform ${sidebarIsOpen ? 'translate-x-0' : '-translate-x-full'}`}>
                        <div className="flex gap-4 items-center h-[4.5rem]">
                          <button
                            className="p-0"
                            onClick={() => {
                              setSidebarIsOpen(false);
                            }}
                          >
                            <HamburgerIcon />
                          </button>
                          <PantheonIcon />
                        </div>
                        {pathname !== Paths.Calculator && <SideBar items={items} className="w-full" />}
                      </div>
                    </div>
                    <Header
                      menuIsOpen={menuIsOpen}
                      walletMenuIsOpen={walletMenuIsOpen}
                      toggleMenu={setMenuIsOpen}
                      toggleWalletMenu={setWalletMenuIsOpen}
                      sidebarIsOpen={sidebarIsOpen}
                      toggleSidebar={setSidebarIsOpen}
                      className="px-10 max-lg:px-5"
                    />
                    <div className="flex flex-1 p-10 max-md:px-5">
                      {pathname !== Paths.ProfileEdit && pathname !== Paths.Calculator && <SideBar items={items} className="max-lg:hidden" />}
                      <div className={`flex flex-col ${pathname !== Paths.Calculator ? 'px-12 max-sm:px-0 max-lg:px-8' : ''} w-full z-10`}>
                        {children}
                      </div>
                    </div>
                  </div>
                )}
            </>
          )
      }
    </>
  );
};
