import ReactSelect from 'react-select';
import { useState } from 'react';
import { AppElement, AppFC } from '../../../interfaces';
import { SelectProps } from './types';

import { BlockedIcon, DropdownIcon } from '../icons';

// export const Select: AppFC<SelectProps> = ({
//   className,
//   disabled,
//   blocked,
//   error,
//   ...rest
// }): AppElement => (
//   <div className={`relative w-full ${className} ${rest.value ? 'text-white' : 'text-mediumGray'}`}>
//     <select {...rest} disabled={blocked || disabled} className={`relative appearance-none w-full rounded-lg border px-4 py-3 focus:outline-none h-12 z-10 ${blocked || disabled ? 'bg-disabled text-[#99938C] pointer-events-none focus:border-default' : 'bg-transparent'} ${error ? 'border-error focus:border-error focus:border-2' : 'border-default focus:border-lightBrown focus:border-2'}`}>
//       {rest.placeholder && (
//         <option className="disabled" value="" disabled>
//           {rest.placeholder}
//         </option>
//       )}
//       {rest.options.map((option, index) => (
//         <option key={index} value={option.value}>
//           {option.label}
//         </option>
//       ))}
//     </select>
//     {!blocked
//       ? <DropdownIcon color={disabled ? 'rgba(0, 0, 0, 0.30)' : 'white'} className="absolute z-10 translate-y-[-50%] top-[50%] right-[1rem] hover:cursor-pointer" />
//       : <BlockedIcon className="absolute z-10 translate-y-[-50%] top-[50%] right-[1rem]" />}
//   </div>
// );

export const Select: AppFC<SelectProps> = ({
  className,
  name,
  options,
  value,
  defaultValue,
  placeholder,
  disabled,
  blocked,
  onChange,
  isMulti,
  error,
}): AppElement => {
  const [menuOpen, setMenuOpen] = useState(false);

  const customStyles = {
    control: (provided: any) => ({
      ...provided,
      backgroundColor: error ? '#401F1F99' : 'transparent', // bg-error : bg-input
      borderRadius: '0.5rem',
      borderColor: error ? 'rgba(255, 40, 40, 0.8)' : '#FFFFFF4D', // border-default
      height: isMulti ? 'auto' : '3rem',
      width: '100%',
      paddingLeft: '16px',
      paddingRight: '16px',
      paddingTop: isMulti ? '8px' : '',
      paddingBottom: isMulti ? '8px' : '',
      cursor: 'pointer',
      boxShadow: 'none',
      ':hover': {
        borderColor: error ? 'rgba(255, 40, 40, 0.8)' : '#FFFFFF4D', // border-default
      },
      ':focus': {
        backgroundColor: 'transparent', // bg-inputFocus
        borderColor: error ? 'rgba(255, 40, 40, 0.8)' : '#D6B389', // border-default
        borderWidth: '2px',
      },
    }),
    placeholder: (provided: any) => ({
      ...provided,
      color: '#7B8BA5', // text-disabled
    }),
    input: (provided: any) => ({
      ...provided,
      color: 'white',
    }),
    menu: (provided: any) => ({
      ...provided,
      backgroundColor: '#0f1625', // bg-input
      overflow: 'hidden',
      padding: 0,
      borderRadius: '0.5rem',
      border: '1px #FFFFFF4D solid',
      marginTop: '6px',
      marginBottom: '6px',
      zIndex: 100,
      width: '100%',
    }),
    option: (provided: any, state: any) => ({
      ...provided,
      backgroundColor: state.isSelected ? '#D6B389' : 'transparent', // bg-primary
      '&:hover': {
        backgroundColor: !state?.isSelected ? '#D6B38999' : '#D6B389', // bg-primaryHover : bg-primary
      },
      paddingLeft: '16px',
      fontSize: '14px',
      color: 'white',
      width: '100%',
      zIndex: 100,
    }),
    valueContainer: (provided: any) => ({
      ...provided,
      padding: 0,
      fontSize: '14px',
      height: '100%',
    }),
    multiValue: (provided: any) => ({
      ...provided,
      backgroundColor: '#D6B389', // bg-input
      borderRadius: '0.5rem',
      border: '1px #B8C3FF14 solid',
      color: 'white',
    }),
    multiValueLabel: (provided: any) => ({
      ...provided,
      color: 'white',
    }),
    multiValueRemove: (provided: any) => ({
      ...provided,
      '&:hover': {
        backgroundColor: 'transparent',
        color: 'white',
      },
    }),
    singleValue: (provided: any) => ({
      ...provided,
      color: blocked || disabled ? '#7B8BA5' : 'white', // text-disabled
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
      height: '100%',
    }),
    indicatorSeparator: (provided: any) => ({
      ...provided,
      display: 'none',
    }),
  };

  const dropdownIndicator = () => (!blocked ? <DropdownIcon color={disabled ? 'rgba(0, 0, 0, 0.30)' : 'white'} className={menuOpen ? 'transform rotate-180' : ''} /> : <BlockedIcon />);

  if (isMulti) {
    return (
      <div className={`relative ${className} ${blocked || disabled ? 'text-disabled' : 'text-white'}`}>
        <ReactSelect
          name={name}
          options={options}
          placeholder={placeholder}
          components={{ DropdownIndicator: dropdownIndicator }}
          styles={customStyles}
          isDisabled={blocked || disabled}
          onMenuOpen={() => setMenuOpen(true)}
          onMenuClose={() => setMenuOpen(false)}
          menuPlacement="auto"
          isMulti
          defaultValue={options.filter((option) => (defaultValue as Array<string>)?.includes(option.value))}
          value={options.filter((option) => (value as Array<string>)?.includes(option.value))}
          menuShouldScrollIntoView
          onChange={(newValue) => {
            if (onChange) {
              const values = newValue.map((item) => item.value);
              onChange(values);
            }
          }}
        />
        {!!error && <p className="absolute w-full text-[10px] leading-6 text-end text-error">{error.message}</p>}
      </div>
    );
  }

  return (
    <div className={`relative ${className} ${blocked || disabled ? 'text-disabled' : 'text-white'}`}>
      <ReactSelect
        name={name}
        options={options}
        placeholder={placeholder}
        components={{ DropdownIndicator: dropdownIndicator }}
        styles={customStyles}
        isDisabled={blocked || disabled}
        onMenuOpen={() => setMenuOpen(true)}
        onMenuClose={() => setMenuOpen(false)}
        menuPlacement="auto"
        defaultValue={options.find((option) => option.value === defaultValue)}
        value={options.find((option) => option.value === value)}
        menuShouldScrollIntoView
        onChange={(newValue) => {
          if (onChange) {
            onChange(newValue!.value);
          }
        }}
      />
      {!!error && <p className="absolute w-full text-[10px] leading-6 text-end text-error">{error.message}</p>}
    </div>
  );
};
