import { useContext, useState } from 'react';

import { ProjectsContext } from '../../../stores';
import { InvoiceHandler } from '../../../stores/handlers';

import { AppElement, AppFC } from '../../../interfaces';
import { InvoiceRowProps } from './types';
import {
  dateFormat,
  reorderDateString,
} from '../../../utils';

import { Loader } from '../../Atoms';
import { DownloadIcon } from '../../Atoms/icons';

export const InvoiceRow: AppFC<InvoiceRowProps> = ({
  id,
  invoiceIssueDate,
  invoiceNumber,
  location,
  numberOfMiners,
  pricePerMiner,
  projectId,
  totalAmount,
  currency,
  type,
  status,
  className,
  ...rest
}): AppElement => {
  const { projectsState: { projects } } = useContext(ProjectsContext);
  const { downloadInvoice } = InvoiceHandler();
  const [isLoading, setIsLoading] = useState(false);

  const invoiceProject = projects.find((project) => project.id === projectId);
  const formattedDate = reorderDateString(invoiceIssueDate);
  const date = dateFormat(formattedDate, 'MMM D, YYYY');
  const handleDownloadClick = () => {
    setIsLoading(true);
    downloadInvoice(id).then(() => { setIsLoading(false); }).catch(() => { setIsLoading(false); });
  };

  return (
    <tr className={`h-[72px] rounded-lg ${className || ''}`} {...rest}>
      <th scope="row" className="rounded-l-lg pl-6 font-medium">
        {invoiceNumber}
      </th>
      <td className="px-3">
        Miner
        {' '}
        {type.charAt(0).toUpperCase() + type.slice(1)}
      </td>
      {invoiceProject && (
      <td className="px-3">
        {invoiceProject.title}
      </td>
      )}
      <td className="px-3">
        {location}
      </td>
      <td className="px-6">
        {status === 'in_progress' && 'Pending'}
        {status === 'paid' && 'Approved'}
      </td>
      <td className="px-3">
        {date}
      </td>
      <td className="rounded-r-lg px-4 w-10">
        <button onClick={handleDownloadClick} disabled={isLoading} className="h-10 w-10 p-0 flex items-center justify-center bg-download rounded-full text-[13px] text-white">
          {!isLoading ? <DownloadIcon /> : <Loader className="w-6 h-6" />}
        </button>
      </td>
    </tr>
  );
};
