import { InvoicesActionTypes } from './actions';
import { UseReducerType } from '../../interfaces';
import { InvoicesActionType } from './interfaces';
import { InvoicesInterface } from '../../../types/invoices';

export const invoicesReducer: UseReducerType<InvoicesInterface, InvoicesActionType> = (
  state,
  action,
): InvoicesInterface => {
  switch (action.type) {
    case InvoicesActionTypes.SetInvoices:
      return { invoices: action.payload };
    default:
      return state;
  }
};
