import React, { useContext, useState } from 'react';

import {
  ProfileContext, ProjectsContext, ViewContext, ViewModalActionTypes,
} from '../../../../../stores';

import { AppElement, AppFC } from '../../../../../interfaces';

import { Button, InfoRaw } from '../../../../Atoms';
import {
  AlertCategory, ButtonCategory, CurrencyTypes, KYCStatusTypes,
} from '../../../../../constants';
import { CurrencyAmountBox, KYCAlert } from '../../../../Molecules';
import { ReviewResultInterface } from '../../../../../stores/contexts';
import { generateErrorAlert, getStatus } from '../../../../../utils';
import { InvoiceHandler, ProjectHandler } from '../../../../../stores/handlers';

export const QuotationDetails: AppFC = (): AppElement => {
  const [isLoading, setIsLoading] = useState(false);
  const [alert, setAlert] = useState<AppElement>();
  const {
    viewModal: {
      title, description, info, ref,
    },
    updateViewModal,
  } = useContext(ViewContext);
  const { projectsState: { rates } } = useContext(ProjectsContext);
  const { profileState: { KYCStatus, reviewResult } } = useContext(ProfileContext);
  const { createInvestmentAgreement } = ProjectHandler();
  const { generateInvoice } = InvoiceHandler();

  const status = getStatus(KYCStatus as KYCStatusTypes, reviewResult as ReviewResultInterface);
  const {
    selectedAccount, totalPrice, projectCurrency, projectId, pricePerMiner, spotsAmount,
  } = info || {};

  const handleInvestClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    if (status === KYCStatusTypes.ON_HOLD) {
      updateViewModal({
        type: ViewModalActionTypes.DefaultModal,
        payload: {
          isClosable: false,
          info,
          content: <KYCAlert
            className="max-w-sm px-8"
            category={AlertCategory.Warning}
            title="KYC Pending"
            description="We are currently reviewing your personal data and will keep you updated on the outcome."
            isVertical
            onClose={() => updateViewModal({ type: ViewModalActionTypes.Close })}
            isClosable
          />,
        },
      });
    } else {
      if (status === KYCStatusTypes.INITIATED) {
        updateViewModal({
          type: ViewModalActionTypes.BankingAccessInfoModal,
          payload: {
            title: 'Know your customer (KYC)',
            description: 'In order to buy a mining device, clients are required to complete an enhanced Know Your Customer (KYC) process.',
            ref,
            info: {
              ...info,
              totalPrice: Number(pricePerMiner) * Number(spotsAmount),
              spotsAmount,
              projectName: title,
              projectId,
            },
            isClosable: true,
          },
        });
      }

      if (status === KYCStatusTypes.APPROVED) {
        /** Investment flow - 1.Invoice generation by spots * */

        if (projectId) {
          setIsLoading(true);
          const invoiceData = {
            numberOfMiners: Number(info?.spotsAmount),
          };
          generateInvoice(projectId, invoiceData)
            .then((res) => {
              const {
                invoiceNumber,
                id,
              } = res.data;

              /** Investment flow - 2.Investment Agreement document generation by invoice number and spots * */
              const projectAgreementData = {
                invoiceNumber,
                spots: info?.spotsAmount as number,
                currency: CurrencyTypes.EUR, // selectedAccount.currency,
                amount: Number(((Number(info?.totalPrice) * (Number(rates?.rates.eUR) || 1)) * (info?.spotsAmount as number)).toFixed(4)),
              };
              createInvestmentAgreement(projectId as string, projectAgreementData)
                .then(({ data }) => {
                  setIsLoading(false);
                  /** Open external document link for sign * */
                  updateViewModal({
                    type: ViewModalActionTypes.DocusignModal,
                    payload: {
                      isClosable: false,
                      info: {
                        ...info,
                        url: data.redirectUrl,
                        selectedAccount,
                        invoiceId: id,
                      },
                    },
                  });
                })
                .catch((reason) => {
                  generateErrorAlert(reason, 'Purchase agreement file generation Failed!', setAlert, 'top-6 z-50');
                  setIsLoading(false);
                });
            })
            .catch((reason) => {
              generateErrorAlert(reason, 'Invoice Generation Failed!', setAlert, 'top-6 z-50');
              setIsLoading(false);
            });
        }

        // updateViewModal({
        //   type: ViewModalActionTypes.BankingWithPassedKYCModal,
        //   payload: {
        //     title: 'Sovereign Banking Access',
        //     description: 'The Pantheon Mining Portal, in collaboration with Striga, is pleased to offer advanced sovereign banking services.',
        //     ref,
        //     info: {
        //       ...info,
        //       totalPrice: Number(pricePerMiner) * Number(spotsAmount),
        //       pricePerMiner,
        //       spotsAmount,
        //       projectName: title,
        //       projectId,
        //       selectedAccount,
        //     },
        //   },
        // });
      }
    }
  };

  return (
    <div className="max-w-xl flex flex-col gap-6">
      {alert}
      <h4 className="text-[2rem] leading-[160%]">
        {title}
      </h4>
      <p className="text-[18px] font-medium">{description}</p>
      <div>
        {info?.infoRaws?.map(({ label, value }, index) => <InfoRaw className="px-2" key={label + index} label={label} value={value} />)}
        <InfoRaw
          className="bg-white text-black px-2 font-bold"
          label="Total Amount"
          value={(
            <CurrencyAmountBox
              projectCurrency={projectCurrency}
              amount={totalPrice}
              currency={selectedAccount?.currency || CurrencyTypes.EUR}
              decimals={5}
            />
)}
        />
      </div>
      <ul className="mt-6 list-none [&>li]:font-normal [&>li]:text-[13px] [&>li]:flex">
        <li>Disclaimer:</li>
        <li>
          <span className="mr-1">*</span>
          Average hours means not every month is the same amount of days thus hours.
        </li>
        <li>
          <span className="mr-1">*</span>
          Miners can run on a higher hash rate from time to time using more electricity
        </li>
        <li>
          <span className="mr-1">*</span>
          Miners can be offline and use less
        </li>
      </ul>
      <div className="w-full justify-end flex gap-2 max-[450px]:flex-col max-[450px]:[&>button]:w-full">
        <Button className="w-52 p-0" category={ButtonCategory.Light} onClick={() => { updateViewModal({ type: ViewModalActionTypes.Close }); }}>Cancel</Button>
        <Button className="w-52 p-0" category={ButtonCategory.Filled} onClick={handleInvestClick} isLoading={isLoading}>Approve & Proceed</Button>
      </div>
    </div>
  );
};
