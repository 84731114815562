import {
  useContext,
  useEffect,
  useRef,
  useState,
  MouseEvent as ReactMouseEvent,
} from 'react';
import { NavLink, useNavigate } from 'react-router-dom';

import { AuthHandler } from '../../../../stores/handlers';
import { ProfileContext, ViewContext, ViewModalActionTypes } from '../../../../stores';
import { ReviewResultInterface } from '../../../../stores/contexts';

import { AppElement, AppFC } from '../../../../interfaces';
import { MenuProps } from './types';
import {
  AuthPaths, KYCStatusTypes,
  Paths,
} from '../../../../constants';
import { getStatus } from '../../../../utils';

import { Button } from '../../../Atoms';

export const Menu: AppFC<MenuProps> = ({
  menuIsOpen,
  toggleMenu,
}): AppElement => {
  const menuRef = useRef<HTMLDivElement | null>(null);
  const modalRef = useRef<HTMLDivElement | null>(null);

  const {
    viewModal: {
      isOpen,
      isReload,
      ref,
      kycTier,
    },
    updateViewModal,
  } = useContext(ViewContext);

  const { profileState: { strigaId, KYCStatus, reviewResult } } = useContext(ProfileContext);

  const status = getStatus(KYCStatus as KYCStatusTypes, reviewResult as ReviewResultInterface);

  const [isLoading, setIsLoading] = useState(false);

  const { signOut } = AuthHandler();

  const navigate = useNavigate();

  const handleLogoutClick = (event: ReactMouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();

    setIsLoading(true);

    signOut()
      .then(() => {
        navigate(AuthPaths.SignIn);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  const handleGetReturnsClick = (event: ReactMouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();

    toggleMenu(false);

    updateViewModal({
      type: ViewModalActionTypes.BitcoinAddressModal,
      payload: { ref: modalRef },
    });
  };

  const handleOutsideClick = (e: MouseEvent) => {
    if (menuRef.current && !menuRef.current.contains(e.target as Node)) {
      if (menuIsOpen) {
        toggleMenu(false);
      }
    }
    if (ref?.current && !ref.current.contains(e.target as Node)) {
      if (isOpen && !kycTier) {
        if (isReload) {
          window.location.reload();
        }
      }
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleOutsideClick);
    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, [menuIsOpen, isOpen]);

  return (
    <div ref={menuRef} className="absolute bg-filled top-16 right-5 lg:right-10 flex flex-col rounded shadow-md shadow-default z-40">
      <NavLink className="py-4 px-6 hover:bg-disabled rounded-t" to={Paths.ProfileEdit}>
        <h5>Edit Profile</h5>
      </NavLink>
      <Button
        className="h-auto border-none rounded-none py-4 px-6 hover:bg-disabled"
        onClick={handleGetReturnsClick}
      >
        <h5 className="text-start">
          Get Returns
        </h5>
      </Button>
      <NavLink className="py-4 px-6 hover:bg-disabled rounded-t" to={Paths.Calculator}>
        <h5>Calculator</h5>
      </NavLink>
      {strigaId && status === KYCStatusTypes.APPROVED && (
      <NavLink className="py-4 px-6 hover:bg-disabled rounded-t" to={Paths.Wallets}>
        <h5>My Wallets</h5>
      </NavLink>
      )}
      <Button
        className="h-auto border-none rounded-none rounded-b py-4 px-6 hover:bg-disabled"
        onClick={handleLogoutClick}
        isLoading={isLoading}
      >
        <h5 className="text-start text-error">
          Logout
        </h5>
      </Button>
    </div>
  );
};
