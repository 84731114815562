import React, { useState } from 'react';
import { Controller } from 'react-hook-form';
import PhoneInput from 'react-phone-number-input';

import { AppElement, AppFC } from '../../../interfaces';
import { AppInputProps } from './types';

import { Input, Label } from '../../Atoms';
import { InputCategory } from '../../../constants';
import { BlockedIcon, CopyIcon } from '../../Atoms/icons';
import { useCopyToClipboard } from '../../../utils';

export const AppInput: AppFC<AppInputProps> = ({
  label,
  control,
  error,
  placeholder,
  type,
  name,
  blocked,
  disabled,
  className,
  category,
  copiable,
}): AppElement => {
  const [copiedOpen, setCopiedOpen] = useState(false);

  const [copy] = useCopyToClipboard();
  const handleCopyClick = async (value: string) => {
    await copy(value as string).then((result) => {
      if (result) {
        setCopiedOpen(true);
        setTimeout(() => setCopiedOpen(false), 2000);
      }
    });
  };

  return (
    <Label className={className || ''}>
      {!!label && <h5 className={`text-[14px] ${error ? 'text-error' : ''}`}>{label}</h5>}
      <Controller
        name={name}
        control={control}
        render={({
          field: {
            name: fieldName,
            value,
            onChange,
            onBlur,
          },
        }) => (
          <div className="relative">
            {category === InputCategory.Phone
              ? (
                <>
                  <BlockedIcon className="absolute z-10 translate-y-[-50%] top-[50%] right-[1rem]" />
                  <PhoneInput
                    className={`phone-input flex gap-2 w-full bg-disabled text-[#99938C] pointer-events-none rounded-lg ${error ? 'border-error focus:border-error' : 'border-default focus:border-lightBrown'}  px-4 py-3 focus:outline-none h-12`}
                    name={name}
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    international
                    defaultCountry="US"
                    countryCallingCodeEditable={false}
                  />
                </>
              )
              : (
                <>
                  { copiable && (
                  <>
                    {copiedOpen && <p className="absolute py-1 px-2 border-default text-lightBlack bg-mediumGray rounded-lg -top-5 right-4 z-10">Copied!</p>}
                    <CopyIcon filled className="absolute z-10 translate-y-[-50%] top-[50%] right-[1rem]" onClick={() => { handleCopyClick(value || '').then(); }} />
                  </>
                  )}
                  <Input
                    name={fieldName}
                    type={type}
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    placeholder={placeholder}
                    error={error}
                    blocked={blocked}
                    disabled={disabled}
                    className={copiable ? 'pr-9' : ''}
                  />
                </>
              )}
            {!!error && <p className="absolute w-full text-[10px] mt-1 text-end text-error">{error.message}</p>}
          </div>
        )}
      />
    </Label>
  );
};
