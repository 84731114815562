import React, { useContext, useState } from 'react';
import { AppElement, AppFC } from '../../../interfaces';
import { CopyBoxProps } from './types';

import { CopyIcon, QrIcon } from '../../Atoms/icons';
import { useCopyToClipboard } from '../../../utils';
import { ViewContext, ViewModalActionTypes } from '../../../stores';

export const CopyBox: AppFC<CopyBoxProps> = ({
  label,
  value,
  width,
  isQrCode,
  ...rest
}): AppElement => {
  const [copiedOpen, setCopiedOpen] = useState(false);

  const [copy] = useCopyToClipboard();

  const { viewModal, updateViewModal } = useContext(ViewContext);
  console.log('viewModal', viewModal);
  const handleCopyClick = async () => {
    await copy(value as string).then((result) => {
      if (result) {
        setCopiedOpen(true);
        setTimeout(() => setCopiedOpen(false), 2000);
      }
    });
  };
  const handleQrClick = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    updateViewModal({
      type: ViewModalActionTypes.QrCodeModal,
      payload: { blockchainAddress: value as string },
    });
  };

  return (
    <div className="relative flex justify-between items-center gap-2 p-4 bg-buttonFilled rounded-lg" {...rest}>
      <div className="flex flex-col gap-1.5 overflow-hidden">
        <h5 className="text-white/60 text-[12px]">{label}</h5>
        <h5 className={`text-[16px] ${width ? `w-[${width}] leading-[160%]` : ''}`}>{value}</h5>
      </div>
      <div className="flex gap-4">
        {isQrCode && <QrIcon onClick={handleQrClick} className="text-white/60" />}
        <CopyIcon onClick={handleCopyClick} className="text-white/60" />
        {copiedOpen && <p className="absolute py-1 px-2 border-default text-lightBlack bg-mediumGray rounded-lg -top-5 right-4">Copied!</p>}
      </div>
    </div>
  );
};
