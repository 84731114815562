import { ViewModalInterface, ViewModalReducerInterface } from './interfaces';
import { viewModalInitialState } from './initialState';
import { ViewModalActionTypes } from './actions';
import { ModalTypes } from '../../../../constants';

export const viewModalReducer: ViewModalReducerInterface = (
  state = { ...viewModalInitialState },
  action,
): ViewModalInterface => {
  switch (action.type) {
    case ViewModalActionTypes.KYCModal:
      return {
        ...state,
        isOpen: true,
        ...action.payload,
        modalType: ModalTypes.KYCModal,
      };

    case ViewModalActionTypes.AccountDetailsModal:
      return {
        ...state,
        isOpen: true,
        ...action.payload,
        modalType: ModalTypes.AccountDetailsModal,
      };

    case ViewModalActionTypes.QuotationDetailsModal:
      return {
        ...state,
        isOpen: true,
        ...action.payload,
        modalType: ModalTypes.QuotationDetailsModal,
      };

    case ViewModalActionTypes.DocusignModal:
      return {
        ...state,
        isOpen: true,
        ...action.payload,
        modalType: ModalTypes.DocusignModal,
      };

    case ViewModalActionTypes.SumsubModal:
      return {
        ...state,
        isOpen: true,
        ...action.payload,
        modalType: ModalTypes.SumsubModal,
      };

    case ViewModalActionTypes.InvestmentModal:
      return {
        ...state,
        isOpen: true,
        ...action.payload,
        modalType: ModalTypes.InvestmentDetailsModal,
      };

    case ViewModalActionTypes.PasswordChangeModal:
      return {
        ...state,
        isOpen: true,
        ...action.payload,
        modalType: ModalTypes.PasswordChangeModal,
      };

    case ViewModalActionTypes.BitcoinAddressModal:
      return {
        ...state,
        isOpen: true,
        ...action.payload,
        modalType: ModalTypes.BitcoinAddressModal,
      };

    case ViewModalActionTypes.BankingAccessInfoModal:
      return {
        ...state,
        isOpen: true,
        ...action.payload,
        modalType: ModalTypes.BankingAccessInfoModal,
      };

    case ViewModalActionTypes.BankingWithPassedKYCModal:
      return {
        ...state,
        isOpen: true,
        ...action.payload,
        modalType: ModalTypes.BankingWithPassedKYCModal,
      };

    case ViewModalActionTypes.BankingAccessFormModal:
      return {
        ...state,
        isOpen: true,
        ...action.payload,
        modalType: ModalTypes.BankingAccessFormModal,
      };

    case ViewModalActionTypes.WithdrawModal:
      return {
        ...state,
        isOpen: true,
        ...action.payload,
        modalType: ModalTypes.WithdrawModal,
      };

    case ViewModalActionTypes.ExchangeModal:
      return {
        ...state,
        isOpen: true,
        ...action.payload,
        modalType: ModalTypes.ExchangeModal,
      };

    case ViewModalActionTypes.QrCodeModal:
      return {
        ...state,
        isOpen: true,
        ...action.payload,
        modalType: ModalTypes.QrCodeModal,
      };

    case ViewModalActionTypes.TxConfirmModal:
      return {
        ...state,
        isOpen: true,
        ...action.payload,
        modalType: ModalTypes.TxConfirmModal,
      };

    case ViewModalActionTypes.SuccessModal:
      return {
        ...state,
        isOpen: true,
        ...action.payload,
        modalType: ModalTypes.SuccessModal,
      };

    case ViewModalActionTypes.FailModal:
      return {
        ...state,
        isOpen: true,
        ...action.payload,
        modalType: ModalTypes.FailModal,
      };

    case ViewModalActionTypes.DefaultModal:
      return {
        ...state,
        isOpen: true,
        ...action.payload,
        modalType: ModalTypes.DefaultModal,
      };

    case ViewModalActionTypes.Close:
      return {
        ...viewModalInitialState,
      };

    default:
      return state;
  }
};
