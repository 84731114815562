import React, { useContext } from 'react';

import { ViewContext } from '../../../stores';

import { AppElement, AppFC } from '../../../interfaces';
import { ModalTypes } from '../../../constants';

import { Modal } from '../../Molecules';
import {
  KYCForm,
  AccountDetails,
  Docusign,
  Sumsub,
  InvestmentDetails,
  TxConfirmOTP,
  SuccessInfo,
  FailInfo,
  PasswordChange,
  BitcoinWalletAddress,
  BankingAccessInfo,
  BankingAccessForm,
  WithdrawFunds,
  Exchange,
  QrCode, BankingWithPassedKYC,
} from './contents';
import { QuotationDetails } from './contents/QuotationDetails';

export const AppModal:AppFC = (): AppElement => {
  const { viewModal } = useContext(ViewContext);
  const getModal = () => {
    switch (viewModal.modalType) {
      case ModalTypes.KYCModal:
        return (
          <Modal className="h-fit my-8 pt-12 pb-16 px-8 max-md:px-6 max-md:my-auto bg-default">
            <KYCForm />
          </Modal>
        );
      case ModalTypes.AccountDetailsModal:
        return (
          <Modal className="h-fit my-5 pt-16 pb-14 px-8 max-md:px-6 bg-default">
            <AccountDetails />
          </Modal>
        );
      case ModalTypes.QuotationDetailsModal:
        return (
          <Modal className="h-fit p-8 my-5 max-md:px-6 bg-default">
            <QuotationDetails />
          </Modal>
        );

      case ModalTypes.DocusignModal:
        return (
          <Modal className="h-[90vh] w-full max-w-[968px] self-center bg-default">
            <Docusign />
          </Modal>
        );

      case ModalTypes.SumsubModal:
        return (
          <Modal className="h-[90vh] w-full max-w-[968px] self-center bg-white">
            <Sumsub />
          </Modal>
        );

      case ModalTypes.InvestmentDetailsModal:
        return (
          <Modal className="h-fit my-auto p-14 max-w-[850px] max-md:p-10 max-sm:p-6 bg-default">
            <InvestmentDetails />
          </Modal>
        );

      case ModalTypes.PasswordChangeModal:
        return (
          <Modal className="h-fit self-center justify-self-center py-12 w-full max-w-[500px] px-8 max-md:px-6 mx-5 bg-default">
            <PasswordChange />
          </Modal>
        );

      case ModalTypes.BitcoinAddressModal:
        return (
          <Modal className="h-fit self-center justify-self-center py-12 max-w-[500px] px-8 max-md:px-6 bg-default">
            <BitcoinWalletAddress />
          </Modal>
        );

      case ModalTypes.BankingAccessInfoModal:
        return (
          <Modal className="h-fit self-center justify-self-center py-12 px-8 max-md:px-6 bg-default">
            <BankingAccessInfo />
          </Modal>
        );

      case ModalTypes.BankingWithPassedKYCModal:
        return (
          <Modal className="h-fit self-center justify-self-center py-12 px-8 max-md:px-6 bg-default">
            <BankingWithPassedKYC />
          </Modal>
        );

      case ModalTypes.BankingAccessFormModal:
        return (
          <Modal className="h-fit my-auto pt-12 pb-16 px-8 max-md:px-6 max-md:h-full bg-default">
            <BankingAccessForm />
          </Modal>
        );

      case ModalTypes.WithdrawModal:
        return (
          <Modal className="h-fit my-auto py-14 px-20 max-md:px-6 max-md:h-full bg-default">
            <WithdrawFunds />
          </Modal>
        );

      case ModalTypes.ExchangeModal:
        return (
          <Modal className="h-fit my-auto py-14 px-20 max-md:px-6 max-md:h-full bg-default">
            <Exchange />
          </Modal>
        );

      case ModalTypes.QrCodeModal:
        return (
          <Modal className="h-fit my-auto py-14 px-20 max-md:px-6 max-md:h-full bg-default">
            <QrCode />
          </Modal>
        );

      case ModalTypes.TxConfirmModal:
        return (
          <Modal className="h-fit my-8 self-center max-md:my-0 max-md:h-full py-24 px-8 max-md:px-6 bg-default">
            <TxConfirmOTP />
          </Modal>
        );

      case ModalTypes.SuccessModal:
        return (
          <Modal className="h-fit self-center max-md:h-full py-14 px-20 max-md:px-8 bg-default">
            <SuccessInfo />
          </Modal>
        );

      case ModalTypes.FailModal:
        return (
          <Modal className="h-fit self-center max-md:h-full py-14 px-20 max-md:px-8 bg-default">
            <FailInfo />
          </Modal>
        );

      case ModalTypes.DefaultModal:
        return (
          <Modal className="h-fit self-center bg-default" />
        );

      default:
        return <Modal />;
    }
  };
  if (!viewModal?.isOpen) {
    return null;
  }

  return (
    <div className="flex justify-center overflow-x-hidden fixed bg-black/75 top-0 w-full h-full z-50">
      {getModal()}
    </div>
  );
};
