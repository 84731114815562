import React, { useContext } from 'react';

import { ViewContext, ViewModalActionTypes } from '../../../../../stores';

import { AppElement, AppFC } from '../../../../../interfaces';
import { ButtonCategory } from '../../../../../constants';

import { Button } from '../../../../Atoms';

export const BankingWithPassedKYC: AppFC = (): AppElement => {
  const {
    viewModal: {
      title,
      description,
      info,
    },
    updateViewModal,
  } = useContext(ViewContext);

  const handlePayByStrigaClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    updateViewModal({
      type: ViewModalActionTypes.InvestmentModal,
      payload: {},
    });
  };

  const handleKYCSkipClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    updateViewModal({
      type: ViewModalActionTypes.BankingAccessFormModal,
      payload: {
        info: {
          ...info,
          noKYC: true,
        },
      },
    });
  };

  return (
    <div className="flex flex-col items-center">
      {title && <h4>{title}</h4>}
      {description && <p className="mt-2 text-center max-w-lg">{description}</p>}
      <div className="w-full flex justify-center items-center gap-2 mt-7 max-sm:flex-col [&>button]:w-[200px] [&>button]:p-0 max-sm:[&>button]:w-56">
        <Button category={ButtonCategory.Outlined} onClick={handleKYCSkipClick}>Pay by Bank Wire</Button>
        <Button type="submit" category={ButtonCategory.Filled} onClick={handlePayByStrigaClick}>Pay with Striga</Button>
      </div>
    </div>
  );
};
