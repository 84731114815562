import { HTMLAttributes } from 'react';
import { AppElement, AppFC } from '../../../interfaces';

export const QrIcon: AppFC<HTMLAttributes<HTMLDivElement>> = ({ className, ...rest }): AppElement => (
  <div className={`cursor-pointer ${className || ''}`} {...rest}>
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M2.4375 3.47119C2.4375 2.90194 2.8995 2.43994 3.46875 2.43994H7.59375C8.163 2.43994 8.625 2.90194 8.625 3.47119V7.59619C8.625 8.16544 8.163 8.62744 7.59375 8.62744H3.46875C3.19525 8.62744 2.93294 8.51879 2.73955 8.3254C2.54615 8.132 2.4375 7.8697 2.4375 7.59619V3.47119ZM2.4375 12.4087C2.4375 11.8394 2.8995 11.3774 3.46875 11.3774H7.59375C8.163 11.3774 8.625 11.8394 8.625 12.4087V16.5337C8.625 17.1029 8.163 17.5649 7.59375 17.5649H3.46875C3.19525 17.5649 2.93294 17.4563 2.73955 17.2629C2.54615 17.0695 2.4375 16.8072 2.4375 16.5337V12.4087ZM11.375 3.47119C11.375 2.90194 11.837 2.43994 12.4063 2.43994H16.5313C17.1005 2.43994 17.5625 2.90194 17.5625 3.47119V7.59619C17.5625 8.16544 17.1005 8.62744 16.5313 8.62744H12.4063C12.1327 8.62744 11.8704 8.51879 11.677 8.3254C11.4836 8.132 11.375 7.8697 11.375 7.59619V3.47119Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M5.1875 5.18994H5.875V5.87744H5.1875V5.18994ZM5.1875 14.1274H5.875V14.8149H5.1875V14.1274ZM14.125 5.18994H14.8125V5.87744H14.125V5.18994ZM11.375 11.3774H12.0625V12.0649H11.375V11.3774ZM11.375 16.8774H12.0625V17.5649H11.375V16.8774ZM16.875 11.3774H17.5625V12.0649H16.875V11.3774ZM16.875 16.8774H17.5625V17.5649H16.875V16.8774ZM14.125 14.1274H14.8125V14.8149H14.125V14.1274Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  </div>
);
