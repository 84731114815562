import React, { useContext } from 'react';

import { ViewContext, ViewModalActionTypes } from '../../../../../stores';

import { AppElement, AppFC } from '../../../../../interfaces';

import { AlertErrorIcon } from '../../../../Atoms/icons';
import { Button } from '../../../../Atoms';
import { ButtonCategory } from '../../../../../constants';

export const FailInfo: AppFC = (): AppElement => {
  const { viewModal: { title, description }, updateViewModal } = useContext(ViewContext);

  return (
    <div className="flex flex-col items-center justify-center">
      <AlertErrorIcon />
      {title && <h4 className="mt-6 text-center">{title}</h4>}
      {description && <p className="mt-4 text-center">{description}</p>}
      <Button
        className="px-1 mt-8 w-60"
        category={ButtonCategory.Outlined}
        onClick={() => updateViewModal({ type: ViewModalActionTypes.Close })}
      >
        <h5 className="text-[16px]">Close</h5>
      </Button>
    </div>
  );
};
