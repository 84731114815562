import React, { useContext } from 'react';

import { ViewContext, ViewModalActionTypes } from '../../../../../stores';

import { AppElement, AppFC } from '../../../../../interfaces';
import { ButtonCategory } from '../../../../../constants';

import { AlertSuccessIcon } from '../../../../Atoms/icons';
import { Button } from '../../../../Atoms';

export const SuccessInfo: AppFC = (): AppElement => {
  const { viewModal: { title, description }, updateViewModal } = useContext(ViewContext);

  return (
    <div className="flex flex-col items-center justify-center">
      <AlertSuccessIcon />
      <h4 className="mt-6 text-center">{title}</h4>
      <p className="mt-4 text-center">{description}</p>
      <Button
        className="px-1 mt-8 w-[200px]"
        category={ButtonCategory.Outlined}
        onClick={() => {
          window.location.reload();
          updateViewModal({ type: ViewModalActionTypes.Close });
        }}
      >
        <h5 className="text-[16px]">Close</h5>
      </Button>
    </div>
  );
};
