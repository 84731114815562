import { Controller } from 'react-hook-form';

import { AppElement, AppFC } from '../../../interfaces';
import { AppSelectProps } from './types';

import { Label, Select } from '../../Atoms';

export const AppSelect: AppFC<AppSelectProps> = ({
  label,
  control,
  name,
  className,
  error,
  ...rest
}): AppElement => (
  <Label className={className}>
    {!!label && <h5 className={`text-[14px] ${error ? 'text-error' : ''}`}>{label}</h5>}
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <div className="relative">
          <Select
            error={error}
            {...field}
            {...rest}
          />
          {!!error && <p className="absolute w-full text-[10px] mt-1 text-end text-error">{error.message}</p>}
        </div>
      )}
    />
  </Label>
);
