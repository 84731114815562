import { useContext, useState } from 'react';
import { AppElement, AppFC } from '../../../interfaces';
import { InvoiceCardProps } from './types';

import { ProjectsContext } from '../../../stores';
import { DownloadIcon } from '../../Atoms/icons';
import { InvoiceHandler } from '../../../stores/handlers';
import { Loader } from '../../Atoms';
import { dateFormat, reorderDateString } from '../../../utils';

export const InvoiceCard: AppFC<InvoiceCardProps> = ({
  id,
  invoiceIssueDate,
  invoiceNumber,
  location,
  numberOfMiners,
  pricePerMiner,
  projectId,
  totalAmount,
  currency,
  type,
  status,
  className,
  ...rest
}): AppElement => {
  const { projectsState: { projects } } = useContext(ProjectsContext);
  const { downloadInvoice } = InvoiceHandler();
  const [isLoading, setIsLoading] = useState(false);

  const invoiceProject = projects.find((project) => project.id === projectId);
  const formattedDate = reorderDateString(invoiceIssueDate);
  const date = dateFormat(formattedDate, 'MMM D, YYYY');
  const handleDownloadClick = () => {
    setIsLoading(true);
    downloadInvoice(id).then(() => { setIsLoading(false); }).catch(() => { setIsLoading(false); });
  };

  return (
    <div className={`relative max-w-sm flex justify-between items-start p-5 h-full rounded-xl ${className || ''}`} {...rest}>
      <div className="flex flex-col items-start gap-5 w-[50%]">
        <div className="flex flex-col gap-2">
          <h5 className="text-[12px] text-[#A0A0A0]">Invoice number</h5>
          <h5 className="text-[14px]">{invoiceNumber}</h5>
        </div>
        <div className="flex flex-col gap-2">
          <h5 className="text-[12px] text-[#A0A0A0]">Status</h5>
          <h5 className="text-[14px]">
            {status === 'in_progress' ? 'pending' : status}
          </h5>
        </div>
        <div className="flex flex-col gap-2">
          <h5 className="text-[12px] text-[#A0A0A0]">Date</h5>
          <h5 className="text-[14px]">{date}</h5>
        </div>
      </div>
      <div className="relative h-full flex flex-col gap-5 justify-start">
        {invoiceProject && (
        <div className="flex flex-col gap-2">
          <h5 className="text-[12px] text-[#A0A0A0]">Project</h5>
          <h5 className="text-[14px]">{invoiceProject?.title}</h5>
        </div>
        )}
        <div className="flex flex-col gap-2">
          <h5 className="text-[12px] text-[#A0A0A0]">Invoice Type</h5>
          <h5 className="text-[14px]">
            Miner
            {' '}
            {type.charAt(0).toUpperCase() + type.slice(1)}
          </h5>
        </div>
        <div className="flex flex-col gap-2">
          <h5 className="text-[12px] text-[#A0A0A0]">Location</h5>
          <h5 className="text-[14px]">{location}</h5>
        </div>
        <button onClick={handleDownloadClick} disabled={isLoading} className="h-10 w-[150px] flex items-center justify-center gap-1 bg-default rounded-full px-4 text-[13px] text-white cursor-pointer">
          {!isLoading ? <DownloadIcon /> : <Loader className="w-6 h-6" />}
          <h5 className="text-[14px] mt-0.5">Download</h5>
        </button>
      </div>
    </div>
  );
};
