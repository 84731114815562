import { Controller, FieldError } from 'react-hook-form';

import React from 'react';
import { AppElement, AppFC } from '../../../interfaces';
import { DateSelectProps } from './types';

import { Label, ErrorMessage, Select } from '../../Atoms';
import { getDayOptions, getMonthOptions, getYearOptions } from '../../../utils';

export const DateSelect: AppFC<DateSelectProps> = ({
  label,
  control,
  errors,
  year,
  month,
  disabled,
  blocked,
  className,
}): AppElement => {
  const dateError = (errors?.year || errors?.month || errors?.day) as FieldError;

  return (
    <Label className={className}>
      {!!label && <h5 className={`text-[14px] ${dateError ? 'text-error' : ''}`}>{label}</h5>}
      <div className="w-full flex gap-3.5">
        <Controller
          name="year"
          control={control}
          render={({
            field: {
              name,
              value,
              onChange,
              onBlur,
            },
          }) => (
            <Select
              className="flex-[4]"
              name={name}
              value={value}
              onChange={onChange}
              onBlur={onBlur}
              error={errors?.year as FieldError}
              options={getYearOptions()}
              placeholder="Year"
              disabled={disabled}
              blocked={blocked}
            />
          )}
        />
        <Controller
          name="month"
          control={control}
          render={({
            field: {
              name,
              value,
              onChange,
              onBlur,
            },
          }) => (
            <Select
              className="flex-[6]"
              name={name}
              value={value}
              onChange={onChange}
              onBlur={onBlur}
              error={errors?.month as FieldError}
              options={getMonthOptions()}
              placeholder="Month"
              disabled={disabled}
              blocked={blocked}
            />
          )}
        />
        <Controller
          name="day"
          control={control}
          render={({
            field: {
              name,
              value,
              onChange,
              onBlur,
            },
          }) => (
            <Select
              className="flex-[3]"
              name={name}
              value={value}
              onChange={onChange}
              onBlur={onBlur}
              error={errors?.day as FieldError}
              options={getDayOptions(year, month)}
              placeholder="Day"
              disabled={disabled}
              blocked={blocked}
            />
          )}
        />
      </div>
      {dateError && <ErrorMessage className="top-20">{dateError.message}</ErrorMessage>}
    </Label>
  );
};
