export enum AuthPaths {
  SignIn = '/signin',
  SignUp = '/signup',
  VerifyEmailConfirm = '/authentication/register/verify-email/:key',
  VerifyEmail = '/authentication/register/verify-email',
  ForgotPassword = '/forgot-password',
  ResetPassword = '/reset-password/:uid/:token',
  MobileVerify = '/phone-verify',
}

export enum Paths {
  Wallets = '/wallets',
  Projects = '/projects',
  Project = '/projects/:id',
  Invoices = '/invoices',
  InvoicesHistory = '/invoices-history',
  ProfileEdit = '/profile/edit',
  SumsubKYC = '/kyc',
  Docusign = '/docusign/',
  Calculator = '/calculator',
}
