import React, { useContext, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import { ProfileContext, ViewContext, ViewModalActionTypes } from '../../../../../stores';

import { httpRequest } from '../../../../../services';
import { HttpRequestHeaderTypes } from '../../../../../services/httpRequest';

import { AppElement, AppFC } from '../../../../../interfaces';
import { BankingAccessFormDataProps } from './types';
import { ButtonCategory, getProjectAPI, RequestTypes } from '../../../../../constants';
import { generateErrorAlert } from '../../../../../utils';

import { Button } from '../../../../Atoms';
import { AppInput } from '../../../../Molecules';

const validationSchema = yup.object()
  .shape({
    telegram: yup.string().required(),
    email: yup.string().email('Email is invalid').required(),
    phone: yup.string().required(),
  });
export const BankingAccessForm: AppFC = ({ className }): AppElement => {
  const [isLoading, setIsLoading] = useState(false);
  const [alert, setAlert] = useState<AppElement>();
  const { profileState } = useContext(ProfileContext);
  const { viewModal: { info }, updateViewModal } = useContext(ViewContext);

  const {
    email = '',
    phone = '',
    telegram,
  } = profileState;

  console.log('info', info, profileState);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      telegram: telegram || '@',
      email,
      phone,
    },
  });

  const {
    PROJECT_SEND_EMAIL,
  } = getProjectAPI(info?.projectId);

  const onSubmit: SubmitHandler<BankingAccessFormDataProps> = (data) => {
    setIsLoading(true);

    httpRequest({
      data: {
        telegram: data.telegram,
        spots: info?.spotsAmount as number,
      },
      url: PROJECT_SEND_EMAIL,
      method: RequestTypes.Post,
      headerType: HttpRequestHeaderTypes.MultiPart,
    })
      .then(() => {
        setIsLoading(false);
        updateViewModal({
          type: ViewModalActionTypes.AccountDetailsModal,
          payload: {
            title: 'Your Request Received',
            description: 'Please use the following wire instructions below to make a purchase on Pantheon Portal after our team approval. \n'
                + 'Allow 1-3 business days for the deposit to be processed.',
            info: info || {},
            importantMessage: '',
            isPrintable: false,
          },
        });
      })
      .catch((reason) => {
        setIsLoading(false);
        generateErrorAlert(reason, 'Banking Access request Failed!', setAlert, 'top-[50%] z-50');
      });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={`flex flex-col items-center my-auto ${className}`}>
      {alert}
      <h4>You&apos;re one step closer</h4>
      <p className="mt-2 text-center max-w-lg">Please complete the form to receive wire transfer instructions. Our dedicated team will reach out to assist you further and confirm your transaction details.</p>
      <div className="flex flex-col gap-8 w-full max-w-[400px] mt-8">
        <AppInput
          label="Telegram User"
          name="telegram"
          control={control}
          placeholder="Enter telegram user id"
          error={errors.telegram}
        />
        <AppInput
          label="Email Address"
          name="email"
          control={control}
          placeholder="Enter email address"
          blocked
          error={errors.email}

        />
        <AppInput
          label="Phone Number"
          name="phone"
          control={control}
          placeholder="Enter phone number"
          blocked
          error={errors.phone}
        />
      </div>
      <div className="w-full flex justify-between gap-2 mt-6 max-w-[400px]">
        <Button className="w-[calc(50%-28px)] mt-7 flex-1" category={ButtonCategory.Outlined} onClick={() => updateViewModal({ type: ViewModalActionTypes.Close })}>Cancel</Button>
        <Button type="submit" className="w-[calc(50%-28px)] mt-7 flex-1" category={ButtonCategory.Filled} isLoading={isLoading}>Submit</Button>
      </div>
    </form>
  );
};
