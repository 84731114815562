import React, { useState, useEffect, useContext } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { ProfileContext } from '../../../../stores';
import { AuthHandler } from '../../../../stores/handlers';

import { AppElement, AppFC } from '../../../../interfaces';
import { AlertCategory, AuthPaths } from '../../../../constants';
import { generateErrorAlert } from '../../../../utils';

import { Alert, Button } from '../../../Atoms';

export const EmailVerification: AppFC = (): AppElement => {
  const [isLoading, setIsLoading] = useState(false);
  const [alert, setAlert] = useState<AppElement>();

  const { profileState: { email: userEmail } } = useContext(ProfileContext);
  const { key = '' } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const { alertMessage, emailAddress } = location.state || {};
  const email = userEmail || emailAddress;

  const { emailVerification, resendEmail } = AuthHandler();

  useEffect(() => {
    if (alertMessage) {
      setAlert(
        <Alert
          className="top-5 z-50"
          category={AlertCategory.Success}
          title={alertMessage}
        />,
      );
      setTimeout(() => {
        setAlert(null);
      }, 7000);
    } else {
      setAlert(null);
    }
  }, []);

  useEffect(() => {
    if (key) {
      emailVerification(key).then(() => {
        navigate(`${AuthPaths.SignIn}/${location.search}`, { state: { alertMessage: 'Your Email Successfully Verified!' } });
      }).catch((reason) => {
        generateErrorAlert(reason, 'Email verification failed!', setAlert, 'top-5 z-50');
      });
    }
  }, [key]);

  const handleResend = () => {
    setIsLoading(true);
    if (email) {
      resendEmail(email)
        .then(() => {
          setIsLoading(false);
          setAlert(
            <Alert
              className="top-5 z-50"
              category={AlertCategory.Success}
              title="New verification email sent!"
            />,
          );
          setTimeout(() => {
            setAlert(null);
          }, 7000);
        })
        .catch((reason) => {
          generateErrorAlert(reason, 'Email resend failed!', setAlert, 'top-5 z-50');
          setIsLoading(false);
        });
    } else {
      setIsLoading(false);
    }
  };

  if (!key) {
    return (
      <div className="relative h-full flex flex-col items-center justify-center gap-2 pb-10">
        {alert}
        <h4 className="text-center">Verification</h4>
        <p className="font-medium text-center w-[110%]">
          Thanks for registering an account with Pantheon. We’ve just sent an email to
          {' '}
          <span className="font-bold">{email}</span>
          {' '}
          to verify your email.
        </p>
        <div className="absolute translate-y-[-50%] bottom-[20%] flex items-center gap-1 font-semibold text-[13px]">
          <span>
            Didn&apos;t get an email?
          </span>
          <Button className="px-1" isLoading={isLoading} onClick={handleResend}>
            <h5 className="text-[13px] text-blue underline">Resend</h5>
          </Button>
        </div>
      </div>
    );
  }

  return (
    <>
      {alert}
    </>
  );
};
