import { ProfileActionTypes } from './actions';
import { profileInitialState } from './initialState';
import { UseReducerType } from '../../interfaces';
import { ProfileActionType, ProfileInterface, WalletInterface } from './interfaces';

const updateProfile = (data: ProfileInterface, oldData: ProfileInterface) => ({
  ...oldData,
  ...data,
});

export const profileReducer: UseReducerType<ProfileInterface, ProfileActionType> = (
  state,
  action,
): ProfileInterface => {
  switch (action.type) {
    case ProfileActionTypes.UpdateProfile:
      return { ...state, ...updateProfile(action.payload, state) };

    case ProfileActionTypes.UpdateProfileWallet:
      return { ...state, wallet: { ...state.wallet, ...action.payload } };

    case ProfileActionTypes.UpdateProfileWalletAccountsDetails:
      return {
        ...state,
        wallet: {
          ...state.wallet,
          accountDetails: {
            ...state.wallet?.accountDetails,
            [action.payload.currency]: action.payload,
          },
        } as WalletInterface,
      };

    case ProfileActionTypes.ClearProfile:
      return profileInitialState;

    default:
      return state;
  }
};
