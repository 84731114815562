import React, { useContext, useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import {
  ProfileContext, ProjectsContext, ViewContext, ViewModalActionTypes,
} from '../../../../../stores';

import { AppElement, AppFC } from '../../../../../interfaces';
import { ExchangeDataProps } from './types';
import {
  ButtonCategory, currencyKeys, CurrencyTypes, minTradeValues,
} from '../../../../../constants';

import { Button, Loader } from '../../../../Atoms';
import { AppInput, AppSelect } from '../../../../Molecules';
import { ProjectHandler } from '../../../../../stores/handlers';
import {
  getCurrencyOptions, getFormattedAmount,
  // numberWithCommas
} from '../../../../../utils';

const validationSchema = (minValue: number, maxValue: number) => yup.object()
  .shape({
    amount: yup
      .number()
      .typeError('Amount must be a number')
      .required('')
      .min(minValue)
      .max(maxValue)
      .required(' '),
    exchangeTo: yup
      .string()
      .oneOf(Object.values(CurrencyTypes), 'Invalid currency.')
      .required('Please enter currency.'),
  });

export const Exchange: AppFC = ({ className }): AppElement => {
  const [isLoading,
    // setIsLoading
  ] = useState(false);
  const { viewModal: { info }, updateViewModal } = useContext(ViewContext);
  const { profileState: { strigaId, wallet } } = useContext(ProfileContext);
  const {
    projectsState: {
    // rates,
      swapFee,
    },
  } = useContext(ProjectsContext);
  const [seconds, setSeconds] = useState(10);

  const { accounts } = wallet || {};
  const {
    // exchangeCurrency,
    getRates,
  } = ProjectHandler();

  const { selectedAccount } = info || {};

  const currencyOptions = getCurrencyOptions(selectedAccount?.currency as CurrencyTypes);

  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm({
    resolver: yupResolver(validationSchema(minTradeValues[selectedAccount!.currency], getFormattedAmount(selectedAccount?.availableBalance.amount, selectedAccount?.availableBalance.currency))),
    defaultValues: {
      exchangeTo: CurrencyTypes.EUR,
    },
  });

  const exchangeTo = watch('exchangeTo');
  const amount = watch('amount');

  useEffect(() => {
    if (strigaId && selectedAccount && exchangeTo) {
      const timer = setInterval(() => {
        setSeconds((prevSeconds) => {
          if (prevSeconds === 0) {
            getRates().then();
            return 10;
          }
          return prevSeconds - 1;
        });
      }, 1000);

      if (selectedAccount.currency === CurrencyTypes.EUR) {
        clearInterval(timer);
      } else {
        getRates().then(() => {
        });
        setSeconds(10);
      }

      return () => {
        clearInterval(timer);
      };
    }
    return () => {};
  }, [exchangeTo]);

  useEffect(() => {
    getRates().then(() => {
    });
  }, []);

  const onSubmit: SubmitHandler<ExchangeDataProps> = (data) => {
    console.log('exchange data', data);
    // setIsLoading(true);

    const destinationAccount = accounts?.[currencyKeys[data.exchangeTo as CurrencyTypes]];

    if (selectedAccount && destinationAccount) {
      // exchangeCurrency({
      //   amount: getFormattedAmount(data.amount, selectedAccount.availableBalance.currency, -1).toString(),
      //   sourceAccountId: selectedAccount.accountId,
      //   destinationAccountId: destinationAccount.accountId,
      // }).then((res) => {
      //   console.log('res', res);
      //   updateViewModal({
      //     type: ViewModalActionTypes.SuccessModal,
      //     payload: {
      //       title: 'Exchange Success',
      //       description: 'Check your Wallet Balance on Pantheon Mining Investor Portal.',
      //     },
      //   });
      // }).catch(() => {
      //   updateViewModal({
      //     type: ViewModalActionTypes.FailModal,
      //     payload: {
      //       title: 'Funds Withdrawal Failed',
      //     },
      //   });
      // });
    }
  };
  console.log('amounts', amount, getFormattedAmount(selectedAccount?.availableBalance.amount, selectedAccount?.availableBalance.currency));
  return (
    <form onSubmit={handleSubmit(onSubmit)} className={`flex flex-col items-center my-auto gap-12 ${className}`}>
      {/* {alert} */}
      <div className="flex flex-col items-center gap-2">
        <h4>
          {`Exchange ${selectedAccount?.currency}`}
        </h4>
        <p className="text-center max-w-md">
          {`Please specify the amount of ${selectedAccount?.currency} you would like to exchange`}
        </p>
      </div>
      <div className="flex flex-col gap-8 w-full max-w-[500px]">
        <AppInput
          label="Amount"
          name="amount"
          control={control}
          placeholder="0.00"
          error={errors.amount}
        />
        {selectedAccount && <span className={`${errors.amount ? '-mt-4' : '-mt-6'} font-medium text-[12px]`}>{`Min exchange amount is ${minTradeValues[selectedAccount.currency]} ${selectedAccount.currency}`}</span>}
        <AppSelect
          label="Exchange to"
          name="exchangeTo"
          control={control}
          options={currencyOptions}
          placeholder="Select currency"
          error={errors.exchangeTo}
        />
        <div className="flex flex-col gap-1">
          { selectedAccount && exchangeTo && (
            <div className="flex items-center gap-1">
              <span>
                1
                {' '}
                {selectedAccount?.currency}
                {' '}
                =
                {' '}
                {/* {`${numberWithCommas(rates?.[`${currencyKeys[selectedAccount.currency]}${exchangeTo}`]?.sell)} ${exchangeTo}`} */}
              </span>
              <Loader className="w-5 h-5" />
              <span>{seconds}</span>
            </div>
          )}
          {amount && swapFee && <span>{`Transaction fee is ${swapFee}%. Approx.- ${(Number(amount) * Number(swapFee)) / 100} ${selectedAccount?.currency}`}</span>}
        </div>
      </div>
      <div className="w-full flex justify-center gap-2 max-[450px]:flex-col">
        <Button className="w-[200px] max-[450px]:w-full" category={ButtonCategory.Outlined} onClick={() => updateViewModal({ type: ViewModalActionTypes.Close })}>Cancel</Button>
        <Button
          type="submit"
          className="w-[200px] max-[450px]:w-full"
          category={ButtonCategory.Filled}
          isLoading={isLoading}
          disabled={
          (Number(amount) > getFormattedAmount(selectedAccount?.availableBalance.amount, selectedAccount?.availableBalance.currency))
                || !Number(selectedAccount?.availableBalance.amount)
                || Number(amount) < minTradeValues[selectedAccount!.currency]
        }
        >
          Confirm
        </Button>
      </div>
    </form>
  );
};
