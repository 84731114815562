import React, { useContext } from 'react';

import { AppElement, AppFC } from '../../../../../interfaces';
import { ViewContext } from '../../../../../stores';

export const Sumsub: AppFC = (): AppElement => {
  const { viewModal } = useContext(ViewContext);
  const { info, kycTier } = viewModal;

  return (
    <div className="flex flex-col items-center w-full h-full">
      <div className="flex flex-col gap-8 w-full h-full">
        {kycTier === 3 && (
        <div className="flex flex-col items-center gap-4 pt-8">
          <h4>KYC final stage</h4>
          <p className="text-center max-w-[700px] px-5">
            You are now at the final step of the KYC process. We require setting up a video call with you, which, upon successful completion, will grant you access to purchase miners through the Pantheon Mining Portal. Please select a time slot that is most convenient for you.
          </p>
        </div>
        )}
        <iframe
          className="h-full w-full rounded-lg"
          title="Sumsub"
          src={info?.url}
          sandbox="allow-same-origin allow-scripts allow-popups allow-forms"
        />
      </div>
    </div>
  );
};
