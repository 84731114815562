import React, { useContext, useEffect, useState } from 'react';
import {
  Link, useNavigate, useSearchParams,
} from 'react-router-dom';
import { QRCodeSVG } from 'qrcode.react';
import OtpInput from 'react-otp-input';

import { AuthHandler } from '../../../../stores/handlers';
import { AppElement, AppFC } from '../../../../interfaces';
import { TwoFAProps } from './types';
import { ButtonCategory, Paths } from '../../../../constants';

import {
  Button,
  Label,
  Loader,
} from '../../../Atoms';
import { CopyIcon } from '../../../Atoms/icons';

import { useCopyToClipboard, generateErrorAlert } from '../../../../utils';
import { ViewContext } from '../../../../stores';

export const TwoFA: AppFC<TwoFAProps> = ({ userId, qrCode, manualCode }): AppElement => {
  const [verificationCode, setVerificationCode] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [alert, setAlert] = useState<AppElement>();
  const [copyText, setCopyText] = useState('Copy Setup Key');

  const navigate = useNavigate();
  const [params] = useSearchParams();
  const projectId = params.get('projectId');
  const miners = params.get('miners');

  const searchPath = projectId ? `${projectId}${miners ? `?miners=${miners}` : ''}` : '';

  const { twoFaAuthorize } = AuthHandler();
  const { viewDevice: { isIOS, android } } = useContext(ViewContext);

  const getAuthy = () => {
    switch (true) {
      case isIOS:
        return 'https://apps.apple.com/us/app/twilio-authy/id494168017';
      case android:
        return 'https://play.google.com/store/apps/details?id=com.authy.authy';
      default:
        return 'https://authy.com/download/';
    }
  };

  const getGoogleAuthenticator = () => {
    switch (true) {
      case isIOS:
        return 'https://apps.apple.com/us/app/google-authenticator/id388497605';
      case android:
        return 'https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2';
      default:
        return 'https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2';
    }
  };

  const [copy] = useCopyToClipboard();

  const handleCopyClick = async () => {
    await copy(manualCode as string).then((result) => {
      if (result) {
        setCopyText('Copied');
        setTimeout(() => setCopyText('Copy Setup Key'), 2000);
      }
    });
  };

  useEffect(() => {
    if (verificationCode.length === 6) {
      setIsLoading(false);
      twoFaAuthorize({ verificationCode, userId })
        .then(() => {
          navigate(`${Paths.Projects}/${searchPath}`);
        })
        .catch((reason) => {
          generateErrorAlert(reason, '2FA failed!', setAlert, 'top-[20%] z-50');
          setIsLoading(false);
          setVerificationCode('');
        });
    }
  }, [verificationCode]);

  const handleSkipClick = () => {
    setIsLoading(true);
    twoFaAuthorize({ isTwoFaSkipped: true, userId })
      .then(() => {
        navigate(`${Paths.Projects}/${searchPath}`);
      })
      .catch((reason) => {
        generateErrorAlert(reason, '2FA failed!', setAlert, 'top-[20%] z-50');
        setIsLoading(false);
      });
  };

  return (
    <>
      {isLoading
        ? <Loader className="w-7 h-7" />
        : (
          <div className={`relative self-center ${qrCode ? 'pt-28 pb-24' : 'justify-center h-full'} sm:w-[130%] flex flex-col max-md:pb-8`}>
            {alert}
            {
          qrCode
            ? (
              <>
                <h4>Enable 2FA</h4>
                <ol className="flex flex-col gap-1 list-decimal pl-4 mb-10 mt-4">
                  <li>Open the authenticator app.</li>
                  <li className="list-disc text-blue ml-1 text-[10px]">
                    <Link className="underline -ml-1 text-[15px]" to={getGoogleAuthenticator()}>Install Google Authenticator</Link>
                  </li>
                  <li className="list-disc text-blue ml-1 mt-1 text-[10px]">
                    <Link className="underline -ml-1 text-[15px]" to={getAuthy()}>Install Authy</Link>
                  </li>
                  <li>Scan the QR Code</li>
                </ol>
                <div className="flex flex-col gap-4 w-[180px] max-md:w-full">
                  <div className="flex flex-col gap-4 max-md:hidden">
                    <QRCodeSVG
                      value={qrCode}
                      size={180}
                      bgColor="#F2F3F3"
                    />
                    <span className="text-[12px] font-medium">Can’t scan QR code?</span>
                  </div>
                  <div className="relative">
                    <Button
                      className="h-10 rounded-[8px] bg-[#E9E9E9] flex gap-3 items-center justify-center w-[150px] max-md:w-full px-0 text-[12px] text-[#000] hover:bg-[#E9E9E9]"
                      category={ButtonCategory.Filled}
                      onClick={() => { handleCopyClick().then(); }}
                    >
                      <CopyIcon />
                      <h6>{copyText}</h6>
                    </Button>
                  </div>
                </div>
                <h5 className="leading-[160%] mt-10 mb-6">Enter the code generated by authenticator application</h5>
              </>
            )
            : (
              <>
                <h4>Two-factor Authentication</h4>
                <h5 className="leading-[160%] mb-16 mt-6">
                  Enter the code generated by authenticator application
                </h5>
              </>
            )
}
            <div className="flex flex-col gap-12 w-full">
              <Label>
                <OtpInput
                  value={verificationCode}
                  onChange={(value) => { setVerificationCode(value); }}
                  numInputs={6}
                  inputStyle={{
                    width: '44px',
                    height: '44px',
                    borderRadius: '8px',
                    borderColor: 'white',
                    background: 'transparent',
                  }}
                  renderSeparator={<span className="w-2" />}
                  renderInput={(props) => <input {...props} className="otp-input otp-input-default" />}
                />
              </Label>
              <h5 className="leading-[160%]">
                If you encounter any difficulties with authentication please
                <a href="https://www.pantheonmining.com/contact" target="_blank" rel="noopener noreferrer" className=" text-blue underline ml-2">Contact support</a>
              </h5>
              {qrCode && <Button className="w-52" category={ButtonCategory.Filled} onClick={handleSkipClick}>Skip for now</Button>}
            </div>
          </div>
        )}
    </>
  );
};
