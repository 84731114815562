import { AppElement, AppFC } from '../../../interfaces';

export const DropdownIcon: AppFC<{ color?: string }> = ({ color, ...rest }): AppElement => (
  <div {...rest}>
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <path d="M13 5.5L8 10.5L3 5.5" stroke={color || 'currentColor'} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  </div>

);
