import { SetStateAction, Dispatch } from 'react';

import { AlertCategory } from '../constants';
import { AppElement } from '../interfaces';

import { Alert } from '../components/Atoms';

export const generateErrorAlert = (
  reason: any,
  title: string,
  setAlert: Dispatch<SetStateAction<AppElement | undefined>>,
  className?: string,
  isClosable?: boolean,
  onClose?: ()=>void,
  children?: AppElement,
) => {
  const { data: errorData } = reason.response;
  const errorObject = Object.values(errorData);
  const stringError = typeof errorObject?.[0] === 'string' ? errorObject[0] : '';
  const objectError = typeof errorObject?.[0] === 'object' ? Object.values(errorObject[0] || {})?.[0] : '';
  const errorMessage = errorData?.details?.[0]?.message || errorData.detail || objectError || stringError || errorData?.title || errorData?.message || '';
  setAlert(
    <Alert
      className={className}
      category={AlertCategory.Error}
      title={title}
      description={errorData.spots || errorMessage}
      isClosable={isClosable}
      onClose={onClose}
    >
      {children || null}
    </Alert>,
  );
  if (!isClosable) {
    setTimeout(() => {
      setAlert(null);
    }, 7000);
  }
};
