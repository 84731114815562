import { useContext } from 'react';

import { httpRequest } from '../../services';
import {
  // AccountEnrichDataProps,
  HTTPRequestDataType,
  HttpRequestHeaderTypes,
} from '../../services/httpRequest';

import {
  ProfileActionTypes,
  ProfileContext,
  // WalletInterface
} from '../contexts';

import { PROFILE_API, RequestTypes } from '../../constants';

export const ProfileHandler = () => {
  const {
    GET_KYC_STATUS,
    POST_KYC_START,
    // GET_ALL_WALLETS,
    // GET_CORPORATE_ACCOUNT,
    PROFILE,
    PROFILE_UPDATE,
    UPDATE_PROFILE_KYC,
    // PROFILE_VERIFY_MOBILE,
    // PROFILE_RESEND_SMS,
  } = PROFILE_API;

  const {
    // profileState: { wallet = {} as WalletInterface },
    updateProfileState,
  } = useContext(ProfileContext);

  // const getCorporateAccount = async () => {
  //   const response = await httpRequest({
  //     url: GET_CORPORATE_ACCOUNT,
  //     method: RequestTypes.Get,
  //   });
  //   updateProfileState({
  //     type: ProfileActionTypes.UpdateProfileWallet,
  //     payload: { ...wallet, corporateAccounts: response.data?.wallets[0].accounts },
  //   });
  //
  //   return response.data;
  // };
  const getProfile = async () => {
    const response = await httpRequest({
      url: PROFILE,
      method: RequestTypes.Get,
    });

    updateProfileState({
      type: ProfileActionTypes.UpdateProfile,
      payload: response.data,
    });

    return response.data;
  };

  const updateProfile = async (data: HTTPRequestDataType | FormData) => {
    const response = await httpRequest({
      url: PROFILE_UPDATE,
      method: RequestTypes.Put,
      data,
      headerType: HttpRequestHeaderTypes.MultiPart,
    });

    updateProfileState({
      type: ProfileActionTypes.UpdateProfile,
      payload: response.data,
    });

    return response.data;
  };

  const updateProfileKYC = async (data: HTTPRequestDataType) => {
    const response = await httpRequest({
      url: UPDATE_PROFILE_KYC,
      method: RequestTypes.Put,
      data,
      headerType: HttpRequestHeaderTypes.MultiPart,
    });

    updateProfileState({
      type: ProfileActionTypes.UpdateProfile,
      payload: { isKycPassed: true },
    });

    return response.data;
  };
  // const verifyPhoneNumber = async (data: HTTPRequestDataType) => {
  //   const response = await httpRequest({
  //     url: PROFILE_VERIFY_MOBILE,
  //     method: RequestTypes.Post,
  //     data,
  //     headerType: HttpRequestHeaderTypes.MultiPart,
  //   });
  //   console.log('verifyPhoneNumber', response);
  //   updateProfileState({
  //     type: ProfileActionTypes.UpdateProfile,
  //     payload: {
  //       phoneVerified: true,
  //       emailVerified: true,
  //     },
  //   });
  //
  //   return response.data;
  // };
  // const resendSMS = async () => {
  //   const response = await httpRequest({
  //     url: PROFILE_RESEND_SMS,
  //     method: RequestTypes.Post,
  //     data: {},
  //   });
  //
  //   return response.data;
  // };

  const startKYC = async () => {
    const response = await httpRequest({
      url: POST_KYC_START,
      method: RequestTypes.Post,
      data: {},
    });

    const { status } = response?.data || {};

    if (status) {
      updateProfileState({
        type: ProfileActionTypes.UpdateProfile,
        payload: { KYCStatus: status },
      });
    }

    return response.data;
  };

  const getKYCStatus = async () => {
    const response = await httpRequest({
      url: GET_KYC_STATUS,
      method: RequestTypes.Get,
    });

    const {
      reviewStatus, emailVerified, reviewResult,
    } = response?.data || {};

    updateProfileState({
      type: ProfileActionTypes.UpdateProfile,
      payload: { KYCStatus: reviewStatus, reviewResult, emailVerified },
    });

    return response.data;
  };

  // const getAllWallets = async () => {
  //   const response = await httpRequest({
  //     url: GET_ALL_WALLETS,
  //     method: RequestTypes.Get,
  //   });
  //
  //   const { wallets } = response?.data || {};
  //
  //   if (wallets) {
  //     updateProfileState({
  //       type: ProfileActionTypes.UpdateProfileWallet,
  //       payload: {
  //         id: wallets[0].walletId,
  //         accounts: wallets[0].accounts,
  //         selectedAccount: wallets[0].accounts.eUR,
  //       },
  //     });
  //   }
  //
  //   return response.data;
  // };

  // const enrichAccount = async (data: AccountEnrichDataProps) => {
  //   const response = await httpRequest({
  //     url: PROFILE_API.ENRICH_ACCOUNT,
  //     method: RequestTypes.Post,
  //     data,
  //   });
  //
  //   if (response.data) {
  //     updateProfileState({
  //       type: ProfileActionTypes.UpdateProfileWalletAccountsDetails,
  //       payload: response.data,
  //     });
  //   }
  //   return response.data;
  // };

  return ({
    getProfile,
    updateProfile,
    updateProfileKYC,
    // verifyPhoneNumber,
    // resendSMS,
    startKYC,
    getKYCStatus,
    // getAllWallets,
    // getCorporateAccount,
    // enrichAccount,
  });
};
