import { AppElement, AppFC } from '../../../../interfaces';
import { IconProps } from './types';

export const AlertInfoIcon: AppFC<IconProps> = (props): AppElement => (
  <div {...props}>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12 2C10.0222 2 8.08879 2.58649 6.4443 3.6853C4.79981 4.78412 3.51809 6.3459 2.76121 8.17316C2.00433 10.0004 1.8063 12.0111 2.19215 13.9509C2.578 15.8907 3.53041 17.6725 4.92894 19.0711C6.32746 20.4696 8.10929 21.422 10.0491 21.8078C11.9889 22.1937 13.9996 21.9957 15.8268 21.2388C17.6541 20.4819 19.2159 19.2002 20.3147 17.5557C21.4135 15.9112 22 13.9778 22 12C22 9.34783 20.9464 6.8043 19.0711 4.92893C17.1957 3.05357 14.6522 2 12 2ZM10.3333 6.29167C10.3333 5.96203 10.4311 5.6398 10.6142 5.36572C10.7974 5.09163 11.0577 4.87801 11.3622 4.75187C11.6667 4.62572 12.0019 4.59272 12.3252 4.65702C12.6485 4.72133 12.9454 4.88007 13.1785 5.11315C13.4116 5.34624 13.5703 5.64321 13.6346 5.96652C13.699 6.28982 13.6659 6.62493 13.5398 6.92947C13.4137 7.23402 13.2 7.49431 12.926 7.67745C12.6519 7.86058 12.3296 7.95833 12 7.95833C11.7742 7.96964 11.5485 7.93489 11.3366 7.85621C11.1246 7.77752 10.9309 7.65655 10.7672 7.50065C10.6035 7.34474 10.4733 7.15716 10.3843 6.94932C10.2954 6.74149 10.2497 6.51773 10.25 6.29167H10.3333ZM16.1667 17C16.1667 17.221 16.0789 17.433 15.9226 17.5893C15.7663 17.7455 15.5543 17.8333 15.3333 17.8333H9.5C9.27899 17.8333 9.06703 17.7455 8.91075 17.5893C8.75447 17.433 8.66667 17.221 8.66667 17C8.66667 16.779 8.75447 16.567 8.91075 16.4107C9.06703 16.2545 9.27899 16.1667 9.5 16.1667H11.1667V11.1667H10.3333C10.1123 11.1667 9.90036 11.0789 9.74408 10.9226C9.5878 10.7663 9.5 10.5543 9.5 10.3333C9.5 10.1123 9.5878 9.90036 9.74408 9.74408C9.90036 9.5878 10.1123 9.5 10.3333 9.5H13.6667V16.1667H15.3333C15.5543 16.1667 15.7663 16.2545 15.9226 16.4107C16.0789 16.567 16.1667 16.779 16.1667 17Z" fill="#FF9900" />
    </svg>
  </div>
);
