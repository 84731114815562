import { useContext, useEffect } from 'react';

import { InvoicesContext } from '../../../stores';
import { AppElement, AppFC } from '../../../interfaces';

import { InvoiceCard } from '../../Molecules';
import { InvoiceRow } from '../../Molecules';

export const Invoices: AppFC = (): AppElement => {
  const { invoicesState: { invoices } } = useContext(InvoicesContext);
  const orderedInvoices = invoices.sort((a, b) => b.invoiceNumber - a.invoiceNumber);
  useEffect(() => {
    // Scroll to the top of the page after a redirect
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, []);

  return (
    <div className="flex flex-col gap-7 h-full">
      <h5 className="text-[1rem] font-semibold leading-[160%]">Invoices</h5>
      {orderedInvoices.length > 0 ? (
        <>
          <table className="max-md:hidden border-separate border-spacing-y-1 w-full text-sm text-left ">
            <thead className="font-semibold text-[#A0A0A0] bg-filled">
              <tr className="rounded-lg">
                <th scope="col" className="rounded-l-lg px-6 py-2.5">
                  Invoice number
                </th>
                <th scope="col" className="px-3 py-2.5">
                  Invoice Type
                </th>
                <th scope="col" className="px-3 py-2.5">
                  Project
                </th>
                <th scope="col" className="px-3 py-2.5">
                  Location
                </th>
                <th scope="col" className="px-6 py-2.5">
                  Status
                </th>
                <th scope="col" className="px-3 py-2.5">
                  Date
                </th>
                <th scope="col" className="rounded-r-lg px-3 py-2.5">
                  <h5 className="opacity-0 w-10">P</h5>
                </th>
              </tr>
            </thead>
            <tbody className="font-medium before:leading-3">
              {orderedInvoices?.map((invoice, index) => <InvoiceRow key={`invoice_row_${index}`} {...invoice} className="bg-filled" />)}
            </tbody>
          </table>
          <div className="md:hidden flex flex-col gap-3">
            {orderedInvoices?.map((invoice, index) => <InvoiceCard key={`invoice_card_${index}`} {...invoice} className="bg-filled" />)}
          </div>
        </>
      ) : <div className="self-center text-white/40"> Invoices doesn&apos;t exist</div>}
    </div>
  );
};
