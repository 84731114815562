import { useContext } from 'react';
import { AppElement, AppFC } from '../../../interfaces';
import { ProjectProps } from './types';

import { ProgressBox } from '../../Atoms';
import { ProfileContext } from '../../../stores';
import { CurrencyAmountBox } from '../CurrencyAmountBox';
import { CurrencyTypes, InvestmentStatusTypes } from '../../../constants';

export const UserProjectCard: AppFC<ProjectProps> = ({
  coverImage,
  title,
  soldSpots,
  totalSpots,
  className,
  boughtSpots = 0,
  pricePerMiner = 0,
  currency,
  investments,
}): AppElement => {
  const { profileState: { wallet } } = useContext(ProfileContext);
  const { selectedAccount } = wallet || {};
  const isPending = investments.find(({ status }) => status === InvestmentStatusTypes.Pending);

  return (
    <div className={`flex rounded-xl shadow-3xl hover:shadow-4xl hover:-translate-y-1 overflow-hidden max-sm:flex-col ${className}`}>
      <div className="relative w-[40%] max-sm:w-full rounded-l-xl overflow-hidden">
        {soldSpots === totalSpots && <div className="absolute top-0 p-3 w-full font-semibold text-white text-[14px] text-center bg-[#FF8B36] flex items-center justify-center">This project is sold out</div>}
        <img src={coverImage} className="h-full w-full rounded-l-xl object-cover" alt="SumsubKYC" />
      </div>
      <div className="bg-white text-black flex flex-col justify-center p-5 w-[60%] max-sm:w-full gap-9">
        <div className="flex justify-between">
          <h5 className="text-[1rem] font-semibold leading-[160%]">{title}</h5>
          {isPending && <span className="flex items-center justify-center bg-[#E4B14E] p-2 font-semibold rounded-lg leading-none text-[12px] whitespace-nowrap h-fit">Pending Approval</span>}
        </div>
        <div className="flex gap-20 max-sm:justify-between max-sm:gap-0">
          <div className="flex flex-col items-start gap-1">
            <h5 className="text-coldBrown text-[12px]">Your Miners</h5>
            <h5 className="text-[20px]">{boughtSpots}</h5>
          </div>
          <div className="flex flex-col items-end gap-1">
            <h5 className="text-coldBrown text-[12px]">Purchased</h5>
            <h5 className="text-[20px]">
              <CurrencyAmountBox projectCurrency={currency} amount={boughtSpots * pricePerMiner} currency={selectedAccount?.currency || CurrencyTypes.EUR} />
            </h5>
          </div>
        </div>
        <ProgressBox
          className="mt-3"
          labelSize="14px"
          valueSize="16px"
          currentAmount={soldSpots}
          totalAmount={totalSpots}
        />
      </div>
    </div>
  );
};
