import React, { useContext } from 'react';

import { ProjectsContext } from '../../../stores';

import { AppElement, AppFC } from '../../../interfaces';
import { CurrencyAmountBoxProps } from './types';
import { convertAmountByCurrency } from '../../../utils';
import { CurrencyTypes, ProjectCurrencyTypes } from '../../../constants';

import { Loader } from '../../Atoms';

export const CurrencyAmountBox: AppFC<CurrencyAmountBoxProps> = ({
  amount,
  currency,
  isLoader,
  prefix,
  className,
  decimals = 2,
  projectCurrency,
  ...rest
}): AppElement => {
  const { projectsState: { rates } } = useContext(ProjectsContext);
  console.log('rate', rates);
  if (!amount && Number(amount) !== 0) {
    return null;
  }

  return (
    <div className="flex flex-col gap-1 items-end [&>span]:leading-[100%]" {...rest}>
      <span className={`flex items-center gap-2 text-end ${className || ''}`}>
        {currency !== CurrencyTypes.EUR && isLoader && <Loader className="w-5 h-5 " />}
        {prefix && <span>{prefix}</span>}
        {projectCurrency === ProjectCurrencyTypes.EUR
          ? `€ ${convertAmountByCurrency(rates, '' as CurrencyTypes, Number(amount), decimals)}`
          : `${convertAmountByCurrency(rates, CurrencyTypes.EUR, Number(amount), decimals)}`}
      </span>
      {(projectCurrency === ProjectCurrencyTypes.USD) && (
      <span className="text-[12px]">
          {`$ ${convertAmountByCurrency(rates, '' as CurrencyTypes, Number(amount), decimals)}`}
      </span>
      )}
    </div>

  );
};

// import React, { useContext } from 'react';
//
// import { ProfileContext, ProjectsContext } from '../../../stores';
//
// import { AppElement, AppFC } from '../../../interfaces';
// import { CurrencyAmountBoxProps } from './types';
// import { convertAmountByCurrency } from '../../../utils';
// import { CurrencyTypes } from '../../../constants';
//
// import { Loader } from '../../Atoms';
//
// export const CurrencyAmountBox: AppFC<CurrencyAmountBoxProps> = ({
//                                                                    amount,
//                                                                    currency,
//                                                                    isLoader,
//                                                                    prefix,
//                                                                    className,
//                                                                    decimals = 2,
//                                                                    projectCurrency,
//                                                                    ...rest
//                                                                  }): AppElement => {
//   const { projectsState: { rates } } = useContext(ProjectsContext);
//   const { profileState: { strigaId } } = useContext(ProfileContext);
//
//   if (!amount && Number(amount) !== 0) {
//     return null;
//   }
//
//   return (
//       <div className="flex flex-col gap-1 items-end [&>span]:leading-[100%]" {...rest}>
//         {strigaId
//             ? (
//                 <>
//             <span className={`flex items-center gap-2 text-end ${className || ''}`}>
//               {currency !== CurrencyTypes.EUR && isLoader && <Loader className="w-5 h-5 " />}
//               {prefix && <span>{prefix}</span>}
//               {currency === CurrencyTypes.EUR && projectCurrency === 'EUR'
//                   ? `€ ${convertAmountByCurrency(rates, '' as CurrencyTypes, Number(amount), decimals)}`
//                   : convertAmountByCurrency(rates, currency, Number(amount), decimals)}
//             </span>
//                   {(projectCurrency !== currency) && (
//                       <span className="text-[12px] text-white/80">
//               {projectCurrency === 'USD'
//                   ? `$ ${convertAmountByCurrency(rates, '' as CurrencyTypes, Number(amount), decimals)}`
//                   : `€ ${convertAmountByCurrency(rates, '' as CurrencyTypes, Number(amount), decimals)}`}
//             </span>
//                   )}
//                 </>
//             ) : (
//                 <span className={`flex items-center gap-2 text-end ${className || ''}`}>
//             {prefix && <span>{prefix}</span>}
//                   {convertAmountByCurrency(rates, CurrencyTypes.EUR, Number(amount), decimals)}
//           </span>
//             )}
//       </div>
//
//   );
// };
