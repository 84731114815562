import { useState } from 'react';
import { AppElement, AppFC } from '../../../interfaces';
import { InputProps } from './types';
import { BlockedIcon, EyeIcon, EyeSlashIcon } from '../icons';

export const Input: AppFC<InputProps> = ({
  className,
  disabled,
  blocked,
  type = 'text',
  onIconClick,
  error,
  ...rest
}): AppElement => {
  const [inputType, setInputType] = useState(type);
  const handleIconClick = () => {
    if (inputType === 'password') {
      setInputType('text');
    } else {
      setInputType('password');
    }
  };
  return (
    <div className="relative">
      {blocked && <BlockedIcon className="absolute z-10 translate-y-[-50%] top-[50%] right-[1rem]" />}
      <input type={inputType} disabled={blocked || disabled} className={`w-full rounded-lg px-4 py-3 border border-default focus:outline-none h-12 ${blocked || disabled ? 'bg-disabled text-[#99938C] pointer-events-none focus:border-default' : 'bg-transparent'} ${error ? 'border-error focus:border-error focus:border-2' : 'border-default focus:border-lightBrown focus:border-2'} ${className}`} {...rest} />
      {type === 'password' && (
      <>
        {
            inputType === 'password'
              ? (
                <EyeSlashIcon
                  onClick={handleIconClick}
                  className="cursor-pointer w-6 h-6 absolute z-10 translate-y-[-50%] top-[50%] right-[1rem]"
                  error={!!error}
                />
              )
              : (
                <EyeIcon
                  onClick={handleIconClick}
                  className="cursor-pointer w-6 h-6 absolute z-10 translate-y-[-50%] top-[50%] right-[1rem]"
                  error={!!error}
                />
              )
          }
      </>
      )}
      {error && <p className={`absolute w-full text-[10px] mt-1 text-end text-error ${className}`}>{error.message}</p>}
    </div>
  );
};
