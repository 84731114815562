import { AppElement, AppFC } from '../../../interfaces';
import { AlertProps } from './types';
import { AlertCategory } from '../../../constants';

import {
  AlertErrorIcon, AlertSuccessIcon, AlertWarningIcon, AlertInfoIcon,
} from '../icons';
import closeIcon from '../../../assets/icons/close.svg';

export const Alert: AppFC<AlertProps> = ({
  className,
  category,
  title,
  description,
  isClosable,
  onClose,
  children,
}): AppElement => {
  const getIcon = () => {
    switch (category) {
      case AlertCategory.Error:
        return <AlertErrorIcon className="w-[56px] h-[56px]" width="56px" height="56px" />;
      case AlertCategory.Warning:
        return <AlertWarningIcon className="w-[56px] h-[56px]" width="56px" height="56px" />;
      case AlertCategory.Success:
        return <AlertSuccessIcon className="w-[56px] h-[56px]" width="56px" height="56px" />;
      case AlertCategory.Info:
        return <AlertInfoIcon />;
      default:
        return <AlertErrorIcon className="w-[56px] h-[56px]" width="56px" height="56px" />;
    }
  };
  return (
    <div className={`absolute ${category === AlertCategory.Info ? 'w-auto p-4' : 'w-full px-3.5 py-2'} max-w-[500px] flex-col items-center gap-3.5 bg-white text-black rounded-xl shadow-3xl ${className}`}>
      <div className={`relative w-full flex ${category === AlertCategory.Info ? 'gap-2' : 'gap-3.5'}  items-center`}>
        {isClosable && (
        <button
          onClick={(event) => {
            event.stopPropagation();
            if (onClose) onClose();
          }}
          className="absolute -top-2 -right-2 cursor-pointer p-0 h-auto"
        >
          <img src={closeIcon} alt="close" />
        </button>
        )}
        {getIcon()}
        <div className="flex flex-col justify-center">
          <h5 className="leading-[160%] text-[1rem] font-semibold">{title}</h5>
          {description && <p className="font-medium">{description}</p>}
        </div>
      </div>
      {children}
    </div>
  );
};
