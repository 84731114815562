import React, { useContext, useEffect, useState } from 'react';

import {
  ProfileContext,
  ProjectsContext,
  ViewContext,
  ProjectsActionTypes,
  ViewModalActionTypes,
} from '../../../../../stores';

import { AppElement, AppFC } from '../../../../../interfaces';
import {
  ButtonCategory,
  CurrencyTypes,
  KYCStatusTypes,
  currencyKeys,
  walletIcons,
  walletKeys,
} from '../../../../../constants';

import { InvoiceHandler, ProjectHandler } from '../../../../../stores/handlers';

import { ReviewResultInterface, WalletInterface } from '../../../../../stores/contexts';
import {
  generateErrorAlert,
  getFormattedAmount,
  getStatus,
  numberWithCommas,
} from '../../../../../utils';

import { CurrencyAmountBox, WalletCard } from '../../../../Molecules';
import { Button, Loader } from '../../../../Atoms';

import lineIcon from '../../../../../assets/icons/line.svg';
import exclamationIcon from '../../../../../assets/icons/exclamation.svg';

export const InvestmentDetails: AppFC = ({ className }): AppElement => {
  const [isLoading, setIsLoading] = useState(false);
  const [alert, setAlert] = useState<AppElement>();
  const [seconds, setSeconds] = useState(10);

  const { viewModal: { info }, updateViewModal } = useContext(ViewContext);
  const { projectsState: { envelopeId, rates }, updateProjectsState } = useContext(ProjectsContext);
  const {
    profileState: {
      wallet, KYCStatus, strigaId, reviewResult,
    },
  } = useContext(ProfileContext);

  const { getRates, createInvestmentAgreement } = ProjectHandler();
  const { generateInvoice } = InvoiceHandler();

  const status = getStatus(KYCStatus as KYCStatusTypes, reviewResult as ReviewResultInterface);

  const { accounts, selectedAccount: defaultSelectedAccount } = wallet || {} as WalletInterface;
  const [selectedAccount, setSelectedAccount] = useState(defaultSelectedAccount);
  const [userIsSolvent, setUserIsSolvent] = useState(true);
  const { amount, currency: amountCurrency } = selectedAccount?.availableBalance || {};

  /** Here is the check of supported countries,
   *  and if user country is not supported by striga,
   *  user will see the investment details
   *  and after confirmation he/she will pass agreement flow
   *  and see the bank data for manual investment * */

  useEffect(() => {
    if (strigaId) {
      const timer = setInterval(() => {
        setSeconds((prevSeconds) => {
          if (prevSeconds === 0) {
            getRates().then();
            return 10;
          }
          return prevSeconds - 1;
        });
      }, 1000);

      if (selectedAccount.currency === CurrencyTypes.EUR) {
        setUserIsSolvent(getFormattedAmount(amount, amountCurrency) >= Number(info?.totalPrice));
        clearInterval(timer);
      } else {
        getRates().then((res) => {
          setUserIsSolvent(getFormattedAmount(amount, amountCurrency) >= (Number(info?.totalPrice)) / (Number(res?.[`${currencyKeys[selectedAccount.currency]}EUR`]?.sell) || 1));
        });
        setSeconds(10);
      }

      return () => {
        clearInterval(timer);
      };
    }
    return () => {};
  }, [selectedAccount]);

  const handleConfirmClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    if (userIsSolvent && info?.projectId) {
      /** Investment flow - 1.Invoice generation by spots * */

      const invoiceData = {
        numberOfMiners: Number(info?.spotsAmount),
      };
      setIsLoading(true);
      generateInvoice(info.projectId, invoiceData).then((res) => {
        const { invoiceNumber, id } = res.data;

        /** Investment flow - 2.Investment Agreement document generation by invoice number and spots * */
        const projectAgreementData = {
          invoiceNumber,
          spots: info?.spotsAmount as number,
          currency: selectedAccount.currency,
          amount: (Number(info?.totalPrice) / (Number(rates?.rates.eUR) || 1)) * (info?.spotsAmount as number),
        };
        createInvestmentAgreement(info.projectId as string, projectAgreementData).then(({ data }) => {
          setIsLoading(false);
          /** Open external document link for sign * */
          updateViewModal({
            type: ViewModalActionTypes.DocusignModal,
            payload: {
              isClosable: false,
              info: {
                ...info,
                url: data.redirectUrl,
                selectedAccount,
                invoiceId: id,
              },
            },
          });
        }).catch((reason) => {
          generateErrorAlert(reason, 'Purchase agreement file generation Failed!', setAlert, 'top-6 z-50');
          setIsLoading(false);
        });
      }).catch((reason) => {
        generateErrorAlert(reason, 'Invoice Generation Failed!', setAlert, 'top-6 z-50');
        setIsLoading(false);
      });
    }
  };

  const handleAddBalanceClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    if (status === KYCStatusTypes.APPROVED) {
      updateViewModal({
        type: ViewModalActionTypes.AccountDetailsModal,
        payload: {
          title: 'Account Details',
          isClosable: true,
          info: {
            ...info,
            selectedAccount,
          },
          importantMessage: 'Please ensure that the funds are transferred from your personal bank account, which must match the first and last name you have registered on the Pantheon Portal.',
        },
      });
    }
  };

  return (
    <div className={`flex flex-col items-center justify-center gap-16 ${className}`}>
      {alert}
      {selectedAccount
        ? (
          <>
            <div className="flex flex-col justify-center items-center gap-1">
              <span className="text-[24px]"> You are investing into</span>
              <span className="text-[24px] font-semibold">{info?.projectName}</span>
            </div>
            <div>
              <h6 className="mb-3">Available balance</h6>
              <div className="flex flex-wrap gap-2">
                {walletKeys.map((key, index) => (
                  <WalletCard
                    key={`wallet-${index}`}
                    className="[&>img]:h-10 [&>img]:w-10"
                    icon={walletIcons[key]}
                    currency={accounts[key].currency}
                    balance={accounts[key].availableBalance}
                    onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                      event.stopPropagation();
                      setSelectedAccount(accounts[key]);
                    }}
                    selected={accounts[key].accountId === selectedAccount?.accountId}
                    decimals={5}
                  />
                ))}
              </div>
              {selectedAccount.currency !== CurrencyTypes.EUR && (
              <div className="flex items-center gap-2 mt-4">
                <span className="flex items-center font-semibold">
                  <img className="h-6 w-6 mr-0.5" src={walletIcons[currencyKeys[selectedAccount.currency]]} alt="" />
                  1
                  {' '}
                  {selectedAccount.currency}
                  {' '}
                  = €
                  {numberWithCommas(rates?.rates.eUR)}
                </span>
                <Loader className="w-5 h-5" />
                <span>{seconds}</span>
              </div>
              )}
            </div>
            <div className="w-full">
              <h6 className="mb-3">Transaction details</h6>
              <div className={`w-full rounded-xl p-4 text-[18px] ${userIsSolvent ? 'bg-[#F9F9F9]' : 'bg-[#EE161614] border-[#EE161666] border'}`}>
                <div className="flex justify-between items-center">
                  <span>
                    {info?.spotsAmount}
                    {' '}
                    Miners
                  </span>
                  <CurrencyAmountBox
                    prefix={`${info?.spotsAmount} x `}
                    amount={Number(info?.pricePerMiner)}
                    currency={selectedAccount.currency}
                    decimals={5}
                    isLoader
                  />
                </div>
                <img src={lineIcon} className="my-4 w-full" alt="line icon" />
                <div className="flex justify-between items-center">
                  <span>Total</span>
                  <CurrencyAmountBox
                    className="font-semibold"
                    amount={info?.totalPrice}
                    currency={selectedAccount.currency}
                    decimals={5}
                    isLoader
                  />
                </div>
              </div>
              {!userIsSolvent && (
              <div className="flex gap-2 items-center mt-4">
                <img src={exclamationIcon} alt="icon" />
                <span className="text-error">Insufficient balance</span>
                <Button
                  className="h-8 ml-2 px-[18px] text-[12px]"
                  category={ButtonCategory.Outlined}
                  onClick={handleAddBalanceClick}
                >
                  Add Balance
                </Button>
              </div>
              )}
            </div>
            <div className="w-full flex justify-center items-center gap-2 mt-6 max-sm:flex-col max-sm:[&>button]:w-full">
              <Button
                className="w-[200px] px-6"
                category={ButtonCategory.Outlined}
                onClick={() => {
                  if (envelopeId) {
                    updateProjectsState({ type: ProjectsActionTypes.SetEnvelopeId, payload: '' });
                  }
                  updateViewModal({ type: ViewModalActionTypes.Close });
                }}
              >
                Cancel
              </Button>
              <Button
                className="w-[200px] px-6"
                category={ButtonCategory.Filled}
                disabled={!info?.totalPrice}
                isLoading={isLoading}
                onClick={handleConfirmClick}
              >
                Confirm investment
              </Button>
            </div>
          </>
        )
        : (
          <>
            <span className="mt-2 text-center">
              <h4 className="text-[20px]">You don’t have sufficient funds.</h4>
              <h5 className="text-[16px] mt-2"> Please add deposit funds into your account and try again.</h5>
            </span>
            <div className="w-full flex justify-center gap-2 -mt-6">
              <Button
                className="max-w-[240px] flex-1"
                category={ButtonCategory.Filled}
                onClick={handleAddBalanceClick}
              >
                Add Balance
              </Button>
            </div>
          </>
        )}
    </div>
  );
};
