import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import CryptoJS from 'crypto-js';
import { httpRequest } from '../../services';

import {
  CRYPTO_API, ProjectCurrencyTypes,
  RequestTypes,
} from '../../constants';

export const CryptoHandler = () => {
  const API_KEY = '20d592a7-f38a-4680-a8ad-fae97ca0d849';
  const API_SECRET = '0c6e9e22-ee09-4179-9bf0-c92f1f8a28127770ed3c-cf54-4c67-bf31-80d07883a7f2';
  const ORGANIZATION_ID = '82ee1c70-bd0c-463c-8d5a-c93a90857dba';

  const getTimestamp = async (): Promise<string> => {
    const response = await axios.get('/api/v2/time');
    return response.data.serverTime;
  };

  const generateNonce = (): string => uuidv4();

  const createSignature = (
    apiKey: string,
    apiSecret: string,
    timestamp: string,
    nonce: string,
    organizationId: string,
    method: string,
    path: string,
    queryString: string,
    body?: object,
  ): string => {
    const delimiter = '\0';
    let input = [
      apiKey,
      timestamp,
      nonce,
      '',
      organizationId,
      '',
      method.toUpperCase(),
      path,
      queryString,
    ].join(delimiter);

    if (body) {
      input += delimiter + JSON.stringify(body);
    }

    return CryptoJS.HmacSHA256(input, apiSecret).toString(CryptoJS.enc.Hex);
  };

  const getMiningRigs = async () => {
    const url = 'https://api-test.nicehash.com/main/api/v2/mining/rigs2';
    const queryString = 'algorithm=X16R&page=0&size=100';
    const fullUrl = `${url}?${queryString}`;

    const timestamp = await getTimestamp();
    const nonce = generateNonce();
    const method = 'GET';
    const path = '/main/api/v2/mining/rigs2';

    const signature = createSignature(API_KEY, API_SECRET, timestamp, nonce, ORGANIZATION_ID, method, path, queryString);

    const headers = {
      'X-Time': timestamp,
      'X-Nonce': nonce,
      'X-Organization-Id': ORGANIZATION_ID,
      'X-Request-Id': uuidv4(),
      'X-Auth': `${API_KEY}:${signature}`,
    };

    try {
      const response = await axios.get(fullUrl, { headers });
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        console.error('Error making request', error.response.data);
      } else {
        console.error('Error making request', error);
      }
      return error;
    }
  };

  const getBitcoinPrice = async (currency: ProjectCurrencyTypes) => {
    try {
      const response = await httpRequest({
        url: `${CRYPTO_API.PRICES}/BTC-${currency}/spot`,
        method: RequestTypes.Get,
      });
      console.log(`The current price of Bitcoin is $${response.data.data.amount} USD`);
      return response.data;
    } catch (error) {
      console.error('Error fetching Bitcoin price:', error);
      return error;
    }
  };

  // const getMiningRigs = async () => {
  //   try {
  //     const response = await httpRequest({
  //       url: `${CRYPTO_API.NICEHASH}/main/api/v2/mining/rigs2`,
  //       method: RequestTypes.Get,
  //     });
  //     console.log('Mining rigs:', response.data);
  //     return response.data;
  //   } catch (error) {
  //     console.error('Error fetching Bitcoin price:', error);
  //     return error;
  //   }
  // };

  return {
    getBitcoinPrice,
    getMiningRigs,
  };
};
