import {
  useContext, useEffect, useRef, useState,
} from 'react';
import { useReactToPrint } from 'react-to-print';

import { useNavigate } from 'react-router-dom';
import {
  ProjectsActionTypes, ProjectsContext, ViewContext, ViewModalActionTypes,
} from '../../../stores';
import { AppElement, AppFC } from '../../../interfaces';

// import { httpRequest } from '../../../services';
import {
  CurrencyTypes,
  KYCStatusTypes,
  ModalTypes,
  Paths,
  // PROFILE_API, RequestTypes,
} from '../../../constants';
import { CloseIcon, PrintIcon } from '../../Atoms/icons';
import { InvoiceHandler, ProfileHandler, ProjectHandler } from '../../../stores/handlers';
import { generateErrorAlert, getStatus } from '../../../utils';
import { Loader } from '../../Atoms';

export const Modal: AppFC = ({ className, children }): AppElement => {
  const [alert, setAlert] = useState<AppElement>();
  const [isLoading, setIsLoading] = useState(false);

  const { viewModal, updateViewModal } = useContext(ViewContext);
  const { projectsState: { envelopeId, rates }, updateProjectsState } = useContext(ProjectsContext);
  const {
    modalType,
    content,
    isClosable,
    isPrintable,
    // isReload,
    // kycTier,
    isOpen,
    ref,
    info,
  } = viewModal;

  // const { GET_KYC_STATUS, POST_KYC_START } = PROFILE_API;
  const navigate = useNavigate();
  const contentRef = useRef(null);

  const {
    getKYCStatus,
    getProfile,
  } = ProfileHandler();

  const {
    generateInvoice,
    getInvoices,
  } = InvoiceHandler();

  const {
    createInvestmentAgreement,
  } = ProjectHandler();

  const handlePrint = useReactToPrint({
    content: () => contentRef?.current,
  });

  const handleClose = () => {
    if (envelopeId) {
      updateProjectsState({ type: ProjectsActionTypes.SetEnvelopeId, payload: '' });
    }
    // if (kycTier && kycTier < 3 && process.env.REACT_APP_MODE === 'production') {
    //   httpRequest({
    //     url: GET_KYC_STATUS,
    //     method: RequestTypes.Get,
    //   }).then((response) => {
    //     const { status } = response.data[`tier${kycTier}`];
    //     if (status === 'APPROVED') {
    //       httpRequest({
    //         data: { tier: kycTier + 1 },
    //         url: POST_KYC_START,
    //         method: RequestTypes.Post,
    //       }).then((res) => {
    //         if (res.data.verificationLink) {
    //           updateViewModal({
    //             type: ViewModalActionTypes.SumsubModal,
    //             payload: {
    //               isClosable: true,
    //               isReload: true,
    //               kycTier: kycTier + 1,
    //               info: {
    //                 ...viewModal.info,
    //                 url: res.data.verificationLink,
    //               },
    //             },
    //           });
    //         } else {
    //           updateViewModal({ type: ViewModalActionTypes.Close });
    //         }
    //       }).catch(() => {
    //         updateViewModal({ type: ViewModalActionTypes.Close });
    //       });
    //     }
    //   });
    // } else {
    if (modalType === ModalTypes.SumsubModal && info?.projectId) {
      setIsLoading(true);
      getKYCStatus().then(({ reviewStatus, reviewResult }) => {
        const status = getStatus(reviewStatus, reviewResult);
        if (status === KYCStatusTypes.APPROVED) {
          /** Investment flow - 1.Invoice generation by spots * */

          const invoiceData = {
            numberOfMiners: Number(info?.spotsAmount),
          };

          if (info?.projectId) {
            generateInvoice(info.projectId, invoiceData)
              .then((res) => {
                const {
                  invoiceNumber,
                  id,
                } = res.data;

                /** Investment flow - 2.Investment Agreement document generation by invoice number and spots * */
                const projectAgreementData = {
                  invoiceNumber,
                  spots: info?.spotsAmount as number,
                  currency: CurrencyTypes.EUR, // selectedAccount.currency,
                  amount: Number(((Number(info?.totalPrice) * (Number(rates?.rates.eUR) || 1)) * (info?.spotsAmount as number)).toFixed(4)),
                };
                createInvestmentAgreement(info.projectId as string, projectAgreementData)
                  .then(({ data }) => {
                    setIsLoading(false);
                    /** Open external document link for sign * */
                    updateViewModal({
                      type: ViewModalActionTypes.DocusignModal,
                      payload: {
                        isClosable: false,
                        info: {
                          ...info,
                          url: data.redirectUrl,
                          invoiceId: id,
                        },
                      },
                    });
                  })
                  .catch((reason) => {
                    generateErrorAlert(reason, 'Purchase agreement file generation Failed!', setAlert, 'top-6 z-50');
                    setIsLoading(false);
                  });
              })
              .catch((reason) => {
                generateErrorAlert(reason, 'Invoice Generation Failed!', setAlert, 'top-6 z-50');
                setIsLoading(false);
              });
          }

          // updateViewModal({
          //   type: ViewModalActionTypes.BankingWithPassedKYCModal,
          //   payload: {
          //     title: 'Sovereign Banking Access',
          //     description: 'The Pantheon Mining Portal, in collaboration with Striga, is pleased to offer advanced sovereign banking services.',
          //     ref,
          //     info: {
          //       ...info,
          //       totalPrice: Number(pricePerMiner) * Number(spotsAmount),
          //       pricePerMiner,
          //       spotsAmount,
          //       projectName: title,
          //       projectId,
          //       selectedAccount,
          //     },
          //   },
          // });
        } else {
          updateViewModal({ type: ViewModalActionTypes.Close });
        }
      }).catch((reason) => {
        setIsLoading(false);
        generateErrorAlert(reason, 'Purchase agreement file generation Failed!', setAlert, 'top-6 z-50');
      });
    } else if (modalType === ModalTypes.AccountDetailsModal) {
      // window.location.reload();
      Promise.all([
        getInvoices(),
        getProfile(),
      ])
        .then(() => {
          navigate(Paths.Projects);
          updateViewModal({
            type: ViewModalActionTypes.Close,
          });
        })
        .catch((e) => {
          console.log('project/invoice/profile/getKYCStatus data error', e);
        });
    } else {
      updateViewModal({ type: ViewModalActionTypes.Close });
    }
    // }
  };

  const handleOutsideClick = (e: MouseEvent) => {
    if (ref?.current && !ref.current?.contains(e.target as Node) && isOpen) {
      handleClose();
    }
  };

  useEffect(() => {
    setTimeout(() => { document.addEventListener('click', handleOutsideClick); });
    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, []);

  return (
    <div ref={ref} className={`relative flex justify-center rounded-lg max-md:w-full ${className || ''} shadow-white03`}>
      {alert}
      <div className="absolute z-10 top-4 right-4 p-0 flex gap-2 [&>button]:h-auto [&>button]:p-0">
        {isPrintable && (
        <button onClick={handlePrint}>
          <PrintIcon />
        </button>
        )}
        {isClosable && (
        <button onClick={handleClose}>
          <CloseIcon />
        </button>
        )}
      </div>
      <div ref={contentRef} className="w-full h-full flex justify-center">{isLoading ? <Loader className="self-center justify-self-center w-8 h-8" /> : content || children}</div>
    </div>
  );
};
