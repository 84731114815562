import { NavLink } from 'react-router-dom';

import React from 'react';
import { AppElement, AppFC } from '../../../interfaces';
import { ProjectProps } from './types';

import { Button, ProgressBox } from '../../Atoms';
import { ButtonCategory, Paths } from '../../../constants';
import { ArrowIcon } from '../../Atoms/icons';

export const ProjectCard: AppFC<ProjectProps> = ({
  id,
  coverImage,
  title,
  soldSpots,
  totalSpots,
  description,
  className,
}): AppElement => (
  <div className={`flex flex-col items-center h-full rounded-xl shadow-3xl bg-white text-black ${className || ''}`}>
    <div className="relative w-full h-[200px] rounded-t-xl overflow-hidden">
      {soldSpots === totalSpots && <div className="absolute top-0 p-3 w-full font-semibold text-white text-[14px] text-center bg-[#FF8B36] flex items-center justify-center">This project is sold out</div>}
      <img src={coverImage} className="h-full w-full rounded-t-xl object-cover" alt="SumsubKYC" />
    </div>
    <div className="flex flex-col p-5 w-full flex-1">
      <div className="flex flex-col flex-1 gap-2">
        <h5 className="text-[1rem] font-semibold leading-[160%]">{title}</h5>
        <p className="text-[14px]">
          {description}
        </p>
      </div>
      <ProgressBox className="mt-5" currentAmount={soldSpots} totalAmount={totalSpots} />
      <NavLink to={`${Paths.Projects}/${id}`}>
        <Button className="w-full flex items-center justify-center gap-4 mt-9" category={ButtonCategory.Default}>
          <h5 className="text-[1rem]">
            {
            // soldSpots === totalSpots ?
                'Explore the Project'
                // : 'Purchase'
          }
          </h5>
          <ArrowIcon className="text-white w-4 h-4" />
        </Button>
      </NavLink>
    </div>
  </div>
);
