import { ProjectsActionTypes } from './actions';
import { UseReducerType } from '../../interfaces';
import { ProjectsActionType } from './interfaces';
import { ProjectsInterface } from '../../../types/projects';

export const projectsReducer: UseReducerType<ProjectsInterface, ProjectsActionType> = (
  state,
  action,
): ProjectsInterface => {
  switch (action.type) {
    case ProjectsActionTypes.SetProjects:
      return { ...state, projects: action.payload };
    case ProjectsActionTypes.SetRates:
      return { ...state, rates: action.payload };
    case ProjectsActionTypes.SetSwapFee:
      return { ...state, swapFee: action.payload.swapFee / 100 };
    case ProjectsActionTypes.SetEnvelopeId:
      return { ...state, envelopeId: action.payload };
    case ProjectsActionTypes.SetWhitelistedAddresses:
      return { ...state, whitelistedAddresses: { ...state.whitelistedAddresses, ...action.payload } };
    default:
      return state;
  }
};
