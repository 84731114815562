import React from 'react';
import {
  BarController, BarElement, CategoryScale, Chart as ChartJS, Filler, Legend, LinearScale, LineController, LineElement, PointElement, Title, Tooltip,
} from 'chart.js';
import { Chart } from 'react-chartjs-2';
import { AppFC } from '../../../interfaces';
import { ChartProps } from './types';
import { formatNumberWithK } from '../../../utils';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineController,
  BarController,
  Filler,
);

export const MixedChart: AppFC<ChartProps> = ({
  labels, cashFlows, revenues, profits, electricityCost, currency, className,
}) => {
  const datasetOrder = ['Revenue', 'Profit', 'Electricity Cost', 'Cash Flow (Cumulative)', 'Profit/Loss (Cumulative)'];

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    tension: 0.2,
    interaction: {
      intersect: false,
      mode: 'index',
    },
    plugins: {
      legend: {
        position: 'bottom' as const,
        marginTop: '20px',
        labels: {
          font: {
            size: 12, // Font size of legend labels
          },
          padding: 24, // Padding around legend labels
          boxWidth: 16, // Width of the color box next to the legend label
          boxHeight: 16, // Height of the color box next to the legend label
          generateLabels: (chart: { data: { datasets: any[]; }; isDatasetVisible: (arg0: any) => any; }) => chart.data.datasets.map((dataset, i) => ({
            text: dataset.label,
            fontColor: 'white', // For older versions of Chart.js (pre v3)
            fillStyle: dataset.label === 'Cash Flow (Cumulative)' ? 'rgb(24,63,31' : dataset.backgroundColor,
            strokeStyle: 'transparent', // Ensure the border is not visible
            lineWidth: 0, // Set border width to 0
            borderRadius: 3,
            datasetIndex: i,
          })),
          sort: (a: { text: string; }, b: { text: string; }) => datasetOrder.indexOf(a.text) - datasetOrder.indexOf(b.text),
        },
      },
      tooltip: {
        padding: 12,
        callbacks: {
          title: (item: any) => `Month ${item[0].label}`,
          labelColor: (context: { dataset: { backgroundColor: any; label: string }; }) => {
            const { dataset: { backgroundColor, label } } = context;
            return {
              backgroundColor: label === 'Cash Flow (Cumulative)' ? '#481b1b' : backgroundColor,
              borderColor: '#4b1a1a',
              borderWidth: 0,
              strokeStyle: 'transparent', // Ensure the border is not visible
              lineWidth: 0, // Set border width to 0
            };
          },
          label: (tooltipItem: any) => {
            const { dataset: { label }, parsed: { y } } = tooltipItem;
            return ` ${label}: ${y < 0 ? '-' : ''}${currency}${formatNumberWithK(Math.abs(y))}`;
          },
        },
        // Add a custom formatter to use the color styles in the tooltip
        // You need to use a renderer that can handle styling
        // This works in Chart.js 3.x and later
        // For older versions, you might need to use HTML tooltips
        // See Chart.js documentation for more details
        // https://www.chartjs.org/docs/latest/configuration/tooltip.html
        // https://www.chartjs.org/docs/latest/developers/theming.html
        // Note: Customize this if you're using a different version or setup
      },
    },
    scales: {
      x: {
        grid: {
          display: false, // Hide vertical grid lines
          drawBorder: false, // Draw the border of the y-axis
        },
        ticks: {
          font: {
            size: 12, // Font size
            fontWeight: 400, // Font weight
          },
          color: '#C7C7C7', // Font color
          maxRotation: 0, // Prevent rotation of tick labels
          minRotation: 0, // Prevent rotation of tick labels
        },
      },
      y: {
        beginAtZero: true,
        grid: {
          color: 'rgba(239, 243, 247, 0.3)', // Color of horizontal grid lines
          borderColor: 'rgba(239, 243, 247, 0.3)', // Color of the horizontal axis line
          lineWidth: 1, // Width of horizontal grid lines
        },
        ticks: {
          font: {
            size: 12, // Font size
            fontWeight: 400, // Font weight
          },
          color: '#C7C7C7', // Font color
          padding: 10, // Padding between the labels and the axis
          callback: (value: number) => `${value < 0 ? '-' : ''}${currency}${formatNumberWithK(Math.abs(value))}`, // No formatting for smaller numbers
        },
        title: {
          display: true,
          text: 'Revenue & Profit',
          color: '#fff',
          fontWeight: '500',
          fontSize: '13px',
        },
      },
      y2: {
        position: 'right',
        beginAtZero: true,
        grid: {
          drawOnChartArea: false, // Hide grid lines for the secondary y-axis
        },
        ticks: {
          font: {
            size: 12, // Font size
            fontWeight: 400, // Font weight
          },
          color: '#C7C7C7', // Font color
          padding: 10, // Padding between the labels and the axis
          callback: (value: number) => `${value < 0 ? '-' : ''}${currency}${formatNumberWithK(Math.abs(value))}`, // No formatting for smaller numbers
        },
        title: {
          display: true,
          text: 'Cash Flow & Profit/Loss Cumulative',
          color: '#fff',
          fontWeight: 500,
          fontSize: 13,
        },
      },
    },
  };

  const data = {
    labels,
    datasets: [
      {
        type: 'bar' as const,
        label: 'Revenue',
        data: revenues,
        backgroundColor: '#2C55FB',
        borderRadius: 2, // Rounds the top corners of the bars
        borderWidth: 0,
        categoryPercentage: 0.7, // Set the category width percentage
        barPercentage: 0.7,
        yAxisID: 'y', // Assign to primary y-axis
        order: 2,
      },
      {
        type: 'bar' as const,
        label: 'Electricity Cost',
        data: electricityCost,
        backgroundColor: '#FEA049',
        borderRadius: 2, // Rounds the top corners of the bars
        borderWidth: 0,
        categoryPercentage: 0.7, // Set the category width percentage
        barPercentage: 0.7,
        yAxisID: 'y', // Assign to primary y-axis
        order: 2,
      },
      {
        type: 'bar' as const,
        label: 'Profit',
        data: profits,
        backgroundColor: '#49E3FE',
        categoryPercentage: 0.7, // Set the category width percentage
        barPercentage: 0.7,
        borderRadius: 2, // Rounds the top corners of the bars
        borderWidth: 0,
        yAxisID: 'y', // Assign to primary y-axis
        order: 2,
      },
      {
        type: 'line' as const,
        label: 'Profit/Loss (Cumulative)',
        data: cashFlows,
        backgroundColor: '#00C314',
        segment: {
          backgroundColor: (context: {
            p0: any;
            p1: any;
            raw: any; }) => {
            const value1 = context.p0.raw;
            return value1 <= 0 ? '#F50000' : '#00C314'; // Customize colors based on value
          },

          borderColor: (context: {
            p0: any;
            p1: any;
            raw: any; }) => {
            const value1 = context.p0.raw;
            return value1 <= 0 ? '#F50000' : '#00C314'; // Customize colors based on value
          },
        },
        borderWidth: 3,
        pointRadius: 0, // Remove dots
        fill: false,
        yAxisID: 'y2', // Assign to secondary y-axis
        titleID: 'title2',
        order: 1,
      },
      {
        type: 'line' as const,
        label: 'Cash Flow (Cumulative)',
        data: cashFlows,
        segment: {
          backgroundColor: (context: {
            p0: any;
            raw: any; }) => {
            const value = context.p0.raw;
            return value <= 0 ? '#F5000033' : '#00C31433'; // Customize colors based on value
          },
        },
        borderColor: 'transparent',
        borderWidth: 3,
        pointRadius: 0, // Remove dots
        fill: true,
        yAxisID: 'y2', // Assign to secondary y-axis
        titleID: 'title2',
        order: 1,
      },
    ],
  };

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return <Chart style={{ width: '100%' }} className={`w-full ${className || ''}`} type="bar" options={options} data={data} />;
};
