import { useContext } from 'react';

import { httpRequest } from '../../services';
import { InvoicesActionTypes, InvoicesContext } from '../contexts';

import {
  getInvoiceAPI,
  getProjectAPI,
  RequestTypes,
} from '../../constants';
import { InvoiceInterface } from '../../types/invoices';
import { HttpRequestHeaderTypes, InvoiceDataProps } from '../../services/httpRequest';

export const InvoiceHandler = () => {
  const { updateInvoicesState } = useContext(InvoicesContext);

  const getInvoices = async () => {
    const response = await httpRequest({
      url: getInvoiceAPI().INVOICES,
      method: RequestTypes.Get,
    });

    const sortedInvoices = (response.data as InvoiceInterface[]).sort((a, b) => Number(a.projectId) - Number(b.projectId));

    updateInvoicesState({ type: InvoicesActionTypes.SetInvoices, payload: sortedInvoices });

    return response.data;
  };

  const generateInvoice = async (id: string | number, data: InvoiceDataProps) => httpRequest({
    url: getProjectAPI(id).PROJECT_INVOICE_GENERATE,
    method: RequestTypes.Post,
    data,
    headerType: HttpRequestHeaderTypes.MultiPart,
  });

  const downloadInvoice = async (id: string) => {
    const response = await httpRequest({
      url: getInvoiceAPI(id).INVOICES_DOWNLOAD,
      method: RequestTypes.Get,
    });
    if (response.data.downloadUrl) window.open(response.data.downloadUrl, '_blank', 'noopener');

    return response.data;
  };

  const getInvoiceHistory = async () => httpRequest({
    url: getInvoiceAPI().INVOICES_HISTORY,
    method: RequestTypes.Get,
  });

  return ({
    getInvoices,
    generateInvoice,
    downloadInvoice,
    getInvoiceHistory,
  });
};
