import SumsubWebSdk from '@sumsub/websdk-react';
import { useSearchParams } from 'react-router-dom';
import { AppElement, AppFC } from '../../../interfaces';

export const SumsubKYC: AppFC = (): AppElement => {
  const [searchParams] = useSearchParams();

  const token = searchParams.get('token');

  if (token) {
    return (
      <div className="w-full flex flex-col justify-center h-full">
        <SumsubWebSdk
          accessToken={token}
          expirationHandler={() => new Promise(() => {})}
          onMessage={(value: any) => { console.log('onMessage:', value); }}
          onError={(value: any) => { console.log('onError:', value); }}
          className="h-full"
        />
      </div>
    );
  }

  return <></>;
};
