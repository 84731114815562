import React, { useContext, useState } from 'react';
import { FieldErrors, SubmitHandler, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import { ProjectsContext, ViewContext, ViewModalActionTypes } from '../../../../../stores';
// import { ProjectHandler } from '../../../../../stores/handlers';

import { AppElement, AppFC } from '../../../../../interfaces';
import { WithdrawFundsCryptoDataProps, WithdrawFundsBankDataProps } from './types';
import { ButtonCategory, CurrencyTypes } from '../../../../../constants';
import {
  getFormattedAmount,
  // getNetworkForWithdraw
} from '../../../../../utils';

import { AppInput } from '../../../../Molecules';
import { Button } from '../../../../Atoms';

export const WithdrawFunds: AppFC = ({ className }): AppElement => {
  const [
    isLoading,
    // setIsLoading
  ] = useState(false);
  // const [alert, setAlert] = useState<AppElement>();
  const { viewModal: { info }, updateViewModal } = useContext(ViewContext);
  const { projectsState: { whitelistedAddresses } } = useContext(ProjectsContext);

  // const { withdrawByCrypto, withdrawByBank } = ProjectHandler();

  const { selectedAccount } = info || {};
  const isBank = selectedAccount?.currency === CurrencyTypes.EUR;

  // const amountCurrency = selectedAccount?.availableBalance.currency;
  const availableBalance = getFormattedAmount(selectedAccount?.availableBalance.amount, selectedAccount?.availableBalance.currency, 1);

  const cryptoCurrencySchema = {
    amount: yup
      .number().max(availableBalance)
      .required('Please enter the amount you need to withdraw.'),
    blockchainAddress: yup
      .string()
      .required('Please enter your blockchain deposit address.'),
  };

  const bankCurrencySchema = {
    amount: yup
      .number().max(availableBalance)
      .required('Please enter the amount you need to withdraw.'),
    iban: yup
      .string()
      .required('IBAN is required'),
    bic: yup
      .string()
      .required('BIC is required'),
  };

  let blockchainDefaultAddress = '';

  if (whitelistedAddresses && selectedAccount?.currency) {
    blockchainDefaultAddress = whitelistedAddresses[selectedAccount.currency]?.slice(-1)[0] || '';
  }

  const currentSchema = isBank ? bankCurrencySchema : cryptoCurrencySchema;
  const validationSchema = yup.object()
    .shape(currentSchema);

  const defaultValues = isBank
    ? {
      iban: '',
      bic: '',
    }
    : {
      blockchainAddress: blockchainDefaultAddress,
    };

  const financeSourceName = isBank ? 'bank' : 'wallet';

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: defaultValues as WithdrawFundsBankDataProps | WithdrawFundsCryptoDataProps,
  });

  const onSubmit: SubmitHandler<WithdrawFundsCryptoDataProps | WithdrawFundsBankDataProps> = (data) => {
    console.log('withdraw data', data);
    // setIsLoading(true);
    // if (isBank) {
    //   withdrawByBank({
    //     eurAccountId: selectedAccount?.accountId,
    //     amount: getFormattedAmount((data as WithdrawFundsCryptoDataProps).amount, amountCurrency, -1).toString(),
    //     iban: (data as WithdrawFundsBankDataProps).iban,
    //     bic: (data as WithdrawFundsBankDataProps).bic,
    //   }).then((res) => {
    //     updateViewModal({
    //       type: ViewModalActionTypes.TxConfirmModal,
    //       payload: {
    //         isWithdrawal: true,
    //         info: {
    //           challengeId: res.data.challengeId,
    //         },
    //       },
    //     });
    //   }).catch(() => {
    //     updateViewModal({
    //       type: ViewModalActionTypes.FailModal,
    //       payload: {
    //         title: 'Funds Withdrawal Failed',
    //       },
    //     });
    //   });
    // } else {
    //   const network = getNetworkForWithdraw(selectedAccount?.currency);
    //   if (selectedAccount) {
    //     withdrawByCrypto({
    //       sourceAccountId: selectedAccount.accountId,
    //       currency: selectedAccount.currency,
    //       address: (data as WithdrawFundsCryptoDataProps).blockchainAddress,
    //       amount: getFormattedAmount((data as WithdrawFundsCryptoDataProps).amount, amountCurrency, -1).toString(),
    //       network,
    //     }).then((res) => {
    //       updateViewModal({
    //         type: ViewModalActionTypes.TxConfirmModal,
    //         payload: {
    //           isWithdrawal: true,
    //           info: {
    //             challengeId: res.data.challengeId,
    //           },
    //         },
    //       });
    //     }).catch(() => {
    //       updateViewModal({
    //         type: ViewModalActionTypes.FailModal,
    //         payload: {
    //           title: 'Funds Withdrawal Failed',
    //         },
    //       });
    //     });
    //   }
    // }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={`flex flex-col items-center my-auto ${className}`}>
      {/* {alert} */}
      <h4>Withdrawal</h4>
      <p className="mt-2 text-center max-w-md">{`Provide your ${financeSourceName} details to withdraw funds from Pantheon Mining Portal`}</p>
      {!isBank ? (
        <div className="relative flex flex-col gap-8 w-full max-w-[500px] mt-8">
          <h5 className={`absolute top-0 right-0 text-[14px] ${errors.amount ? 'text-error' : ''}`}>{`Available Balance: ${availableBalance.toFixed(4)} ${selectedAccount?.currency}`}</h5>
          <div className="relative">
            <Button
              type="button"
              className={`absolute text-[13px] font-bold bottom-0 right-5 p-0 z-50 ${errors.amount ? 'text-error' : ''}`}
              onClick={() => { setValue('amount', availableBalance); }}
            >
              MAX
            </Button>
            <AppInput
              label="Amount"
              name="amount"
              control={control}
              placeholder="0.00"
              error={(errors as FieldErrors<WithdrawFundsCryptoDataProps>).amount}
            />
          </div>
          <AppInput
            label="Your blockchain deposit address"
            name="blockchainAddress"
            control={control}
            placeholder="Deposit address"
            error={(errors as FieldErrors<WithdrawFundsCryptoDataProps>).blockchainAddress}
          />
        </div>
      ) : (
        <div className="relative flex flex-col gap-8 w-full max-w-[500px] mt-8">
          <h5 className={`absolute top-0 right-0 text-[14px] ${errors.amount ? 'text-error' : ''}`}>{`Available Balance:${availableBalance} ${selectedAccount?.currency}`}</h5>
          <div className="relative">
            <Button
              type="button"
              className={`absolute text-[13px] font-bold bottom-0 right-5 p-0 z-50 ${errors.amount ? 'text-error' : ''}`}
              onClick={() => { setValue('amount', availableBalance); }}
            >
              MAX
            </Button>
            <AppInput
              className="[&>*input]:pr-7"
              label="Amount"
              name="amount"
              control={control}
              placeholder="0.00"
              error={(errors as FieldErrors<WithdrawFundsBankDataProps>).amount}
            />
          </div>
          <AppInput
            label="IBAN"
            name="iban"
            control={control}
            placeholder="iban"
            error={(errors as FieldErrors<WithdrawFundsBankDataProps>).iban}
          />
          <AppInput
            label="BIC"
            name="bic"
            control={control}
            placeholder="bic"
            error={(errors as FieldErrors<WithdrawFundsBankDataProps>).bic}
          />
        </div>
      )}
      <div className="w-full flex justify-center gap-2 mt-6 max-sm:flex-col">
        <Button className="sm:w-[200px] sm:mt-7" category={ButtonCategory.Outlined} onClick={() => updateViewModal({ type: ViewModalActionTypes.Close })}>Cancel</Button>
        <Button type="submit" className="sm:w-[200px] sm:mt-7" category={ButtonCategory.Filled} isLoading={isLoading}>Submit</Button>
      </div>
    </form>
  );
};
