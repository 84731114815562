import moment from 'moment';
import {
  btcPriceIncrements,
  difficultyIncrement,
  nextHalvingDates,
  failure,
  offline,
  ScenarioTypes,
} from '../constants/calculator';

/**
 * The function calculates compound percent
 * for any amount of month
 * by initially given scenario with different yearly percentages
 * or by constant yearly percentage.
 * Returns the value of the period's last month
 *
 * @param initialValue -> start value
 * @param period -> months
 * @param options -> contain constant yearly percentage and scenario with different yearly percentages
 */
export const calculateCompoundPercentValue = (
  initialValue: number,
  period: number,
  options:{
    yearlyPercentage?: number,
    halvingMonths?: number[],
    scenario?: ScenarioTypes
  },
): number => {
  const { yearlyPercentage, scenario, halvingMonths } = options;
  let currentYearlyPercentage = yearlyPercentage as number;

  if (period <= 0) {
    return initialValue;
  }

  if (scenario) {
    const yearIndex = Math.trunc(period / 12);
    currentYearlyPercentage = btcPriceIncrements[scenario][yearIndex];
  }

  return ((1 + (currentYearlyPercentage / 12)) * calculateCompoundPercentValue(initialValue, period - 1, options)) / (halvingMonths?.includes(period) ? 2 : 1);
};

export const btcHalvingMonths = (): number[] => {
  const halvingMonths: number[] = [];

  nextHalvingDates.forEach((halvingDate) => {
    const difference = moment(new Date(halvingDate)).diff(new Date(), 'months', true);
    if (difference >= 0) {
      halvingMonths.push(Math.round(difference));
    }
  });
  return halvingMonths;
};

export const getCumulativeRevenue = (holdPeriod: number, spotsAmount: number, scenario: ScenarioTypes, btcPrice: number, btcProductionPerMiner: number): number => {
  const halvingMonths = btcHalvingMonths();
  const btcProductionPerMonth = calculateCompoundPercentValue(btcProductionPerMiner, holdPeriod, { yearlyPercentage: -difficultyIncrement, halvingMonths }) * spotsAmount;
  const btcPriceInMonth = calculateCompoundPercentValue(btcPrice, holdPeriod, { scenario });
  const btcProductionCostPerMonth = btcProductionPerMonth * btcPriceInMonth;
  const revenuePerMonth = btcProductionCostPerMonth * (1 - failure - offline);

  if (holdPeriod < 0) {
    return 0;
  }

  if (holdPeriod === 0) {
    return revenuePerMonth;
  }
  return revenuePerMonth + getCumulativeRevenue(holdPeriod - 1, spotsAmount, scenario, btcPrice, btcProductionPerMiner);
};

export const calculateIRR = (
  cashFlows: number[],
  guess: number = 0.1,
  tolerance: number = 1e-6,
  maxIterations: number = 1000,
): number => {
  const calculateNPV = (rate: number, cashFlowsYearly: number[]): number => cashFlowsYearly.reduce(
    (npvValue, cashFlow, i) => npvValue + cashFlow / (1 + rate) ** i,
    0,
  );

  const calculateDerivative = (rate: number, cashFlowsYearly: number[]): number => cashFlowsYearly.reduce(
    (derivative, cashFlow, j) => derivative - (j * cashFlow) / (1 + rate) ** (j + 1),
    0,
  );

  const iterate = (
    rate: number,
    iteration: number,
  ): number => {
    if (iteration >= maxIterations) {
      return rate;
      // throw new Error('IRR calculation did not converge');
    }

    const npvValue = calculateNPV(rate, cashFlows);
    if (Math.abs(npvValue) < tolerance) {
      return rate;
    }

    const derivative = calculateDerivative(rate, cashFlows);

    if (Math.abs(derivative) < tolerance) {
      throw new Error('Derivative is too small, leading to potential division by zero.');
    }

    const newRate = rate - npvValue / derivative;

    return iterate(newRate, iteration + 1);
  };

  return iterate(guess, 0);
};
export const calculateTotalBtcProductionForPeriod = (period: number, minerAmount: number, btcProductionPerMiner: number): number => {
  const halvingMonths = btcHalvingMonths();
  let currentPeriod = period;
  let totalBtcAmount = 0;

  while (currentPeriod >= 0) {
    const currentMonthBtcAmount = calculateCompoundPercentValue(btcProductionPerMiner, currentPeriod, { yearlyPercentage: -difficultyIncrement, halvingMonths });
    // console.log('currentMonthBtcAmount', currentMonthBtcAmount, currentPeriod, halvingMonths);
    totalBtcAmount += currentMonthBtcAmount * minerAmount;
    currentPeriod -= 1;
  }

  return totalBtcAmount;
};

export const getIncomesYearly = (
  holdPeriod: number,
  scenario: ScenarioTypes,
  btcPrice: number,
  initialInvestment: number,
  yearlyElectricityCost: number,
  minerAmount: number,
  btcProductionPerMiner:number,
): number[] => {
  const numberOfYears = Math.trunc(holdPeriod / 12);

  const calculateYearlyIncome = (year: number, previousBtc: number = 0, incomes: number[] = [-initialInvestment]): number[] => {
    if (year > numberOfYears) {
      return incomes;
    }

    const totalBtcProduction = calculateTotalBtcProductionForPeriod(year * 12 - 1, minerAmount, btcProductionPerMiner);
    const btcPriceInMonth = calculateCompoundPercentValue(btcPrice, year * 12 - 1, { scenario });

    const yearlyBtc = totalBtcProduction - previousBtc;
    const yearlyIncome = yearlyBtc * btcPriceInMonth - yearlyElectricityCost;

    return calculateYearlyIncome(year + 1, totalBtcProduction, [...incomes, yearlyIncome]);
  };

  return calculateYearlyIncome(1);
};

export const getBuyingBtcAmount = (
  initialInvestment: number,
  initialBtcPrice: number,
  yearlyCost: number,
  scenario: ScenarioTypes,
  period: number,
): number => {
  if (period <= 0) {
    return initialInvestment / initialBtcPrice;
  }

  const btcPriceInMonth = calculateCompoundPercentValue(initialBtcPrice, period, { scenario });
  return getBuyingBtcAmount(
    initialInvestment,
    initialBtcPrice,
    yearlyCost,
    scenario,
    period - 12,
  ) + (yearlyCost / btcPriceInMonth);
};

export const calculateMonthlyBTCProduction = (
  minerHashRate: number,
  totalNetworkHashRate: number,
  blockReward: number,
  blocksPerMonth: number,
): number => {
  const proportionOfHashRate = minerHashRate / totalNetworkHashRate;
  const totalMonthlyRewards = blocksPerMonth * blockReward;
  return proportionOfHashRate * totalMonthlyRewards;
};
