import React, { useContext, useState } from 'react';
import OtpInput from 'react-otp-input';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import {
  ProfileContext, ViewContext,
  // ViewModalActionTypes
} from '../../../../../stores';
// import { ProjectHandler } from '../../../../../stores/handlers';

import { AppElement, AppFC } from '../../../../../interfaces';
import { TxConfirmProps } from './types';
import {
  // AlertCategory,
  ButtonCategory,
  // getProjectAPI,
  // RequestTypes,
  // FINANCE_API,
} from '../../../../../constants';

import {
  // Alert,
  Button, ErrorMessage, Label,
} from '../../../../Atoms';
// import { httpRequest } from '../../../../../services';
// import { generateErrorAlert } from '../../../../../utils';

const validationSchema = yup.object().shape({
  otp: yup
    .string()
    .required('Please enter verification code'),
});
export const TxConfirmOTP: AppFC = ({ className }): AppElement => {
  const [isLoading,
    // setIsLoading
  ] = useState(false);
  const [isConfirmLoading,
    // setIsVerifyLoading
  ] = useState(false);
  const {
    viewModal: {
      info,
    // isWithdrawal
    },
    // updateViewModal
  } = useContext(ViewContext);
  const { profileState: { phone } } = useContext(ProfileContext);
  const [alert,
    // setAlert
  ] = useState<AppElement>();

  // const {
  //   PROJECT_CONFIRM_TRANSACTION_RESEND,
  //   PROJECT_CONFIRM_TRANSACTION,
  // } = getProjectAPI(info?.projectId as string);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      otp: '',
    },
  });

  const {
    // spotsAmount,
    // projectName,
    challengeId,
    // projectId,
    // invoiceId,
  } = info || {};
  // const { updateProjectSpots } = ProjectHandler();

  const handleResendOTP = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();

    // setIsLoading(true);
    // httpRequest({
    //   data: {
    //     challengeId: challengeId as string,
    //   },
    //   url: PROJECT_CONFIRM_TRANSACTION_RESEND,
    //   method: RequestTypes.Post,
    // }).then(() => {
    //   setIsLoading(false);
    //   setAlert(
    //     <Alert
    //       className="-top-5 w-[230px]"
    //       category={AlertCategory.Success}
    //       title="New OTP sent!"
    //     />,
    //   );
    //   setTimeout(() => {
    //     setAlert(null);
    //   }, 7000);
    // }).catch((reason) => {
    //   generateErrorAlert(
    //     reason,
    //     'OTP resend failed!',
    //     setAlert,
    //     '-top-5 w-[230px]',
    //   );
    //   setIsLoading(false);
    // });
  };

  const onSubmit: SubmitHandler<TxConfirmProps> = (data) => {
    if (challengeId) {
      console.log(challengeId, data);
      // setIsVerifyLoading(true);
      /** Investment flow - 5.Confirm transaction by OTP * */
      // const requestData = isWithdrawal ? {
      //   challengeId: challengeId as string,
      //   verificationCode: data.otp,
      // } : {
      //   challengeId: challengeId as string,
      //   verificationCode: data.otp,
      //   invoiceId,
      // };
      // httpRequest({
      //   url: isWithdrawal ? FINANCE_API.TRANSACTION_CONFIRM : PROJECT_CONFIRM_TRANSACTION,
      //   method: RequestTypes.Post,
      //   data: requestData,
      // }).then(() => {
      //   if (isWithdrawal) {
      //     updateViewModal({
      //       type: ViewModalActionTypes.SuccessModal,
      //       payload: {
      //         title: 'Funds Successfully Withdrawn',
      //       },
      //     });
      //   } else {
      //     updateProjectSpots(projectId as string, { spots: Number(spotsAmount) })
      //       .then(() => {
      //         updateViewModal({
      //           type: ViewModalActionTypes.SuccessModal,
      //           payload: {
      //             title: 'Payment success',
      //             description: `You have just purchased ${spotsAmount} miners in ${projectName} Project`,
      //             isClosable: false,
      //           },
      //         });
      //       });
      //   }
      // }).catch((reason) => {
      //   setIsVerifyLoading(false);
      //   if (isWithdrawal) {
      //     updateViewModal({
      //       type: ViewModalActionTypes.FailModal,
      //       payload: {
      //         title: 'Funds Withdrawal Failed',
      //       },
      //     });
      //   } else {
      //     const childElement = reason.response.data.ip && (
      //     <div className="flex gap-3 flex-wrap py-4 px-4 border-t border-t-neutral-500">
      //       <div><b>Supported countries:</b></div>
      //       <div className="flex justify-between w-full pl-4">
      //         <ul className="list-disc">
      //           <li>Austria</li>
      //           <li>Belgium</li>
      //           <li>Bulgaria</li>
      //           <li>Croatia</li>
      //           <li>Cyprus</li>
      //           <li>Czech Republic</li>
      //           <li>Denmark</li>
      //           <li>Estonia</li>
      //           <li>Finland</li>
      //           <li>France</li>
      //           <li>Germany</li>
      //           <li>Greece</li>
      //           <li>Hungary</li>
      //           <li>Ireland</li>
      //           <li>Italy</li>
      //         </ul>
      //
      //         <ul className="list-disc">
      //           <li>Latvia</li>
      //           <li>Lithuania</li>
      //           <li>Luxembourg</li>
      //           <li>Malta</li>
      //           <li>Netherlands</li>
      //           <li>Poland</li>
      //           <li>Portugal</li>
      //           <li>Romania</li>
      //           <li>Slovakia</li>
      //           <li>Slovenia</li>
      //           <li>Spain</li>
      //           <li>Sweden</li>
      //           <li>Liechtenstein</li>
      //           <li>Norway</li>
      //           <li>Iceland</li>
      //         </ul>
      //       </div>
      //     </div>
      //     );
      //     generateErrorAlert(
      //       reason,
      //       'Payment Failed!',
      //       setAlert,
      //       '-top-5 z-50 flex flex-col py-6 px-5 xl:w-[125%]',
      //       true,
      //       () => {
      //         setAlert(null);
      //       },
      //       childElement,
      //     );
      //   }
      // });
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={`relative h-full flex flex-col items-center justify-center max-w-[360px] ${className}`}>
      {alert}
      <h4 className="mb-5">Confirm OTP</h4>
      <p className="text-center mb-6">{`We've sent a six-digit verification code to ${phone} phone number`}</p>
      <Label>
        <Controller
          name="otp"
          control={control}
          render={({
            field: {
              name,
              value,
              onChange,
            },
          }) => (
            <OtpInput
              containerStyle={{ justifyContent: 'center', marginTop: '44px' }}
              value={value}
              onChange={onChange}
              numInputs={6}
              inputStyle={{
                width: '44px',
                height: '44px',
                borderRadius: '8px',
                background: 'transparent',
              }}
              renderSeparator={<span className="w-2" />}
              renderInput={(props) => <input name={name} {...props} className={`otp-input ${errors.otp ? 'otp-input-error' : 'otp-input-default'} `} />}
            />
          )}
        />
        {errors.otp && <ErrorMessage className="-bottom-7 right-7 w-full text-center">{errors.otp.message}</ErrorMessage>}
      </Label>
      <Button className="mt-[3.5rem] w-full" category={ButtonCategory.Filled} isLoading={isConfirmLoading}>Confirm</Button>
      <div className="w-full mt-11 flex items-center justify-center gap-1 font-semibold text-[13px]">
        <span>
          Didn&apos;t receive the code?
        </span>
        <Button className="px-1" isLoading={isLoading} onClick={handleResendOTP}>
          <h5 className="text-[13px] text-blue underline">Resend</h5>
        </Button>
      </div>

    </form>
  );
};
