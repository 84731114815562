import { City, Country, State } from 'country-state-city';
import country from 'i18n-iso-countries';
import { CurrencyTypes } from '../constants';
import { ProjectInterface } from '../types/projects';

export const getState = (countryCode?: string) => {
  const states = countryCode ? State.getStatesOfCountry(countryCode) : [];

  return states.map(({ name }) => ({
    value: name,
    label: name,
  }));
};

export const getCountries = (format?: string) => {
  const countries = Country.getAllCountries();
  if (format === 'alpha3') {
    return countries.map(({
      isoCode,
      name,
    }) => ({
      value: country.alpha2ToAlpha3(isoCode) || isoCode,
      label: name,
    }));
  }
  return countries.map(({
    isoCode,
    name,
  }) => ({
    value: isoCode,
    label: name,
  }));
};

export const getCities = (countryCode: string, stateName?: string) => {
  let cities = City.getCitiesOfCountry(countryCode) || [];
  if (stateName) {
    const state = State.getStatesOfCountry(countryCode)
      .find(({ name }) => name === stateName);
    if (state) {
      cities = City.getCitiesOfState(countryCode, state.isoCode);
    }
  }

  return cities.map(({ name }) => ({
    value: name,
    label: name,
  }));
};

export const getCurrencyOptions = (currency: CurrencyTypes) => {
  switch (currency) {
    case CurrencyTypes.EUR:
      return [];
    case CurrencyTypes.BTC:
      return [
        {
          value: 'USDT',
          label: 'USDT',
        },
        {
          value: 'USDC',
          label: 'USDC',
        },
        {
          value: 'EUR',
          label: 'EUR',
        },
      ];
    case CurrencyTypes.USDC:
      return [
        {
          value: 'USDT',
          label: 'USDT',
        },
        {
          value: 'EUR',
          label: 'EUR',
        },
      ];
    default:
      return [{
        value: 'EUR',
        label: 'EUR',
      }];
  }
};

export const getProjectOptions = (projects: ProjectInterface[]) => projects.map(({ id, title }) => ({
  value: id,
  label: title,
}));
export const getProjectMinersOptions = (project?: ProjectInterface) => {
  if (project) {
    return [project.minerModel].map((model) => ({
      value: model,
      label: model,
    }));
  }
  return [];
};
export const getProjectCurrencyOptions = () => [{
  value: 'USD',
  label: 'USD',
},
{
  value: 'EUR',
  label: 'EUR',
},
];
