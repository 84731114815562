import { createContext, useMemo, useReducer } from 'react';

import { AppElement, AppFC } from '../../../interfaces';
import { ContextProviderPropsInterface, UseReducerType } from '../../interfaces';
import { ViewContextInterface, ViewDeviceInterface } from './interfaces';

import {
  ViewHeaderActionType,
  viewHeaderInitialState,
  ViewHeaderInterface,
  viewHeaderReducer,
} from './viewHeaderContext';

import {
  ViewLoaderActionType,
  viewLoaderInitialState,
  ViewLoaderInterface,
  viewLoaderReducer,
} from './viewLoaderContext';

import {
  ViewModalActionType,
  viewModalInitialState,
  ViewModalInterface,
  viewModalReducer,
} from './viewModalContext';

export const ViewContext = createContext<ViewContextInterface>({} as ViewContextInterface);

export const ViewContextProvider: AppFC<ContextProviderPropsInterface> = ({ children }): AppElement => {
  const [viewLoader, updateViewLoader] = useReducer<
  UseReducerType<ViewLoaderInterface, ViewLoaderActionType>
  >(viewLoaderReducer, viewLoaderInitialState);

  const [viewModal, updateViewModal] = useReducer<
  UseReducerType<ViewModalInterface, ViewModalActionType>
  >(viewModalReducer, viewModalInitialState);

  const [viewHeader, updateViewHeader] = useReducer<
  UseReducerType<ViewHeaderInterface, ViewHeaderActionType>
  >(viewHeaderReducer, viewHeaderInitialState);

  const isIOS = /iPad|iPhone|iPod|i/.test(navigator.userAgent);
  const isAndroid = /Android|Mobile/.test(navigator.userAgent);
  const isChrome = /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.userAgent);

  const viewDevice: ViewDeviceInterface = {
    isIOS,
    isChrome,
    android: isAndroid,
    desktop: false,
  };

  const value = useMemo(() => ({
    viewModal,
    updateViewModal,
    viewLoader,
    updateViewLoader,
    viewHeader,
    updateViewHeader,
    viewDevice,
  }), [viewModal, viewLoader, viewHeader, viewDevice]);

  return (
    <ViewContext.Provider value={value}>
      {children}
    </ViewContext.Provider>
  );
};
