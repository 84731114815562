import { AppElement, AppFC } from '../../../interfaces';
import { ButtonProps } from './types';
import { ButtonCategory } from '../../../constants';
import { Loader } from '../Loader';

export const Button: AppFC<ButtonProps> = ({
  className,
  category,
  isLoading,
  children,
  disabled,
  isReverse,
  ...rest
}): AppElement => {
  let classes = '';
  if (category === ButtonCategory.Light) {
    classes = `bg-white border border-white text-black ${disabled ? 'opacity-40' : 'hover:bg-white/80'}`;
  }
  if (category === ButtonCategory.Outlined) {
    classes = `bg-transparent border ${!isReverse ? 'border-white text-white' : 'border-black text-black'} ${disabled ? 'opacity-40' : `${!isReverse ? 'hover:bg-white hover:text-black' : 'hover:bg-black hover:text-white'}`}`;
  }
  if (category === ButtonCategory.Filled) {
    classes = `bg-buttonFilled text-white ${disabled ? 'opacity-40' : 'hover:bg-buttonFilled/80'}`;
  }
  if (category === ButtonCategory.Default) {
    classes = `bg-default text-white  ${disabled ? 'opacity-40' : 'hover:bg-default/80'}`;
  }
  return (
    <button {...rest} className={`${classes} ${className} rounded-lg`} disabled={isLoading || disabled}>
      {isLoading ? <Loader className="w-6 h-6" /> : children}
    </button>
  );
};
