import { Controller } from 'react-hook-form';

import React from 'react';
import { AppElement, AppFC } from '../../../interfaces';
import { AppInputProps } from './types';

import checkedIcon from '../../../assets/icons/checked.svg';

export const AppCheckBox: AppFC<AppInputProps> = ({
  name,
  label,
  control,
  className,
  error,
  // ...rest
}): AppElement => (
  <label className={`relative w-full flex items-center gap-2 cursor-pointer ${className || ''}`}>
    <Controller
      name={name}
      control={control}
      render={({ field }) => {
        const checked = field.value;
        return (
          <div className={`relative flex items-center justify-center gap-2 cursor-pointer w-4 h-4 rounded-[0.25rem] ${checked ? 'bg-buttonFilled' : 'border border-default'}`}>
            <input
              className="hidden"
              type="checkbox"
              checked={checked}
              {...field}
            />
            {checked && <img src={checkedIcon} alt="" />}
          </div>
        );
      }}
    />
    {!!label && (
    <span className="relative flex-1">
      {label}
      {!!error && <p className="absolute w-full text-end text-[10px] mt-1 text-error">{error.message}</p>}
    </span>
    )}
  </label>
);
