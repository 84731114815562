import { useMemo, useReducer, createContext } from 'react';

import { invoicesReducer } from './reducer';
import { AppElement, AppFC } from '../../../interfaces';
import {
  ContextProviderPropsInterface,
  UseReducerType,
} from '../../interfaces';
import {
  InvoicesActionType,
  InvoicesContextInterface,
} from './interfaces';

import { invoicesInitialState } from './initialState';
import { InvoicesInterface } from '../../../types/invoices';

export const InvoicesContext = createContext<InvoicesContextInterface>({} as InvoicesContextInterface);

export const InvoicesContextProvider: AppFC<ContextProviderPropsInterface> = ({ children }): AppElement => {
  const [invoicesState, updateInvoicesState] = useReducer<
  UseReducerType<InvoicesInterface, InvoicesActionType>
  >(invoicesReducer, invoicesInitialState);

  const value = useMemo(() => ({
    invoicesState,
    updateInvoicesState,
  }), [invoicesState]);

  return (
    <InvoicesContext.Provider value={value}>{children}</InvoicesContext.Provider>
  );
};
