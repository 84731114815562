export enum ViewModalActionTypes {
  KYCModal = 'KYCModal',
  AccountDetailsModal = 'AccountDetailsModal',
  QuotationDetailsModal = 'QuotationDetailsModal',
  DocusignModal = 'DocusignModal',
  SumsubModal = 'SumsubModal',
  TxConfirmModal = 'TxConfirmModal',
  InvestmentModal = 'InvestmentModal',
  SuccessModal = 'SuccessModal',
  FailModal = 'FailModal',
  PasswordChangeModal = 'PasswordChangeModal',
  BitcoinAddressModal = 'BitcoinAddressModal',
  BankingAccessInfoModal = 'BankingAccessInfoModal',
  BankingWithPassedKYCModal = 'BankingWithPassedKYCModal',
  BankingAccessFormModal = 'BankingAccessFormModal',
  WithdrawModal = 'WithdrawModal',
  ExchangeModal = 'ExchangeModal',
  QrCodeModal = 'QrCodeModal',
  DefaultModal = 'DefaultModal',
  Close = 'Close',
}
