import { KYCStatusTypes } from '../constants';
import { ReviewResultInterface } from '../stores/contexts';

export const getStatus = (KYCStatus: KYCStatusTypes, reviewResult: ReviewResultInterface) => {
  const { reviewAnswer, reviewRejectType } = reviewResult || {} as ReviewResultInterface;
  if (KYCStatus) {
    if (
      KYCStatus === KYCStatusTypes.SUMSUB_PENDING
        || KYCStatus === KYCStatusTypes.SUMSUB_PRECHECKED
        || KYCStatus === KYCStatusTypes.SUMSUB_QUEUED
        || KYCStatus === KYCStatusTypes.SUMSUB_ON_HOLD
    ) {
      return KYCStatusTypes.ON_HOLD;
    }

    if (reviewAnswer === 'GREEN') {
      return KYCStatusTypes.APPROVED;
    }

    if (reviewAnswer === 'RED' && reviewRejectType === 'RETRY') {
      return KYCStatusTypes.REJECTED;
    }

    if (reviewAnswer === 'RED' && reviewRejectType === 'FINAL') {
      return KYCStatusTypes.REJECTED_FINAL;
    }

    return KYCStatusTypes.INITIATED;
  } return null;
};
