import React, { useContext } from 'react';
import { NavLink } from 'react-router-dom';

import { httpRequest } from '../../../../services';

import { ProfileContext, ViewContext, ViewModalActionTypes } from '../../../../stores';
import { ReviewResultInterface } from '../../../../stores/contexts';

import { AppElement, AppFC } from '../../../../interfaces';
import { HeaderProps } from './types';
import {
  ButtonCategory,
  KYCStatusTypes,
  Paths,
  PROFILE_API,
  RequestTypes,
} from '../../../../constants';
import { getStatus } from '../../../../utils';

import { HamburgerIcon, PantheonIcon } from '../../../Atoms/icons';
import { Button } from '../../../Atoms';
import { Menu } from './Menu';

import kycSuccess from '../../../../assets/icons/kycSuccess.svg';

export const Header: AppFC<HeaderProps> = ({
  menuIsOpen,
  walletMenuIsOpen,
  toggleMenu,
  toggleWalletMenu,
  sidebarIsOpen,
  toggleSidebar,
  className,
}): AppElement => {
  const { POST_KYC_START } = PROFILE_API;
  const {
    profileState: {
      profilePicture,
      // wallet,
      KYCStatus,
      reviewResult,
      firstName,
      lastName,
    },
  } = useContext(ProfileContext);

  const {
    viewModal: {
      info,
    },
    updateViewModal,
  } = useContext(ViewContext);

  const status = getStatus(KYCStatus as KYCStatusTypes, reviewResult as ReviewResultInterface);

  const kycStart = () => {
    httpRequest({
      data: {},
      url: POST_KYC_START,
      method: RequestTypes.Post,
    })
      .then((res) => {
        if (res.data.token) {
          updateViewModal({
            type: ViewModalActionTypes.SumsubModal,
            payload: {
              isClosable: true,
              isReload: true,
              info: {
                ...info,
                url: `${window.location.origin}${Paths.SumsubKYC}?token=${res.data.token}`,
              },
            },
          });
        }
        if (res.data.verificationLink) {
          updateViewModal({
            type: ViewModalActionTypes.SumsubModal,
            payload: {
              isClosable: true,
              isReload: true,
              info: {
                ...info,
                url: res.data.verificationLink,
              },
            },
          });
        }
      });
  };

  const handleKycClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    kycStart();
  };

  const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (walletMenuIsOpen) {
      toggleWalletMenu(false);
    }
    event.stopPropagation();
    toggleMenu(!menuIsOpen);
  };

  const handleSidebarClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    toggleSidebar(!sidebarIsOpen);
  };

  return (
    <>
      <div className={`relative w-full bg-transparent flex items-center justify-between h-20 max-lg:h-[4.5rem] ${className || ''}`}>
        <div className="flex items-center gap-4">
          <button
            className="lg:hidden p-0"
            onClick={handleSidebarClick}
          >
            <HamburgerIcon />
          </button>
          <NavLink to={Paths.Projects}>
            <PantheonIcon className="max-sm:w-32" />
          </NavLink>
        </div>
        <div
          className="flex items-center gap-4 text-gray-500"
        >
          {/* {strigaId && ( */}
          {/* <> */}
          {(status === KYCStatusTypes.INITIATED) && (
            <Button
              className="h-8 rounded-[8px] text-[13px] px-5 max-sm:hidden"
              category={ButtonCategory.Outlined}
              onClick={handleKycClick}
            >
              <h5>Start KYC</h5>
            </Button>
          )}
          <h5>
            {firstName}
            {' '}
            {lastName}
          </h5>
          <button
            className="p-0 relative h-auto"
            onClick={handleMenuClick}
          >
            {profilePicture ? <img className="rounded-[50%] object-cover bg-lightGray w-9 h-9" src={profilePicture} alt="User Name" />
              : (
                <div className="rounded-[50%] bg-filled w-9 h-9 flex items-center justify-center">
                  <h5>{firstName?.substring(0, 1)}</h5>
                </div>
              )}
            {status === KYCStatusTypes.APPROVED && <div className="absolute -top-0.5 -right-1.5 rounded-[50%] bg-white flex justify-center items-center"><img className="rounded-[50%] object-cover  w-[16px[ h-[16px]" src={kycSuccess} alt="approved icon" /></div>}
          </button>
        </div>
        {menuIsOpen && (
        <Menu toggleMenu={toggleMenu} menuIsOpen={menuIsOpen} />
        )}
      </div>
      {(status === KYCStatusTypes.INITIATED) && (
      <Button
        className="h-10 rounded-[8px] text-[14px] z-10 sm:hidden mx-5"
        category={ButtonCategory.Outlined}
        onClick={handleKycClick}
      >
        <h5>Start KYC</h5>
      </Button>
      )}
    </>
  );
};
