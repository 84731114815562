import { AppElement, AppFC } from '../../../interfaces';
import { KYCAlertProps } from './types';
import { AlertCategory, ButtonCategory } from '../../../constants';

import { Button } from '../../Atoms';
import {
  AlertErrorIcon,
  AlertSuccessIcon,
  AlertWarningIcon,
} from '../../Atoms/icons';

import closeIcon from '../../../assets/icons/close.svg';

const getIcon = (category: AlertCategory) => {
  switch (category) {
    case AlertCategory.Error:
      return { icon: <AlertErrorIcon />, classes: 'bg-[#FFEFEF]' };
    case AlertCategory.Warning:
      return { icon: <AlertWarningIcon />, classes: 'bg-[#FFF3E5]' };
    case AlertCategory.Success:
      return { icon: <AlertSuccessIcon />, classes: 'bg-[#EDF8E7]' };
    default:
      return { icon: <AlertErrorIcon />, classes: 'bg-[#EDF8E7]' };
  }
};
export const KYCAlert: AppFC<KYCAlertProps> = ({
  className,
  category,
  title,
  description,
  buttonText,
  onButtonClick,
  isClosable,
  onClose,
  isVertical,
}): AppElement => {
  const { icon, classes } = getIcon(category);
  return (
    <div className={`relative w-full flex items-center text-black ${isVertical ? 'flex-col py-6 gap-6' : 'gap-12 py-[18px]'} px-12  rounded-xl min-h-[160px] ${classes} ${className || ''}`}>
      {icon}
      <div className="flex flex-col">
        <div className={`flex flex-col ${isVertical ? 'items-center' : ''}`}>
          <h4 className="leading-[160%]">{title}</h4>
          {description && <p className={`font-medium ${isVertical ? 'text-center' : ''}`}>{description}</p>}
        </div>
        {buttonText
            && (
            <Button
              className="mt-4 h-10 w-32"
              category={ButtonCategory.Outlined}
              onClick={onButtonClick}
              isReverse
            >
              <h5>{buttonText}</h5>
            </Button>
            )}
      </div>
      {isClosable && (
      <button className="absolute right-4 top-4 p-0 h-auto" onClick={onClose}>
        <img src={closeIcon} alt="close" />
      </button>
      )}
    </div>
  );
};
