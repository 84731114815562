import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import TagManager from 'react-gtm-module';
import { App } from './App';
import {
  ProjectsContextProvider,
  InvoicesContextProvider,
  RequestContextProvider,
  ProfileContextProvider,
  ViewContextProvider,
} from './stores';

if (process.env.REACT_APP_MODE === 'production') {
  TagManager.initialize({
    gtmId: 'GTM-5B8TN6TN', // process.env.REACT_APP_GTAG_ID,
    dataLayerName: 'dataLayer',
  });
}

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

root.render(
  <React.StrictMode>
    <RequestContextProvider>
      <ViewContextProvider>
        <ProfileContextProvider>
          <ProjectsContextProvider>
            <InvoicesContextProvider>
              <App />
            </InvoicesContextProvider>
          </ProjectsContextProvider>
        </ProfileContextProvider>
      </ViewContextProvider>
    </RequestContextProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
