import { useEffect, useRef, useState } from 'react';
import { AppElement, AppFC } from '../../../interfaces';
import { ProgressBoxProps } from './types';

export const ProgressBox: AppFC<ProgressBoxProps> = ({
  className,
  totalAmount,
  currentAmount = 0,
  labelSize = '12px',
  valueSize = '20px',
  percentIsShown = false,
}): AppElement => {
  const [isOverlapped, setIsOverlapped] = useState(false);
  const currentAmountPercent = ((currentAmount as number) * 100) / (totalAmount as number);
  const refParent = useRef<HTMLDivElement | null>(null);
  const refChild = useRef<HTMLDivElement | null>(null);

  const isFilled = currentAmount === totalAmount;

  useEffect(() => {
    const parentWidth = refParent.current?.offsetWidth || 0;
    const childWidth = refChild.current?.offsetWidth || 0;
    setIsOverlapped(childWidth > parentWidth);
  }, []);

  return (
    <div className={`w-full flex flex-col  gap-3.5 ${className || ''}`}>
      <div className="relative w-full">
        <div className=" absolute bottom-0 bg-[rgba(214,179,137,0.16)] h-1.5 w-full" />
        <div
          ref={refParent}
          className={`flex flex-col ${isOverlapped ? 'items-start' : 'items-end'} gap-2`}
          style={{ width: `${currentAmountPercent}%` }}
        >
          {percentIsShown && (
          <h5 ref={refChild} className=" py-1.5 px-1 font-extrabold text-white bg-gradient-to-r from-lightBrown to-mediumBrown rounded-lg w-max">
            {Math.round(currentAmountPercent)}
            %
          </h5>
          )}
          <div className="bg-gradient-to-r from-lightBrown to-mediumBrown h-1.5 w-full" />
        </div>
      </div>

      <div className="flex justify-between">
        {!isFilled && (
        <div className="flex flex-col items-start gap-1">
          <h5 className={`text-[${valueSize}]`}>
            {currentAmount}
          </h5>
          <h5 className={`text-coldBrown text-[${labelSize}]`}>
            Sold Miners
          </h5>
        </div>
        )}
        {isFilled && (
        <div className="flex flex-col items-start gap-1">
          <h5 className={`text-[${valueSize}]`}>
            0
          </h5>
          <h5 className={`text-coldBrown text-[${labelSize}]`}>
            Available Miners
          </h5>
        </div>
        )}
        <div className={`flex flex-col ${isFilled ? 'items-start' : 'items-end'} gap-1`}>
          <h5 className={`text-[${valueSize}]`}>
            {totalAmount}
          </h5>
          <h5 className={`text-coldBrown text-[${labelSize}]  ${isFilled ? 'text-start' : 'text-end'}`}>
            Total Miners
          </h5>
        </div>
        {isFilled && (
        <div className="flex flex-col items-start gap-1">
          <h5 className={`text-[${valueSize}]`}>
            100%
          </h5>
          <h5 className={`text-coldBrown text-[${labelSize}]`}>
            Filled
          </h5>
        </div>
        )}
      </div>
    </div>
  );
};
