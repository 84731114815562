import axios from 'axios';
import { camelizeKeys, decamelizeKeys } from 'humps';

import { AUTH_API } from '../../../constants';
import { AuthToken } from './AuthToken';

export const initAxiosInterceptors = () => {
  const authToken: AuthToken = new AuthToken();

  axios.interceptors.request.use((config) => {
    const newConfig = { ...config };
    newConfig.url = `${config.url}`;
    if (authToken.getAccessToken() && authToken.getRefreshToken() && !newConfig.headers.Authorization) {
      newConfig.headers.Authorization = `Bearer ${authToken.getAccessToken()}`;
    }
    if (newConfig.headers['api-key']) {
      return newConfig;
    }
    if (config.params) {
      newConfig.params = decamelizeKeys(config.params);
    }
    if (config.data && !(config.data instanceof FormData)) {
      newConfig.data = decamelizeKeys(config.data, { separator: '_' });
      newConfig.paramsSerializer = () => JSON.stringify(decamelizeKeys(config.params || {}));
    }
    return newConfig;
  });

  axios.interceptors.response.use((response) => {
    if (
      response.data
        && response.headers['content-type'] === 'application/json'
    ) {
      response.data = camelizeKeys(response.data);
    }
    return response;
  }, (error) => {
    const originalRequest = error.config;
    const tokenIsRefreshing = authToken.getTokenIsRefreshing();

    if (!error || !error?.response) {
      return { message: 'cancel request' };
    }

    if (error?.response?.status === 404) {
      return { message: error.response.data.detail };
    }

    if (error.response?.status === 401 && originalRequest.url === AUTH_API.POST_REFRESH_TOKEN) {
      if (error.response.data.code === 'token_not_valid') {
        authToken.clearTokens();
        window.location.reload();
      }
    }

    if (error.response?.status === 401
        && originalRequest.url !== AUTH_API.POST_SIGN_IN
        && tokenIsRefreshing !== 'on'
        && authToken.getRefreshToken()) {
      authToken.setTokens({
        tokenIsRefreshing: 'on',
      });
      return axios.post(
        AUTH_API.POST_REFRESH_TOKEN,
        { refresh: authToken.getRefreshToken() },
        { headers: { Accept: 'application/json' } },
      )
        .then((response) => {
          authToken.setTokens({
            accessToken: response.data.access,
            refreshToken: response.data.refresh,
            tokenIsRefreshing: 'off',
          });

          const originalRequestWithNewToken = {
            ...originalRequest,
            headers: {
              ...originalRequest.headers,
              Authorization: `Bearer ${authToken.getAccessToken()}`,
            },
          };

          return axios(originalRequestWithNewToken);
        }).catch(() => {
          authToken.setTokens({
            tokenIsRefreshing: 'off',
          });
        });
    }

    const newError = { ...error };
    if (newError.response) {
      newError.response = camelizeKeys(error.response);
    }
    return Promise.reject(newError);
  });
};
