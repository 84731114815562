import {
  useContext, useEffect, useRef, useState,
} from 'react';

import { useReactToPrint } from 'react-to-print';
import { useNavigate } from 'react-router-dom';
import { ProfileContext, ViewContext, ViewModalActionTypes } from '../../../../../stores';
// import { ProfileHandler } from '../../../../../stores/handlers';
import {
  BlockchainAccountDetailsInterface,
  EURAccountDetailsInterface,
  WalletInterface,
} from '../../../../../stores/contexts';

import { AppElement, AppFC } from '../../../../../interfaces';
import { DetailItemInterface } from './types';
import { getAccountDetails, numberWithCommas } from '../../../../../utils';

import { CopyBox } from '../../../../Molecules';
import { Button, Loader } from '../../../../Atoms';
import { ButtonCategory, CurrencyTypes, Paths } from '../../../../../constants';
import { PrintIcon } from '../../../../Atoms/icons';
import { InvoiceHandler, ProfileHandler } from '../../../../../stores/handlers';

export const AccountDetails: AppFC = (): AppElement => {
  const [isLoading, setIsLoading] = useState(false);
  const [details, setDetails] = useState<EURAccountDetailsInterface | BlockchainAccountDetailsInterface>();
  // const { enrichAccount } = ProfileHandler();
  const { profileState: { wallet, sumsubId, email } } = useContext(ProfileContext);
  const {
    viewModal: {
      title, description, info, importantMessage,
    },
    updateViewModal,
  } = useContext(ViewContext);

  const { getInvoices } = InvoiceHandler();
  const { getProfile } = ProfileHandler();

  const { accountDetails } = wallet as WalletInterface || {};

  const currentAccountDetails = accountDetails && Object.values(accountDetails).find((item) => item.currency === info?.selectedAccount?.currency);

  const contentRef = useRef(null);
  const navigate = useNavigate();

  const handlePrint = useReactToPrint({
    content: () => contentRef?.current,
  });

  useEffect(() => {
    if (wallet?.accounts && !info?.noKYC) {
      if (!currentAccountDetails && info?.selectedAccount) {
        setDetails(undefined);
        // enrichAccount({ accountId: info?.selectedAccount.accountId })
        //   .then((res) => {
        //     setDetails(res);
        //     console.log('striga user wallet account enrich', res);
        //   })
        //   .catch((reason) => {
        //     console.log('account enrich error', reason);
        //   });
      } else {
        setDetails(currentAccountDetails);
      }
    }
  }, [info?.selectedAccount]);

  useEffect(() => {
    if (wallet?.accounts && !info?.noKYC) {
      if (!currentAccountDetails && info?.selectedAccount) {
        setDetails(undefined);
        // enrichAccount({ accountId: info?.selectedAccount.accountId })
        //   .then((res) => {
        //     setDetails(res);
        //     console.log('striga user wallet account enrich', res);
        //   })
        //   .catch((reason) => {
        //     console.log('account enrich error', reason);
        //   });
      } else {
        setDetails(currentAccountDetails);
      }
    }
  }, [info?.selectedAccount]);

  let currentDetails: DetailItemInterface[] = [];
  if (currentAccountDetails) {
    currentDetails = getAccountDetails(details);
  }

  if (sumsubId || info?.noKYC) {
    currentDetails = [
      {
        label: 'Currency',
        value: CurrencyTypes.EUR,
      },
      {
        label: 'Purchase Amount',
        value: `${numberWithCommas(info?.totalPrice, CurrencyTypes.EUR)}`,
      },
      {
        label: 'Bank Country',
        value: 'NETHERLANDS',
      },
      {
        label: 'IBAN',
        value: 'NL18INGB0675400899',
      },
      {
        label: 'SWIFT CODE',
        value: 'INGBNL2A',
      },
      {
        label: 'Bank Name',
        value: 'ING Bank N.V.',
      },
      {
        label: 'Bank Holder Name',
        value: 'DELOS DIGITAL B.V.',
      },
      {
        label: 'Wire Instruction Note',
        value: `${email}, ${info?.projectName || '-'}`,
        width: '60%',
      },
    ];
  }

  const handleFinalizeClick = () => {
    setIsLoading(true);

    Promise.all([
      getInvoices(),
      getProfile(),
    ])
      .then(() => {
        setIsLoading(false);
        navigate(Paths.Projects);
        updateViewModal({
          type: ViewModalActionTypes.Close,
        });
      })
      .catch((e) => {
        setIsLoading(false);
        console.log('project/invoice/profile/getKYCStatus data error', e);
      });
  };

  return (
    <div className="flex flex-col gap-6 max-w-[600px]">
      <h4>{title || 'Account Details'}</h4>
      {info?.selectedAccount?.currency !== CurrencyTypes.EUR && (
        <div className="flex flex-col gap-2 max-md:px-0 py-6 border-t border-white">
          <div className="flex justify-between">
            <h4>Payment instructions</h4>
            <button className="p-0" onClick={handlePrint}>
              <PrintIcon />
            </button>
          </div>
          <p className="mt-2">{description || 'Please follow the instructions below to add funds to your Pantheon Portal Balance. Allow 1-3 business days for the deposit to be processed.'}</p>
          {importantMessage && (
          <div className="flex flex-col gap-1 text-[12px] font-bold bg-[#EB000014] text-[#EB0000] rounded-lg p-2.5">
            <span>IMPORTANT!</span>
            <span>Be aware that transactions initiated on Friday may take additional days to be debited due to banking processing times over the weekend. We appreciate your understanding and patience.</span>
          </div>
          )}
          {/* <div className="flex flex-col gap-1 text-[12px] font-medium bg-[#EB9B0014] text-[#EB9B00] rounded-lg p-2.5"> */}
          {/*   <span>IMPORTANT!</span> */}
          {/*   <span>Be aware that transactions initiated on Friday may take additional days to be debited due to banking processing times over the weekend. We appreciate your understanding and patience.</span> */}
          {/* </div> */}
        </div>
      )}
      <div ref={contentRef} className="flex flex-col gap-4 w-full">
        {currentAccountDetails || sumsubId || info?.noKYC
          ? (
            <>
              {currentDetails?.map(({
                label,
                value,
                width,
                isQrCode,
              }, index) => <CopyBox key={`copy-box-${index}`} label={label} value={value} width={width} isQrCode={isQrCode} />)}
            </>
          ) : <Loader className="self-center w-7 h-7" />}
      </div>
      <Button
        className="self-end w-52"
        category={ButtonCategory.Light}
        onClick={handleFinalizeClick}
        isLoading={isLoading}
        disabled={isLoading}
      >
        Finalize
      </Button>
    </div>
  );
};
