import { AppElement, AppFC } from '../../../interfaces';
import { InvoiceHistoryRowProps } from './types';
import { capitalizeWords, dateFormat, numberWithCommas } from '../../../utils';

export const HistoryRow: AppFC<InvoiceHistoryRowProps> = ({
  description,
  totalAmount,
  createdAt,
  type,
  status,
  className,
  ...rest
}): AppElement => {
  const date = dateFormat(createdAt, 'MMM D, YYYY');
  console.log('description', description);
  return (
    <tr className={`h-[72px] rounded-lg ${className || ''}`} {...rest}>
      <td className="px-6">
        {capitalizeWords(type)}
      </td>
      <td className="px-3">
        {description}
      </td>
      <td className="px-3">
        {numberWithCommas(totalAmount)}
      </td>
      <td className="px-3">
        {capitalizeWords(status)}
      </td>
      <td className="px-3">
        {date}
      </td>
    </tr>
  );
};
