import { useContext, useEffect } from 'react';

import { AuthToken, httpRequest } from '../../services';
import {
  HTTPRequestDataType,
  HttpRequestHeaderTypes,
  SignInDataProps,
  SignUpDataProps,
  TwoFaDataProps,
} from '../../services/httpRequest';

import { AUTH_API, RequestTypes } from '../../constants';

import {
  ProfileActionTypes,
  ProfileContext,
  RequestContext,
  ViewContext,
  ViewLoaderActionTypes,
} from '../contexts';

export const AuthHandler = () => {
  const { updateViewLoader } = useContext(ViewContext);
  const { requestState } = useContext(RequestContext);
  const { updateProfileState } = useContext(ProfileContext);

  const myLS = new AuthToken();
  const signUp = async (data: SignUpDataProps) => httpRequest({
    url: AUTH_API.POST_SIGN_UP,
    method: RequestTypes.Post,
    withoutToken: true,
    data,
    headerType: HttpRequestHeaderTypes.MultiPart,
  });

  const resendEmail = async (email: string) => httpRequest({
    url: AUTH_API.POST_EMAIL_RESEND,
    method: RequestTypes.Post,
    withoutToken: true,
    data: { email },
    // headerType: HttpRequestHeaderTypes.MultiPart,
  });

  const signIn = async (data: SignInDataProps) => httpRequest({
    url: AUTH_API.POST_SIGN_IN,
    method: RequestTypes.Post,
    data,
    withoutToken: true,
  });

  const twoFaAuthorize = async (data: TwoFaDataProps) => {
    const response = await httpRequest({
      url: AUTH_API.POST_TWO_FA_AUTHORIZE,
      method: RequestTypes.Post,
      data,
      withoutToken: true,
    });

    console.log('twoFaAuthorize', response.data);

    if (response.data) {
      myLS.setTokens({
        accessToken: response.data.tokens.access,
        refreshToken: response.data.tokens.refresh,
        tokenIsRefreshing: 'off',
      });
      updateProfileState({ type: ProfileActionTypes.UpdateProfile, payload: response.data });
    }

    return response;
  };

  const signOut = async () => {
    const response = await httpRequest({
      url: AUTH_API.GET_SIGN_OUT,
      method: RequestTypes.Post,
      data: { refresh: myLS.getRefreshToken() },
      // headerType: HttpRequestHeaderTypes.MultiPart,
    });

    updateProfileState({ type: ProfileActionTypes.ClearProfile });

    myLS.clearTokens();

    return response;
  };

  const passwordReset = async (data: HTTPRequestDataType) => httpRequest({
    url: AUTH_API.POST_RESET,
    method: RequestTypes.Post,
    data,
    headerType: HttpRequestHeaderTypes.MultiPart,
    withoutToken: true,
  });

  const passwordResetConfirm = async (data: HTTPRequestDataType) => httpRequest({
    url: AUTH_API.POST_RESET_CONFIRM,
    method: RequestTypes.Post,
    data,
    headerType: HttpRequestHeaderTypes.MultiPart,
    withoutToken: true,
  });

  const passwordChange = async (data: HTTPRequestDataType) => {
    const response = await httpRequest({
      url: AUTH_API.POST_PASSWORD_CHANGE,
      method: RequestTypes.Post,
      data,
      headerType: HttpRequestHeaderTypes.MultiPart,
      withoutToken: true,
    });

    return response.data;
  };

  const emailVerification = async (key: string) => httpRequest({
    url: AUTH_API.POST_EMAIL_VERIFICATION,
    method: RequestTypes.Post,
    data: { key },
    withoutToken: true,
  });

  useEffect(() => () => {
    updateViewLoader({ type: ViewLoaderActionTypes.UpdateLoader, payload: false });
    requestState.cancelTokenSource.cancel();
  }, [requestState.cancelTokenSource, updateViewLoader]);

  return ({
    authRequestStatus: requestState.status,
    authResponseData: requestState.responseData,
    authSuccessMessage: requestState.successMessage,
    authRequestError: requestState.errorMessage,
    signIn,
    twoFaAuthorize,
    signUp,
    signOut,
    passwordReset,
    passwordResetConfirm,
    passwordChange,
    emailVerification,
    resendEmail,
  });
};
