import {
  useContext, useEffect, useRef, useState,
} from 'react';
import { Navigate } from 'react-router-dom';

import {
  ProfileContext, ViewContext, ViewModalActionTypes,
} from '../../../stores';
// import { ProfileHandler, ProjectHandler } from '../../../stores/handlers';

import { AppElement, AppFC } from '../../../interfaces';
import {
  BlockchainAccountDetailsInterface,
  EURAccountDetailsInterface,
  WalletInterface,
  WalletAccountInterface,
} from '../../../stores/contexts';
import { getAccountDetails, getCurrencyOptions } from '../../../utils';
import {
  ButtonCategory,
  CurrencyTypes,
  Paths,
  walletIcons,
  walletKeys,
} from '../../../constants';

import { CopyBox, WalletCard } from '../../Molecules';
import { Button, Loader } from '../../Atoms';

export const Wallets: AppFC = (): AppElement => {
  const modalRef = useRef<HTMLDivElement | null>(null);
  const [details, setDetails] = useState<EURAccountDetailsInterface | BlockchainAccountDetailsInterface>();
  const { profileState: { wallet = {} as WalletInterface, strigaId } } = useContext(ProfileContext);
  // const { enrichAccount } = ProfileHandler();
  // const { getWhitelistedAddresses, getSwapFee } = ProjectHandler();
  const { accounts = {}, accountDetails = {} } = wallet;
  const [selectedAccount, setSelectedAccount] = useState(accounts?.eUR);

  const { updateViewModal } = useContext(ViewContext);

  const currencyOptions = getCurrencyOptions(selectedAccount.currency);
  const handleWalletCardClick = (account: WalletAccountInterface) => () => {
    setSelectedAccount(account);
    const currentAccount = Object.values(accountDetails).find((item) => item.currency === account.currency);
    if (!currentAccount) {
      setDetails(undefined);
      // enrichAccount({ accountId: account.accountId })
      //   .then((res) => {
      //     setDetails(res);
      //   });
    } else {
      setDetails(currentAccount);
    }
  };

  const handleWithdrawClick = () => {
    updateViewModal({
      type: ViewModalActionTypes.WithdrawModal,
      payload: {
        isClosable: false,
        ref: modalRef,
        info: {
          selectedAccount,
        },
      },
    });
  };
  const handleExchangeClick = () => {
    updateViewModal({
      type: ViewModalActionTypes.ExchangeModal,
      payload: {
        isClosable: false,
        ref: modalRef,
        info: {
          selectedAccount,
        },
      },
    });
  };

  useEffect(() => {
    if (strigaId) {
      const currentAccount = Object.values(accountDetails)
        .find((item) => item.currency === accounts.eUR.currency);
      if (!currentAccount) {
        // Promise.all([
        //   enrichAccount({ accountId: accounts.eUR.accountId }),
        //   getSwapFee(),
        // ])
        //   .then((res) => {
        //     setDetails(res[0]);
        //   })
        //   .catch(() => {
        //     setDetails({} as EURAccountDetailsInterface | BlockchainAccountDetailsInterface);
        //   });
      } else {
        setDetails(currentAccount);
      }
    }
  }, []);

  useEffect(() => {
    if (selectedAccount.currency !== CurrencyTypes.EUR) {
      // getWhitelistedAddresses(selectedAccount.currency).then((res) => {
      //   console.log('whitelistedAddresses', res);
      // });
    }
  }, [selectedAccount]);

  if (!strigaId) {
    return <Navigate to={Paths.Projects} />;
  }

  return (
    <div className="flex flex-col gap-6 h-full">
      <div>
        <h5 className="text-[1rem] font-semibold leading-[160%]">My Wallets</h5>
        <p className="mt-1">Use the details of each currency wallet to deposit funds into your Pantheon Mining portal balance.</p>
      </div>
      <div className="flex flex-wrap gap-2">
        {walletKeys?.map((key, index) => (
          <WalletCard
            key={`wallets-card-${index}`}
            className="[&>img]:h-10 [&>img]:w-10 w-40"
            icon={walletIcons[key]}
            balance={accounts[key].availableBalance}
            currency={accounts[key].currency}
            onClick={handleWalletCardClick(accounts[key])}
            selected={accounts[key].accountId === selectedAccount.accountId}
            disabled={!details}
            decimals={4}
          />
        ))}
      </div>
      <div className="flex flex-col justify-center gap-4 w-full max-w-[640px] max-lg:ml-0 max-lg:self-center mt-14">
        {details
          ? (
            <>
              {getAccountDetails(details)?.map(({
                label,
                value,
                width,
                isQrCode,
              }, index) => <CopyBox key={`copy-box-${index}`} label={label} value={value} width={width} isQrCode={isQrCode} />)}
              <div className="flex gap-4">
                <Button
                  className="mt-8 max-w-[200px]"
                  category={ButtonCategory.Filled}
                  onClick={handleWithdrawClick}
                >
                  Withdraw Funds
                </Button>
                {currencyOptions.length > 0 && (
                <Button
                  className="mt-8 max-w-[200px]"
                  category={ButtonCategory.Outlined}
                  onClick={handleExchangeClick}
                  disabled={!Number(selectedAccount.availableBalance.amount)}
                >
                  Exchange
                  {' '}
                  {selectedAccount.currency}
                </Button>
                )}
              </div>
            </>
          ) : <Loader className="self-center w-7 h-7" />}
      </div>
    </div>
  );
};
