import React, { useContext, useState } from 'react';

import {
  // ProjectsContext,
  ViewContext, ViewModalActionTypes,
} from '../../../../../stores';

import { AppElement, AppFC } from '../../../../../interfaces';
import {
  ButtonCategory,
  // CurrencyTypes,
  Paths, PROFILE_API,
  // ProjectCurrencyTypes,
  RequestTypes,
} from '../../../../../constants';

import { Button } from '../../../../Atoms';
import { httpRequest } from '../../../../../services';
import { generateErrorAlert } from '../../../../../utils';
// import { InvoiceHandler, ProjectHandler } from '../../../../../stores/handlers';

export const BankingAccessInfo: AppFC = (): AppElement => {
  const [isLoading, setIsLoading] = useState(false);
  // const { projectsState: { rates } } = useContext(ProjectsContext);
  const [alert, setAlert] = useState<AppElement>();

  const {
    viewModal: {
      title,
      description,
      info,
    },
    updateViewModal,
  } = useContext(ViewContext);

  // const { createInvestmentAgreement } = ProjectHandler();
  // const { generateInvoice } = InvoiceHandler();
  //
  // const {
  //   selectedAccount, projectId, projectCurrency,
  // } = info || {};

  const { POST_KYC_START } = PROFILE_API;

  // const handlePassKYCClick = (event: React.MouseEvent<HTMLButtonElement>) => {
  //   event.stopPropagation();
  //
  //   // updateViewModal({
  //   //   type: ViewModalActionTypes.KYCModal,
  //   //   payload: {},
  //   // });
  // };
  const handlePassKYCClick = () => {
    setIsLoading(true);
    httpRequest({
      data: {}, // { tier: (tier2?.status === KYCStatusTypes.APPROVED && 3) || (tier1?.status === KYCStatusTypes.APPROVED && 2) || 1 },
      url: POST_KYC_START,
      method: RequestTypes.Post,
    })
      .then((res) => {
        if (res.data.token) {
          console.log('window.location.origin', window.location.origin);
          updateViewModal({
            type: ViewModalActionTypes.SumsubModal,
            payload: {
              isClosable: true,
              // isReload: true,
              info: {
                ...info,
                url: `${window.location.origin}${Paths.SumsubKYC}?token=${res.data.token}`,
              },
            },
          });
        }
        if (res.data.verificationLink) {
          updateViewModal({
            type: ViewModalActionTypes.SumsubModal,
            payload: {
              isClosable: true,
              // isReload: true,
              // kycTier: (tier2?.status === KYCStatusTypes.APPROVED && 3) || (tier1?.status === KYCStatusTypes.APPROVED && 2) || 1,
              info: {
                ...info,
                url: res.data.verificationLink,
              },
            },
          });
        }
        setIsLoading(false);
      }).catch((reason) => {
        generateErrorAlert(reason, 'KYC Start Failed!', setAlert, 'top-6 z-50');
        setIsLoading(false);
      });
  };

  const handleKYCSkipClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    updateViewModal({ type: ViewModalActionTypes.Close });

    // const invoiceData = {
    //   numberOfMiners: Number(info?.spotsAmount),
    // };
    // if (projectId) {
    //   setIsLoading(true);
    //   generateInvoice(projectId, invoiceData)
    //     .then((res) => {
    //       const {
    //         invoiceNumber,
    //         id,
    //       } = res.data;
    //
    //       /** Investment flow - 2.Investment Agreement document generation by invoice number and spots * */
    //       const projectAgreementData = {
    //         invoiceNumber,
    //         spots: info?.spotsAmount as number,
    //         currency: CurrencyTypes.EUR, // selectedAccount.currency,
    //         amount: Number((Number(info?.totalPrice) * (projectCurrency === ProjectCurrencyTypes.USD ? Number(rates?.rates.eUR) : 1) * (info?.spotsAmount as number)).toFixed(2)),
    //       };
    //       createInvestmentAgreement(projectId as string, projectAgreementData)
    //         .then(({ data }) => {
    //           setIsLoading(false);
    //           /** Open external document link for sign * */
    //           updateViewModal({
    //             type: ViewModalActionTypes.DocusignModal,
    //             payload: {
    //               isClosable: false,
    //               info: {
    //                 ...info,
    //                 url: data.redirectUrl,
    //                 selectedAccount,
    //                 invoiceId: id,
    //               },
    //             },
    //           });
    //         })
    //         .catch((reason) => {
    //           generateErrorAlert(reason, 'Purchase agreement file generation Failed!', setAlert, 'top-6 z-50');
    //           setIsLoading(false);
    //         });
    //     })
    //     .catch((reason) => {
    //       generateErrorAlert(reason, 'Invoice Generation Failed!', setAlert, 'top-6 z-50');
    //       setIsLoading(false);
    //     });
    // }
    // updateViewModal({
    //   type: ViewModalActionTypes.BankingAccessFormModal,
    //   payload: {
    //     info: {
    //       ...info,
    //       noKYC: true,
    //     },
    //   },
    // });
  };

  return (
    <div className="flex flex-col items-center">
      {alert}
      {title && <h4>{title}</h4>}
      {description && <p className="mt-2 text-center max-w-lg">{description}</p>}
      <div className="w-full flex justify-center items-center gap-2 mt-7 max-sm:flex-col [&>button]:w-[200px] [&>button]:p-0 max-sm:[&>button]:w-56">
        <Button category={ButtonCategory.Outlined} onClick={handleKYCSkipClick} disabled={isLoading}>Cancel</Button>
        <Button type="submit" category={ButtonCategory.Filled} onClick={handlePassKYCClick} isLoading={isLoading} disabled={isLoading}>Pass KYC</Button>
      </div>
    </div>
  );
};
