import React, { useContext, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import { ProfileContext, ViewContext, ViewModalActionTypes } from '../../../../../stores';
import { ProfileHandler } from '../../../../../stores/handlers';

import { AppElement, AppFC } from '../../../../../interfaces';
import { WalletDataProps } from './types';
import { ButtonCategory } from '../../../../../constants';

import { Button } from '../../../../Atoms';
import { AppInput } from '../../../../Molecules';

const validationSchema = yup.object().shape({
  bitcoinWalletAddress: yup
    .string(),
});
export const BitcoinWalletAddress: AppFC = (): AppElement => {
  const [isLoading, setIsLoading] = useState(false);
  const {
    profileState: {
      bitcoinWalletAddress,
      country,
      address,
      city,
      zipCode,
      birthdate,
      telegram,
    },
  } = useContext(ProfileContext);
  const { updateViewModal } = useContext(ViewContext);

  const { updateProfile } = ProfileHandler();

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      bitcoinWalletAddress: bitcoinWalletAddress || '',
    },
  });

  const onSubmit: SubmitHandler<WalletDataProps> = (data) => {
    const requestData = {
      country,
      address,
      city,
      zipCode,
      birthdate,
      telegram,
      ...data,
    };
    setIsLoading(true);

    updateProfile(requestData).then(() => {
      setIsLoading(false);
      updateViewModal({
        type: ViewModalActionTypes.SuccessModal,
        payload: {
          title: 'Wallet address change success',
          description: '',
        },
      });
    }).catch(() => {
      setIsLoading(false);
      updateViewModal({
        type: ViewModalActionTypes.FailModal,
        payload: {
          title: 'Wallet address change fail',
          description: '',
        },
      });
    });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col items-center self-center w-full">
      <h4>Bitcoin Wallet Address</h4>
      <p className="text-center mt-3">Please provide the Bitcoin wallet address where you would like to receive your returns from projects.</p>
      <div className="flex gap-14 w-full mt-10 max-md:flex-col max-md:items-center max-md:px-4">
        <AppInput
          label="Wallet Address"
          name="bitcoinWalletAddress"
          control={control}
          placeholder="Enter wallet address"
          error={errors.bitcoinWalletAddress}
          copiable
        />
      </div>
      <div className="w-full flex justify-between gap-2 mt-12">
        <Button className="w-[calc(50%-28px)] flex-1" category={ButtonCategory.Outlined} onClick={() => updateViewModal({ type: ViewModalActionTypes.Close })}>Cancel</Button>
        <Button type="submit" className="w-[calc(50%-28px)] flex-1" isLoading={isLoading} category={ButtonCategory.Filled}>Save</Button>
      </div>
    </form>
  );
};
