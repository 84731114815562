import React, { useContext } from 'react';
import { QRCodeSVG } from 'qrcode.react';

import { ViewContext } from '../../../../../stores';

import { AppElement, AppFC } from '../../../../../interfaces';

export const QrCode: AppFC = ({ className }): AppElement => {
  const { viewModal: { blockchainAddress } } = useContext(ViewContext);

  return (
    <div className={`flex flex-col items-center my-auto ${className}`}>
      <h4>Scan QR</h4>
      <p className="mt-2 text-center max-w-md">Open your desired wallet and scan QR Code to send funds to your Pantheon Mining Account</p>
      <QRCodeSVG
        className="my-12"
        value={blockchainAddress as string}
        size={300}
      />
    </div>
  );
};
