import { useMemo, useReducer, createContext } from 'react';

import { projectsReducer } from './reducer';
import { AppElement, AppFC } from '../../../interfaces';
import {
  ContextProviderPropsInterface,
  UseReducerType,
} from '../../interfaces';
import {
  ProjectsActionType,
  ProjectsContextInterface,
} from './interfaces';

import { projectsInitialState } from './initialState';
import { ProjectsInterface } from '../../../types/projects';

export const ProjectsContext = createContext<ProjectsContextInterface>({} as ProjectsContextInterface);

export const ProjectsContextProvider: AppFC<ContextProviderPropsInterface> = ({ children }): AppElement => {
  const [projectsState, updateProjectsState] = useReducer<
  UseReducerType<ProjectsInterface, ProjectsActionType>
  >(projectsReducer, projectsInitialState);

  const value = useMemo(() => ({
    projectsState,
    updateProjectsState,
  }), [projectsState]);

  return (
    <ProjectsContext.Provider value={value}>{children}</ProjectsContext.Provider>
  );
};
