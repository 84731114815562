import React, { useContext, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import { ProfileHandler } from '../../../../../stores/handlers';
import { ProfileContext, ViewContext, ViewModalActionTypes } from '../../../../../stores';

import { httpRequest } from '../../../../../services';
import { KYCDataProps } from '../../../../../services/httpRequest';

import { AppElement, AppFC } from '../../../../../interfaces';
import { KYCFormDataProps } from './types';
import {
  ButtonCategory,
  RequestTypes,
  Paths,
  PROFILE_API,
  occupationOptions,
  purposeOfAccountOptions,
  sourceOfFundsOptions,
  txVolumeYearlyOptions,
} from '../../../../../constants';
import {
  generateErrorAlert,
  getCities,
  getCountries,
  getState,
} from '../../../../../utils';

import {
  Button,
  ErrorMessage,
  Label,
  Radio,
} from '../../../../Atoms';
import { AppInput, AppSelect, DateSelect } from '../../../../Molecules';

const prodShape = process.env.REACT_APP_MODE !== 'production' ? {
  occupation: yup
    .string()
    .required('Occupation is required'),
  sourceOfFunds: yup
    .string()
    .required('Source of funds is required'),
  purposeOfAccount: yup
    .string()
    .required('Purpose of account is required'),
  selfPepDeclaration: yup
    .string()
    .required('Self pep declaration is required'),
  expectedIncomingTxVolumeYearly: yup
    .string()
    .required('This field is required'),
  expectedOutgoingTxVolumeYearly: yup
    .string()
    .required('This field is required'),
} : {
  occupation: yup
    .string(),
  sourceOfFunds: yup
    .string(),
  purposeOfAccount: yup
    .string(),
  selfPepDeclaration: yup
    .string(),
  expectedIncomingTxVolumeYearly: yup
    .string(),
  expectedOutgoingTxVolumeYearly: yup
    .string(),
};

const shape = {
  ...prodShape,
  year: yup
    .string()
    .required('Year is required'),
  month: yup
    .string()
    .required('Month is required'),
  day: yup
    .string()
    .required('Day is required'),
  country: yup
    .string()
    .required('Country is required'),
  state: yup
    .string(),
  city: yup
    .string()
    .required('City is required'),
  zipCode: yup
    .string()
    .required('Postal Code is required'),
  address: yup
    .string()
    .required('Address is required'),
  address2: yup
    .string(),
  placeOfBirth: yup
    .string()
    .required('Place of birth is required'),
};

const validationSchema = yup.object()
  .shape(shape);
export const KYCForm: AppFC = ({ className }): AppElement => {
  const [isLoading, setIsLoading] = useState(false);
  const [alert, setAlert] = useState<AppElement>();
  const { profileState } = useContext(ProfileContext);
  const { viewModal, updateViewModal } = useContext(ViewContext);

  const { updateProfile } = ProfileHandler();

  const {
    birthdate,
    country = '',
    city,
    zipCode,
    state,
    address,
    address2,
    placeOfBirth,
    occupation,
    sourceOfFunds,
    purposeOfAccount,
    selfPepDeclaration,
    expectedIncomingTxVolumeYearly,
    expectedOutgoingTxVolumeYearly,
    sumsubId,
  } = profileState;
  const dob = birthdate?.split('-') || [];

  const defaultValues = {
    country: country || '',
    city: city || '',
    state: state || '',
    zipCode: zipCode || '',
    address: address || '',
    address2: address2 || '',
    year: dob[0] || '',
    month: dob[1] || '',
    day: dob[2] || '',
    placeOfBirth: placeOfBirth || '',
    occupation: occupation || '',
    sourceOfFunds: sourceOfFunds || '',
    purposeOfAccount: purposeOfAccount || '',
    selfPepDeclaration: selfPepDeclaration || 'false',
    expectedIncomingTxVolumeYearly: expectedIncomingTxVolumeYearly || '',
    expectedOutgoingTxVolumeYearly: expectedOutgoingTxVolumeYearly || '',
  };

  const {
    control,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues,
  });

  const currentYear = watch('year');
  const currentMonth = watch('month');
  const currentState = watch('state');

  const {
    POST_KYC_START,
  } = PROFILE_API;

  const onSubmit: SubmitHandler<KYCFormDataProps> = (data) => {
    setIsLoading(true);

    const prodRequestData: KYCDataProps = {
      birthdate: `${data.year}-${data.month}-${data.day}`,
      address: data.address,
      city: data.city,
      zipCode: data.zipCode,
      country: data.country, // ISO 3166 alpha-2 format
      placeOfBirth: data.placeOfBirth,
    };

    const requestData = process.env.REACT_APP_MODE !== 'production' ? {
      occupation: data.occupation,
      sourceOfFunds: data.sourceOfFunds,
      purposeOfAccount: data.purposeOfAccount,
      selfPepDeclaration: data.selfPepDeclaration === 'true',
      expectedIncomingTxVolumeYearly: data.expectedIncomingTxVolumeYearly,
      expectedOutgoingTxVolumeYearly: data.expectedOutgoingTxVolumeYearly,
    } : {} as KYCDataProps;

    if (data.state) {
      prodRequestData.state = data.state;
    }

    if (data.address2) {
      prodRequestData.address2 = data.address2;
    }

    updateProfile({ ...prodRequestData, ...requestData } as KYCDataProps).then(() => {
      if (sumsubId) {
        httpRequest({
          data: {},
          url: POST_KYC_START,
          method: RequestTypes.Post,
        })
          .then((res) => {
            if (res.data.token) {
              updateViewModal({
                type: ViewModalActionTypes.SumsubModal,
                payload: {
                  isClosable: true,
                  // isReload: true,
                  info: {
                    ...viewModal.info,
                    url: `${window.location.origin}${Paths.SumsubKYC}?token=${res.data.token}`,
                  },
                },
              });
            }
          });
        setIsLoading(false);
      }
    }).catch((reason) => {
      const childElement = reason?.response?.data.ip && (
      <div className="flex gap-3 flex-wrap py-4 px-4 border-t border-t-neutral-500">
        <div><b>Supported countries:</b></div>
        <div className="flex justify-between w-full pl-4">
          <ul className="list-disc">
            <li>Austria</li>
            <li>Belgium</li>
            <li>Bulgaria</li>
            <li>Croatia</li>
            <li>Cyprus</li>
            <li>Czech Republic</li>
            <li>Denmark</li>
            <li>Estonia</li>
            <li>Finland</li>
            <li>France</li>
            <li>Germany</li>
            <li>Greece</li>
            <li>Hungary</li>
            <li>Ireland</li>
            <li>Italy</li>
          </ul>

          <ul className="list-disc">
            <li>Latvia</li>
            <li>Lithuania</li>
            <li>Luxembourg</li>
            <li>Malta</li>
            <li>Netherlands</li>
            <li>Poland</li>
            <li>Portugal</li>
            <li>Romania</li>
            <li>Slovakia</li>
            <li>Slovenia</li>
            <li>Spain</li>
            <li>Sweden</li>
            <li>Liechtenstein</li>
            <li>Norway</li>
            <li>Iceland</li>
          </ul>
        </div>
      </div>
      );
      generateErrorAlert(
        reason,
        'Profile Updating Failed!',
        setAlert,
        'top-[50%] z-50',
        true,
        () => { setAlert(null); },
        childElement,
      );
    });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={`flex flex-col items-center my-auto ${className}`}>
      {alert}
      <h4>Know your customer (KYC)</h4>
      <p className="mt-2 text-center">To make a purchase it is required to pass a KYC</p>
      <div className="flex flex-col gap-8 w-full max-w-[400px] mt-16">
        <DateSelect
          label="Date of birth"
          control={control}
          errors={errors}
          year={currentYear}
          month={currentMonth}
        />
        <AppSelect
          label="Place Of Birth"
          name="placeOfBirth"
          options={getCountries('alpha3')}
          control={control}
          placeholder="Select country"
          error={errors.country}
        />
        <AppSelect
          label="Country"
          name="country"
          options={getCountries()}
          control={control}
          placeholder="Select country"
          error={errors.placeOfBirth}
          blocked
          disabled
        />
        <div className="w-full flex gap-3.5">
          <AppSelect
            label="State"
            name="state"
            options={getState(country)}
            control={control}
            placeholder="Select State"
            error={errors.state}
            className="flex-[2]"
          />
          <AppInput
            label="Zip Code"
            name="zipCode"
            control={control}
            placeholder="Zip code"
            error={errors.zipCode}
            className="flex-[1]"
          />
        </div>
        <AppSelect
          label="City"
          name="city"
          options={getCities(country, currentState)}
          control={control}
          placeholder="Select City"
          error={errors.city}
        />
        <AppInput
          label="Address 1"
          name="address"
          control={control}
          placeholder="Enter address 1"
          error={errors.address}
        />
        <AppInput
          label="Address 2"
          name="address2"
          control={control}
          placeholder="Enter address 2"
          error={errors.address2}
        />
        {
            process.env.REACT_APP_MODE !== 'production'
            && (
            <>
              <AppSelect
                label="Occupation"
                name="occupation"
                options={occupationOptions}
                control={control}
                placeholder="Select occupation"
                error={errors.occupation}
              />
              <AppSelect
                label="Source Of Funds"
                name="sourceOfFunds"
                options={sourceOfFundsOptions}
                control={control}
                placeholder="Select source of funds"
                error={errors.sourceOfFunds}
              />
              <AppSelect
                label="Purpose Of Account"
                name="purposeOfAccount"
                options={purposeOfAccountOptions}
                control={control}
                placeholder="Select purpose of account"
                error={errors.purposeOfAccount}
              />
              <AppSelect
                label="Expected Incoming Transaction Volume Yearly"
                name="expectedIncomingTxVolumeYearly"
                options={txVolumeYearlyOptions}
                control={control}
                placeholder="Select volume"
                error={errors.expectedIncomingTxVolumeYearly}
              />
              <AppSelect
                label="Expected Outgoing Transaction Volume Yearly"
                name="expectedOutgoingTxVolumeYearly"
                options={txVolumeYearlyOptions}
                control={control}
                placeholder="Select volume"
                error={errors.expectedOutgoingTxVolumeYearly}
              />
              <Label>
                <h5 className={`text-[14px] ${errors.selfPepDeclaration ? 'text-error' : ''}`}>Are you politically exposed person? </h5>
                <Controller
                  name="selfPepDeclaration"
                  control={control}
                  render={({
                    field: {
                      value,
                    },
                  }) => (
                    <div className="flex items-center gap-4">
                      <Radio
                        onChange={(event) => {
                          setValue('selfPepDeclaration', event.target.value);
                        }}
                        id="radio-1"
                        checked={value === 'true'}
                        label="Yes"
                        value="true"
                      />
                      <Radio
                        onChange={(event) => {
                          setValue('selfPepDeclaration', event.target.value);
                        }}
                        id="radio-2"
                        checked={value === 'false'}
                        label="No"
                        value="false"
                      />
                    </div>
                  )}
                />
                {errors.selfPepDeclaration && <ErrorMessage className="top-20">{errors.selfPepDeclaration.message}</ErrorMessage>}
              </Label>
            </>
            )
        }
      </div>
      <div className="w-full flex justify-between gap-2 mt-12">
        <Button className="w-[calc(50%-28px)] mt-7 flex-1" category={ButtonCategory.Outlined} onClick={() => updateViewModal({ type: ViewModalActionTypes.Close })}>Cancel</Button>
        <Button type="submit" className="w-[calc(50%-28px)] mt-7 flex-1" category={ButtonCategory.Filled} isLoading={isLoading}>Pass KYC</Button>
      </div>
    </form>
  );
};
