import { deleteAxiosAuthorization, setAxiosAuthorization } from './utils';
import { TokenDataType } from './interfaces';

export class AuthToken {
  getAccessToken() { return localStorage.getItem('accessToken') || ''; }

  getRefreshToken() { return localStorage.getItem('refreshToken') || ''; }

  /** temporary * */
  getTokenIsRefreshing() { return localStorage.getItem('tokenIsRefreshing'); }

  setTokens(tokens: TokenDataType) {
    const { accessToken, refreshToken, tokenIsRefreshing } = tokens;
    if (refreshToken) {
      localStorage.setItem('refreshToken', refreshToken);
    }
    if (accessToken) {
      localStorage.setItem('accessToken', accessToken);
      setAxiosAuthorization(accessToken);
    }
    /** temporary * */
    if (tokenIsRefreshing) {
      localStorage.setItem('tokenIsRefreshing', tokenIsRefreshing);
    }
  }

  clearTokens() {
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('accessToken');
    localStorage.removeItem('tokenIsRefreshing');
    deleteAxiosAuthorization();
  }
}
