import { AppElement, AppFC } from '../../../interfaces';

export const BlockedIcon: AppFC = (props): AppElement => (
  <div {...props}>
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <path fillRule="evenodd" clipRule="evenodd" d="M8 1C7.07174 1 6.1815 1.36875 5.52513 2.02513C4.86875 2.6815 4.5 3.57174 4.5 4.5V6.5C3.96957 6.5 3.46086 6.71071 3.08579 7.08579C2.71071 7.46086 2.5 7.96957 2.5 8.5V13C2.5 13.5304 2.71071 14.0391 3.08579 14.4142C3.46086 14.7893 3.96957 15 4.5 15H11.5C12.0304 15 12.5391 14.7893 12.9142 14.4142C13.2893 14.0391 13.5 13.5304 13.5 13V8.5C13.5 7.96957 13.2893 7.46086 12.9142 7.08579C12.5391 6.71071 12.0304 6.5 11.5 6.5V4.5C11.5 2.56667 9.93333 1 8 1ZM10.5 6.5V4.5C10.5 3.83696 10.2366 3.20107 9.76777 2.73223C9.29893 2.26339 8.66304 2 8 2C7.33696 2 6.70107 2.26339 6.23223 2.73223C5.76339 3.20107 5.5 3.83696 5.5 4.5V6.5H10.5Z" fill="#99938C" />
    </svg>
  </div>

);
