import { useContext } from 'react';

import { httpRequest } from '../../services';
import { HttpRequestHeaderTypes, InvestmentAgreementDataProps, InvestmentSpotsDataProps } from '../../services/httpRequest';

import { ProjectsActionTypes, ProjectsContext } from '../contexts';

import { FINANCE_API, getProjectAPI, RequestTypes } from '../../constants';

export const ProjectHandler = () => {
  const { updateProjectsState } = useContext(ProjectsContext);

  const getProjects = async () => {
    const response = await httpRequest({
      url: getProjectAPI().PROJECTS,
      method: RequestTypes.Get,
      withoutToken: true,
    });

    const publishedProjects = response.data?.filter((project: { isPublished: boolean; }) => project.isPublished);

    updateProjectsState({
      type: ProjectsActionTypes.SetProjects,
      payload: publishedProjects,
    });

    return response.data;
  };

  const getProject = async (id: string) => {
    const response = await httpRequest({
      url: getProjectAPI(id).PROJECT,
      method: RequestTypes.Get,
    });
    return response.data;
  };

  const updateProjectSpots = async (id: string, data: InvestmentSpotsDataProps) => {
    const response = await httpRequest({
      url: `${getProjectAPI(id).PROJECTS_SPOTS}`,
      method: RequestTypes.Post,
      data,
      headerType: HttpRequestHeaderTypes.MultiPart,
    });

    return response.data;
  };

  const createInvestmentAgreement = async (id: string | number, data: InvestmentAgreementDataProps) => httpRequest({
    url: `${getProjectAPI(id).PROJECT_AGREEMENT}`,
    method: RequestTypes.Post,
    data,
    headerType: HttpRequestHeaderTypes.MultiPart,
  });

  const getRates = async () => {
    const response = await httpRequest({
      url: FINANCE_API.EXCHANGE_RATES,
      method: RequestTypes.Get,
      withoutToken: true,
    });

    updateProjectsState({
      type: ProjectsActionTypes.SetRates,
      payload: response.data,
    });

    return response.data;
  };

  const getBtcNetworkHashRate = async () => {
    const response = await httpRequest({
      url: FINANCE_API.BTC_NETWORK_HASH_RATE,
      method: RequestTypes.Get,
      withoutToken: true,
    });

    return response.data;
  };

  return {
    getProjects,
    getProject,
    updateProjectSpots,
    createInvestmentAgreement,
    getRates,
    getBtcNetworkHashRate,
  };
};
