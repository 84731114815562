import { NavLink } from 'react-router-dom';

import { AppElement, AppFC } from '../../../interfaces';
import { SideBarProps } from './types';

export const SideBar: AppFC<SideBarProps> = ({
  items,
  className,
  children,
}): AppElement => (
  <div className={`h-screen bg-default flex flex-col gap-5 items-center min-w-[250px] w-[20%] ${className}`}>
    {children}
    {items.map(({ title, icon, path }, index) => (
      <NavLink
        key={`sidebar-item-${index}`}
        to={path}
        className={({ isActive }) => (`rounded-lg w-full ${isActive ? 'bg-filled' : 'bg-default hover:bg-filled/40'}`)}
      >
        <div className="flex gap-3 items-center text-center p-4">
          <img src={icon} alt="icon" />
          <h5 className="text-[1rem]">{title}</h5>
        </div>
      </NavLink>
    ))}
  </div>
);
