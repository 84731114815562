import React, { useState } from 'react';
import { AppElement, AppFC } from '../../../interfaces';
import { InfoCardProps } from './types';

import { formatNumberWithK, numberWithCommas } from '../../../utils';
import { InfoIcon } from '../../Atoms/icons';

export const InfoCard: AppFC<InfoCardProps> = ({
  title,
  value,
  info,
  suffix,
  prefix,
  decimals,
  errorMessage,
  ...rest
}): AppElement => {
  const [popup, setPopup] = useState(false);
  return (
    <div className="flex flex-col gap-5 justify-between rounded-lg border border-white/30 px-5 py-[22px] w-full" {...rest}>
      <div className="flex justify-between items-center gap-2">
        <span className="font-bold">{title}</span>
        {info && (
        <button
          onClick={() => setPopup(!popup)}
          onBlur={() => setPopup(false)}
          className="relative p-0 h-fit text-left font-normal text-[#9FB2CC] hover:text-white"
        >
          <InfoIcon />
          <div className={`absolute right-0 w-[300px] max-2xl:w-[280px] max-xl:w-[250px] text-white bottom-8 bg-buttonFilled border border-[#2A455B] text-[12px] leading-[18px] rounded-lg shadow px-4 py-3 ${popup ? 'block' : 'hidden'}`}>
            {info}
          </div>
        </button>
        )}
      </div>
      <div className="flex items-center gap-4 justify-between relative group">
        {errorMessage ? (
          <p className="text-error text-[12px]">
            Can not calculate IRR:
            {' '}
            {errorMessage}
          </p>
        )
          : (
            <h3 className="font-bold leading-none">
              {value < 0 ? '-' : ''}
              {prefix || ''}
              {formatNumberWithK(Math.abs(value))}
              {suffix || ''}
            </h3>
          )}
        {!errorMessage && (
        <div className="absolute left-0 top-0 w-full bg-default rounded shadow-lg hidden group-hover:block">
          <h3 className="font-bold leading-none">
            {value < 0 ? '-' : ''}
            {prefix || ''}
            {numberWithCommas(Math.abs(value), '', decimals || 2)}
            {suffix || ''}
          </h3>
        </div>
        )}
      </div>
    </div>
  );
};
