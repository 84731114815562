import { HTMLAttributes } from 'react';
import { AppElement, AppFC } from '../../../interfaces';

export const CopyIcon: AppFC<HTMLAttributes<HTMLDivElement> & { filled?: boolean }> = ({ className, filled = false, ...rest }): AppElement => (
  <div className={`cursor-pointer ${className || ''}`} {...rest}>
    {
        filled ? (
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
            <path d="M9.33301 5.33325H2.66634C1.93101 5.33325 1.33301 5.93125 1.33301 6.66659V13.3333C1.33301 14.0686 1.93101 14.6666 2.66634 14.6666H9.33301C10.0683 14.6666 10.6663 14.0686 10.6663 13.3333V6.66659C10.6663 5.93125 10.0683 5.33325 9.33301 5.33325Z" fill="currentColor" />
            <path d="M13.333 1.33325H6.66634C6.31272 1.33325 5.97358 1.47373 5.72353 1.72378C5.47348 1.97382 5.33301 2.31296 5.33301 2.66659V3.99992H10.6663C11.02 3.99992 11.3591 4.14039 11.6091 4.39044C11.8592 4.64049 11.9997 4.97963 11.9997 5.33325V10.6666H13.333C13.6866 10.6666 14.0258 10.5261 14.2758 10.2761C14.5259 10.026 14.6663 9.68687 14.6663 9.33325V2.66659C14.6663 2.31296 14.5259 1.97382 14.2758 1.72378C14.0258 1.47373 13.6866 1.33325 13.333 1.33325Z" fill="currentColor" />
          </svg>
        ) : (
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
            <path d="M16.667 1.66666H8.33366C7.41449 1.66666 6.66699 2.41416 6.66699 3.33332V6.66666H3.33366C2.41449 6.66666 1.66699 7.41416 1.66699 8.33332V16.6667C1.66699 17.5858 2.41449 18.3333 3.33366 18.3333H11.667C12.5862 18.3333 13.3337 17.5858 13.3337 16.6667V13.3333H16.667C17.5862 13.3333 18.3337 12.5858 18.3337 11.6667V3.33332C18.3337 2.41416 17.5862 1.66666 16.667 1.66666ZM3.33366 16.6667V8.33332H11.667L11.6687 16.6667H3.33366ZM16.667 11.6667H13.3337V8.33332C13.3337 7.41416 12.5862 6.66666 11.667 6.66666H8.33366V3.33332H16.667V11.6667Z" fill="currentColor" />
          </svg>
        )
      }
  </div>
);
